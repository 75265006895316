import styled from 'styled-components'

const StyledQuestionIcon = styled.button`
  background-color: transparent;
  border: none;
  padding: 5px;
  width: 25px;
  height: 25px;
  display: inline-block;
  svg {
    width: 15px;
    height: auto;
  }
`

export {
  StyledQuestionIcon
}