import filmnoir from './filmnoir.svg';
import abstract from './abstract.svg';
import realism from './realism.svg';
import technical from './technical.svg';
import futurist from './futurist.svg';
import comic from './comic.svg';
import psychedelic from './psychedelic.svg';
import flat from './flat.svg';
import bluness from './bluness.svg';
import isometric from './isometric.svg';
import alegria from './alegria.svg';
import childbook from './childbook.svg';
import lowpoly from './lowpoly.svg';
import pixel from './pixel.svg';

import scribble from './scribble.svg';
import minimalist from './minimalist.svg';
import doodle from './doodle.svg';
import silhouette from './silhouette.svg';
import colouringbook from './colouringbook.svg';
import ntntntnt from './ntntntnt.svg';

import picasso from './picasso.svg';
import dali from './dali.svg';
import basquiat from './basquiat.svg';
import murakami from './murakami.svg';
import popart from './popart.svg';
import schiele from './schiele.svg';
import escher from './escher.svg';
import matisse from './matisse.svg';
import vangogh from './vangogh.svg';
import rand from './rand.svg';
import mondrian from './mondrian.svg';

import logo_abstract from './logo_abstract.svg';
import logo_elegant from './logo_elegant.svg';
import logo_geometric from './logo_geometric.svg';
import logo_funny from './logo_funny.svg';
import emoji from './emoji.svg';
import icon_web from './icon_web.svg';

import tattoo_americana from './tattoo_americana.svg';
import tattoo_geometric from './tattoo_geometric.svg';
import tattoo_japanese from './tattoo_japanese.svg';
import tattoo_line from './tattoo_line.svg';
import tattoo_oldschool from './tattoo_oldschool.svg';
import tattoo_tribal from './tattoo_tribal.svg';

import { IStyleId, IStyleReplicateId } from '../../types';

type IStyleImages = {
  [key in IStyleId | IStyleReplicateId]: string;
};

export const STYLE_IMAGES: IStyleImages = {
  bluness,
  doodle,
  psychedelic,
  flat,
  picasso,
  dali,
  childbook,
  minimalist,
  scribble,
  basquiat,
  murakami,
  popart,
  schiele,
  escher,
  isometric,
  matisse,
  silhouette,
  technical,
  futurist,
  comic,
  colouringbook,
  filmnoir,
  abstract,
  realism,
  vangogh,
  rand,
  alegria,
  ntntntnt,
  logo_abstract,
  logo_elegant,
  logo_funny,
  logo_geometric,
  emoji,
  icon_web,
  lowpoly,
  pixel,
  mondrian,

  tattoo_americana,
  tattoo_oldschool,
  tattoo_geometric,
  tattoo_line,
  tattoo_tribal,
  tattoo_japanese,

  // TODO: check if those are needed
  "no-style": '',
  manga: '',
  simpson: '',
  rickandmorty: '',
  peanuts: '',
  pokemon: '',
  corporate: '',
  rectangles: '',
  circles: '',
  kanagawa: '',
  app_icon: ''
}