import { StyledPageMain } from './page-main-style';

interface IPageMainProps {
  children: any,
}

export const PageMain = ({ children }: IPageMainProps) => {
  return (
    <StyledPageMain className='pageMain'>
      {children}
    </StyledPageMain>
  )
}