import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from 'react-router-dom';
import { generateApi, generateRepApi, statusRepApi } from "../../api";
import { getUserInfoApi } from "../../firestoreApi";
import { ColorMode, IStyleId, IStyleReplicateId, ObjectMode } from "../../types";
import { StyledGenerate } from "./generate-style";
import { setUserInfoAction, addUserRequestAction, changeUserTokensByXAction } from "../../store/actions/user";
import { setErrorAction, setGenerateLoadingAction, setInfoAction, setLoadingAction, setLoadingMessageAction, setShowLoginModalAction } from "../../store/actions/ui";
import { RootState } from "../../store/store";
import { Spacer } from "../../components/Spacer";
import { useEffect, useState } from "react";
import GenerateStyle from "./components/GenerateStyle/GenerateStyle";
import { GenerateObjectMode } from "./components/GenerateObjectMode/GenerateObjectMode";
import { GenerateConfirm } from "./components/GenerateConfirm/GenerateConfirm";
import { GenerateColormode, isColorLimitValid } from "./components/GenerateColormode/GenerateColormode";
import { logEvent } from "firebase/analytics";
import { analytics } from "../../fire";
import { PromptInput } from "./components/PromptInput/PromptInput";
import { addWhiteToColorpalette } from "../../utils/colors";
import { Stepper } from "../../components/stepper/Stepper";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { getStyleFromStyleId } from "../../utils/styles";
import { GenerateErrorCodes, IGenerateRequestParam } from "../../apiTypes";
import { IStyle } from "../../onlyFeTypes";

export type IAdvancedSetting = "none" | "colorlimit" | "colorpalette";
export type IGenerateStep = "style" | "colormode" | "objectmode" | "confirm";
const ALL_STEPS: IGenerateStep[] = ["style", "colormode", "objectmode", "confirm"];
const ALL_STEPS_TITLES: string[] = ["Choose the style", "Color mode", "Object mode", "Confirm"];

const INITIAL_COLOR_PALETTE = '#ffffff,#0500ff,#e6db63,#e878ce,#272727';

export const Generate = () => {

  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const token = useSelector((state: RootState) => state.user.token);
  const userFirebase = useSelector((state: RootState) => state.user.userFirebase);
  const userInfo = useSelector((state: RootState) => state.user?.userInfo);

  const [isRepStyle, setIsRepStyle] = useState<boolean>(false);
  const [prompt, setPrompt] = useState<string>("");
  const [step, setStep] = useState<IGenerateStep>("style");
  const [selectedStyleId, setSelectedStyleId] = useState<IStyleId | IStyleReplicateId | null>(null);
  const [selectedStyle, setSelectedStyle] = useState<IStyle>();

  const [selectedAdvancedSettings, setSelectedAdvancedSettings] = useState<IAdvancedSetting>("none");
  const [showAdvancedSettings, setShowAdvancedSettings] = useState<boolean>(false);

  const [selectedCategory, setSelectedCategory] = useState<string>("Common");
  const [objectmode, setObjectmode] = useState<ObjectMode>("full");
  const [colormode, setColormode] = useState<ColorMode>("color");
  const [colorlimit, setColorLimit] = useState<number>();
  const [colorpalette, setColorPalette] = useState<string>(INITIAL_COLOR_PALETTE);

  const handleSelectStyle = (s: IStyle) => {
    setSelectedStyle(s);
    setSelectedStyleId(s.id);
    setIsRepStyle(!!s.isRep);
    setStep("colormode");
    logEvent(analytics, 'select_style', { style: s.id });
  };

  const startGeneration = (style: IStyleId) => {
    if (token) {
      dispatch(setGenerateLoadingAction(true));
      if(prompt){
        const generatePayload: IGenerateRequestParam = {
          prompt,
          style,
          colormode,
          objectmode,
          ...((selectedAdvancedSettings === "colorlimit") && isColorLimitValid(colorlimit) && { colorlimit }),
          ...((selectedAdvancedSettings === "colorpalette") && !!colorpalette && { colorpalette: addWhiteToColorpalette(colorpalette, objectmode) }),
        };
        generateApi(token, generatePayload, dispatch).then((res) => {
          dispatch(setGenerateLoadingAction(false));
          if (res.success && res.data) {
            dispatch(addUserRequestAction(res.data));
            dispatch(changeUserTokensByXAction(-1)); //decrement tokens by 1
            navigate('/collection', {
              state: {
                data: res.data,
              }
            });
          } else {
            if (res.customErrorCode) {
              switch (res.customErrorCode) {
                case GenerateErrorCodes.MISSING_PARAMETERS:
                case GenerateErrorCodes.INVALID_STYLE:
                  dispatch(setErrorAction("Missing or wrong parameters. Please contact the support."));
                  break;
                case GenerateErrorCodes.RUN_OUT_TOKENS:
                  navigate('/pricing');
                  break;
                case GenerateErrorCodes.DALLE_EMPTY_RESPONSE:
                  dispatch(setErrorAction("Server are down. Please retry later. Error Code: " + res.customErrorCode));
                  break;
                case GenerateErrorCodes.DALLE_REQUEST_ERROR:
                  // check if we have a message:
                  const message = (res.error as any)?.error?.message;
                  if(message) {
                    dispatch(setErrorAction(message));
                  } else {
                    dispatch(setErrorAction("Server are down. Please retry later. Error Code: " + res.customErrorCode));
                  }
                  break;
                default:
                  break;
              }
            } else if (res.error) {
              console.error(res.error);
              dispatch(setErrorAction(res.error.toString()));
            } else {
              dispatch(setErrorAction("Unknow error"));
            }
          }
        });
      } else {
        dispatch(setGenerateLoadingAction(false));
        dispatch(setErrorAction("Prompt is empty. Please write something."));
      }
    }
  };

  const startGenerationRep = (style: IStyleReplicateId) => {
    if (token) {
      dispatch(setLoadingAction(true));
      generateRepApi(token, { prompt, style, colormode }).then(res => {
        if (res && res.success) {
          if (res.data?.status === 'starting') {
            checkColdBoot(res.data.id, style);
          }
        } else {
          if (res.customErrorCode) {
            switch (res.customErrorCode) {
              case GenerateErrorCodes.MISSING_PARAMETERS:
                dispatch(setErrorAction("Missing parameters. Please contact the support."));
                break;
              case GenerateErrorCodes.RUN_OUT_TOKENS:
                navigate('/pricing');
                break;
              case GenerateErrorCodes.REPLICATE_CONF_ERROR:
              case GenerateErrorCodes.REPLICATE_PREDICT_ERROR_1:
              case GenerateErrorCodes.REPLICATE_PREDICT_ERROR_2:
              case GenerateErrorCodes.REPLICATE_PREDICT_ERROR_3:
                dispatch(setErrorAction("Server are down. Please retry later. Error Code: " + res.customErrorCode));
                break;
              default:
                dispatch(setErrorAction("Error Code: " + res.customErrorCode));
                break;
            }
          } else if (res.error) {
            dispatch(setErrorAction(res.error.toString()));
          } else {
            dispatch(setErrorAction("Unknow error"));
          }
        }
      });
    }
  };

  const checkColdBoot = (id: string, style: IStyleReplicateId) => {
    if (token) {
      setTimeout(() => {
        statusRepApi(token, {
          id,
          colormode,
          style,
          prompt,
          ...((selectedAdvancedSettings === "colorlimit") && isColorLimitValid(colorlimit) && { colorlimit }),
          ...((selectedAdvancedSettings === "colorpalette") && !!colorpalette && { colorpalette: addWhiteToColorpalette(colorpalette, objectmode) }),
        }).then(res => {
          if (res && res.data?.status === 'starting') {
            dispatch(setLoadingMessageAction("Starting. This can sometimes take around 3 to 5 minutes while the model boots up. Please wait. Don't refresh or close this page. This only happens on startup. The next request will be faster."));
            startRepColdBootPolling(id, style);
          } else if (res && res.data?.status === "processing") {
            startRepProcessingPolling(id);
          }
        });
      }, 5000);
    }
  };

  const startRepColdBootPolling = (id: string, style: IStyleReplicateId) => {
    if (token) {
      const i = setInterval(() => {
        statusRepApi(token, {
          id,
          colormode,
          style,
          prompt,
          ...((selectedAdvancedSettings === "colorlimit") && isColorLimitValid(colorlimit) && { colorlimit }),
          ...((selectedAdvancedSettings === "colorpalette") && !!colorpalette && { colorpalette: addWhiteToColorpalette(colorpalette, objectmode) }),
        }).then(res => {
          if (res && res.success) {
            if (res.data?.status === "processing") {
              startRepProcessingPolling(id);
              dispatch(setLoadingMessageAction('Almost ready! There are approximately 30 seconds remaining.'));
              clearInterval(i);
            }
          } else {
            dispatch(setLoadingMessageAction(''));
            dispatch(setLoadingAction(false));
            dispatch(setErrorAction("Error. Please refresh the page and retry."))
            clearInterval(i);
          }
        })
      }, 10000);
    }
  };

  const startRepProcessingPolling = (id: string) => {
    if (token) {
      const i = setInterval(() => {
        statusRepApi(token, {
          id,
          colormode,
          style: selectedStyleId as IStyleReplicateId,
          prompt,
          ...((selectedAdvancedSettings === "colorlimit") && isColorLimitValid(colorlimit) && { colorlimit }),
          ...((selectedAdvancedSettings === "colorpalette") && !!colorpalette && { colorpalette: addWhiteToColorpalette(colorpalette, objectmode) }),
        }).then(res => {
          if (res && res.data?.request) {
            clearInterval(i);
            dispatch(setLoadingAction(false));
            dispatch(setLoadingMessageAction(''));
            dispatch(addUserRequestAction(res.data.request));
            dispatch(changeUserTokensByXAction(-1)); //decrement tokens by 1
            navigate('/collection', {
              state: {
                data: res.data.request,
              }
            });
          } else if (res && res?.error) {
            dispatch(setLoadingMessageAction(''));
            dispatch(setLoadingAction(false));
            dispatch(setErrorAction("Error. Please refresh the page and retry."))
            clearInterval(i);
          }
        })
      }, 5000);
    }
  };

  const handleClickSumbit = () => {
    if (userFirebase && token && selectedStyleId && (objectmode !== undefined)) {
      if (userFirebase.emailVerified) {
        if (import.meta.env.VITE_FLAGS_STOP_GENERATE === "true") {
          dispatch(setInfoAction("Sorry for the inconvenience, but due to high traffic our website is currently undergoing maintenance. We will be back up and running as soon as possible. Thank you for your patience."))
        } else {
          if (userInfo) {
            if (userInfo.tokens > 0) {
              if (isRepStyle) {
                startGenerationRep(selectedStyleId as IStyleReplicateId)
              } else {
                startGeneration(selectedStyleId as IStyleId);
              }
            } else {
              navigate('/pricing');
            }
          } else {
            dispatch(setLoadingAction(true));
            getUserInfoApi(userFirebase.uid).then(res => {
              dispatch(setLoadingAction(false));
              if (res) {
                dispatch(setUserInfoAction(res));
                if (res.tokens > 0) {
                  if (isRepStyle) {
                    startGenerationRep(selectedStyleId as IStyleReplicateId)
                  } else {
                    startGeneration(selectedStyleId as IStyleId);
                  }
                } else {
                  navigate('/pricing');
                }
              }
            });
          }
        }
      } else {
        dispatch(setErrorAction(`Your email is not verified. We have sent a verification email to ${userFirebase.email}. Before continuing click on the link and refresh the page. If you can't find the email, check your spam.`))
      }
    } else {
      dispatch(setShowLoginModalAction(true));
    }
  };

  const handleClickStep = (nextStep: IGenerateStep) => {
    let canGoToStep = false;
    switch (nextStep) {
      case "colormode":
        canGoToStep = !!selectedStyleId
        if (!selectedStyleId) {
          dispatch(setInfoAction("Please select a style."));
          break;
        }
        break;
      case "objectmode":
      case "confirm":
        if (!!colorlimit && !isColorLimitValid(colorlimit)) {
          dispatch(setErrorAction("Color limit must be a number between 2 and 9"));
          break;
        }
        if (!selectedStyleId) {
          dispatch(setInfoAction("Please select a style."));
          break;
        }
        canGoToStep = !!selectedStyleId
        break;
      case "style":
        canGoToStep = true
        break;
      default:
        break;
    }

    if (canGoToStep) {
      setStep(nextStep);
    }
  };

  useEffect(() => {
    const locationPrompt = location?.state?.prompt;

    if (!locationPrompt) {
      navigate('/');
      return;
    }

    const locationStyleId = location?.state?.styleId;
    const locationObjectmode = location?.state?.objectmode;
    const locationColormode = location?.state?.colormode;
    const locationIsRep = location?.state?.isRep;
    const locationColorLimit = location?.state?.colorlimit;
    const locationColorPalette = location?.state?.colorpalette;

    if (locationObjectmode) setObjectmode(locationObjectmode);
    if (locationColormode) setColormode(locationColormode);
    if (locationStyleId) {
      setSelectedStyleId(locationStyleId);
      const s = getStyleFromStyleId(locationStyleId);
      if(s) setSelectedStyle(s);
    }
    if (locationPrompt) setPrompt(locationPrompt);
    if (locationIsRep) setIsRepStyle(locationIsRep);

    if (locationColorLimit) {
      setColorLimit(locationColorLimit);
      setSelectedAdvancedSettings("colorlimit");
      setShowAdvancedSettings(true);
    }

    if (locationColorPalette) {
      setColorPalette(locationColorPalette);
      setSelectedAdvancedSettings("colorpalette");
      setShowAdvancedSettings(true);
    }

    if (locationIsRep) {
      setStep("confirm");
    } else if (locationObjectmode) {
      setStep("confirm");
    } else if (locationColormode) {
      setStep("objectmode");
    } else if (locationStyleId) {
      setStep("colormode");
    }

  }, []);

  return (
    <div className='text-center md:p-4'>
      <HelmetProvider>
        <Helmet>
          <title>Illustroke | Generate</title>
          <meta name="robots" content="noindex" />
        </Helmet>
      </HelmetProvider>
      <StyledGenerate>

        <PromptInput prompt={prompt} setPrompt={setPrompt} />

        <Stepper handleClickStep={handleClickStep} currentStep={step} steps={ALL_STEPS} titles={ALL_STEPS_TITLES} />

        <div>
          {step === "style" && (
            <GenerateStyle
              selectedCategory={selectedCategory}
              setSelectedCategory={setSelectedCategory}
              selectedStyleId={selectedStyleId}
              handleSelectStyle={handleSelectStyle}
            />
          )}

          {step === "colormode" && (
            <GenerateColormode
              colormode={colormode}
              setColormode={setColormode}
              setStep={setStep}
              colorlimit={colorlimit}
              setColorLimit={setColorLimit}
              style={selectedStyle}
              colorpalette={colorpalette}
              setColorPalette={setColorPalette}
              selectedAdvancedSettings={selectedAdvancedSettings}
              setSelectedAdvancedSettings={setSelectedAdvancedSettings}
              showAdvancedSettings={showAdvancedSettings}
              setShowAdvancedSettings={setShowAdvancedSettings}
            />
          )}

          {step === "objectmode" && (
            <GenerateObjectMode
              setStep={setStep}
              objectmode={objectmode}
              setObjectmode={setObjectmode}
              isRepStyle={isRepStyle}
            />
          )}

          {step === "confirm" && (
            <GenerateConfirm
              setStep={setStep}
              prompt={prompt}
              handleClickSumbit={handleClickSumbit}
              colormode={colormode}
              objectmode={objectmode}
              style={selectedStyle}
              colorlimit={colorlimit}
              colorpalette={colorpalette}
              selectedAdvancedSettings={selectedAdvancedSettings}
            />
          )}

          <Spacer />
          <Spacer />
          <Spacer />

        </div>
      </StyledGenerate>
    </div>
  )
};