import { Link } from 'react-router-dom';
import logoWhite from '../../../images/logo-white.png';
import { Spacer } from '../../Spacer';
import { SocialButton } from '../../social-button/SocialButton';

export const Footer = () => {
  return (
    <footer>
      <div>
        <img id='footer-logo' src={logoWhite} alt="illustroke logo white" />
        <p>Stunning vector illustrations from text prompts</p>
        <Spacer />
      </div>
      <div className='social'>
        <SocialButton social="x" />
        <SocialButton social="instagram" />
        <SocialButton social="tiktok" />
      </div>
      <div className="links">
        <a href='mailto:info@illustroke.com'>info@illustroke.com</a>
      </div>
      <div className="links-secondary">
        <Link to="/affiliate">Affiliate Marketing</Link>
        <Link to="/pricing">Pricing</Link>
        <Link to="/api">Api</Link>
        <Link to="/privacy">Privacy Policy</Link>
        <Link to="/terms">Terms and conditions</Link>
      </div>

      <div className='credits'>
        <p>Made by <b>Digitalbore OU</b>. </p>
        {import.meta.env.MODE === "development" && <p>DEV Mode: {import.meta.env.VITE_FIREBASE_PROJECT_ID}</p>}
        <p>v1.3.0</p>
      </div>
    </footer>
  )
};