import React, { useEffect, useState } from "react";
import { GenerateLoaderStyled } from "./generate-loader-style";
import { Loader } from "../Loader";

const messages = [
  'Describe directly the subject do you want to illustrate avoiding abstract or complex concepts.',
  'Write your text prompt in plain english.',
  'Try the same text prompt multiple times. It may take a few tries to get the perfect illustration.',
  'Did you know that you can change the palette of your illustration during the color mode selection step? Open the advanced settings to configure it.',
  "Can't find the style you are looking for? Send us an email to propose it to the team!",
  "After generating your vector file (SVG) you can edit it as you like, changing shapes and colors. You can use our editor, or some more advanced tool like Figma or Adobe Illustrator.",
  "Do you like an illustration, but it's not exactly what you're looking for? You can create a variation and you will get 3 more illustrations similar to that one.",
  "Did you know that Illustroke is not suitable for writing text? You can only generate graphical objects, but you can add text later in our editor, or with other composing tools like Figma or Adobe Illustrator."
];

// Define the component
const GenerateLoader: React.FC = () => {
  const [messageIndex, setMessageIndex] = useState<number>(Math.floor(Math.random() * messages.length));

  useEffect(() => {
    const interval = setInterval(() => {
      const randomIndex = Math.floor(Math.random() * messages.length);
      setMessageIndex(randomIndex);
    }, 15000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  return (
    <GenerateLoaderStyled>
      <div className="inner">
        <div>
          <h3>We are creating your illustrations.</h3>
          <br />
          <p className="tipText"><i>"{messages[messageIndex]}"</i></p>
          <p className="tipTitle"><b>ILLUSTROKE TIP N°{messageIndex + 1}</b></p>
        </div>
        <div>
          <Loader />
        </div>
      </div>
    </GenerateLoaderStyled>
  );
};

export default GenerateLoader;
