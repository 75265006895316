import { ICategory } from "../onlyFeTypes";

export const CATEGORY_LIST: ICategory[] = [
  {
    name: "Common",
    styles: [
      {
        id: "flat",
        label: "Flat",
        examples: [
          {
            prompt: "A dog face",
            image: "flat_dog",
          },
          {
            prompt: "A boxer",
            image: "flat_boxer",
          },
          {
            prompt: "A koala",
            image: "flat_koala",
          },
          {
            prompt: "A dog face",
            image: "flat_dog2",
          },
          {
            prompt: "The planet earth",
            image: "flat_earth",
          },
          {
            prompt: "Two man looking each other",
            image: "flat_twoman"
          },
        ],
      },
      {
        id: "alegria",
        label: "Alegria",
        examples: [
          {
            prompt: "A boxer",
            image: "alegria_boxer",
          },
          {
            prompt: "A man waiting for the bus",
            image: "alegria_bus",
          },
          {
            prompt: "An obese cat",
            image: "alegria_cat1",
          },
          {
            prompt: "Two man looking each other",
            image: "alegria_twoman1",
          },
          {
            prompt: "Two man looking each other",
            image: "alegria_twoman2",
          },
        ]
      },
      {
        isRep: true,
        id: "bluness",
        label: "Bluness",
        examples: [
          {
            prompt: "A girl and a man kissing",
            image: "bluness_kiss",
          },
          {
            prompt: "A person skiing",
            image: "bluness_ski",
          },
          {
            prompt: "A child jumping",
            image: "bluness_jump",
          },
          {
            prompt: "A lion",
            image: "bluness_lion",
          },
          {
            prompt: "A doctor with his dog",
            image: "bluness_doctor",
          },
        ],
      },
      {
        id: "abstract",
        label: "Abstract",
        examples: [
          {
            prompt: "A person reading a book",
            image: "abstract_book1"
          },
          {
            prompt: "A person reading a book",
            image: "abstract_book2"
          },
          {
            prompt: "The planet earth",
            image: "abstract_earth"
          },
          {
            prompt: "Two man looking each other",
            image: "abstract_twoman1"
          },
        ]
      },
      {
        id: "lowpoly",
        label: "Low Poly",
        examples: [
          {
            prompt: "A lion",
            image: "lowpoly_lion"
          },
          {
            prompt: "A smiling woman",
            image: "lowpoly_woman"
          },
          {
            prompt: "A dog",
            image: "lowpoly_dog1"
          },
          {
            prompt: "A dog",
            image: "lowpoly_dog2"
          },
        ]
      },
      {
        id: "pixel",
        label: "Pixel Art",
        examples: [
          {
            prompt: "A dog",
            image: "pixel_dog1"
          },
          {
            prompt: "A dog",
            image: "pixel_dog2"
          },
          {
            prompt: "A dog",
            image: "pixel_dog3"
          },
        ]
      },
      {
        id: "futurist",
        label: "Futurist",
        examples: [
          {
            prompt: "A person reading a book",
            image: "futurist_book"
          },
          {
            prompt: "An obese cat",
            image: "futurist_cat"
          },
          {
            prompt: "The planet earth",
            image: "futurist_earth"
          },
          {
            prompt: "Two man looking each other",
            image: "futurist_twoman"
          },
        ]
      },
      {
        id: "comic",
        label: "Comic",
        examples: [
          {
            prompt: "A smiling woman",
            image: "comic_woman"
          },
          {
            prompt: "A running dog",
            image: "comic_dog"
          },
          {
            prompt: "A person reading a book",
            image: "comic_book"
          },
          {
            prompt: "An obese cat",
            image: "comic_cat"
          },
          {
            prompt: "The planet earth",
            image: "comic_earth"
          },
        ]
      },
      {
        id: "isometric",
        label: "Isometric",
        examples: [
          {
            prompt: "A person reading a book",
            image: "isometric_book"
          },
          {
            prompt: "A person reading a book",
            image: "isometric_book2"
          },
          {
            prompt: "An obese cat",
            image: "isometric_cat"
          },
          {
            prompt: "Two man looking each other",
            image: "isometric_twoman"
          },
        ]
      },
      {
        id: "psychedelic",
        label: "Psychedelic",
        examples: [
          {
            prompt: "An obese cat",
            image: "psychedelic_cat"
          },
          {
            prompt: "A smiling face of an old man",
            image: "psychedelic_man"
          },
          {
            prompt: "Two man looking each other",
            image: "psychedelic_twoman"
          },
          {
            prompt: "A person reading a book",
            image: "psychedelic_book"
          }
        ]
      },
      {
        id: "childbook",
        label: "Child Book",
        examples: [
          {
            prompt: "A person reading a book",
            image: "childbook_book"
          },
          {
            prompt: "An obese cat",
            image: "childbook_castle1"
          },
          {
            prompt: "The planet earth",
            image: "childbook_castle2"
          }
        ]
      },
      {
        id: "realism",
        label: "Realism",
        examples: [
          {
            prompt: "A person reading a book",
            image: "realism_book"
          },
          {
            prompt: "A smiling woman",
            image: "realism_woman"
          },
          {
            prompt: "The planet earth",
            image: "realism_earth"
          },
          {
            prompt: "Two man looking each other",
            image: "realism_twoman"
          },
        ]
      },
      {
        id: "filmnoir",
        label: "Film Noir",
        examples: [
          {
            prompt: "A person reading a book",
            image: "filmnoir_book"
          },
          {
            prompt: "An obese cat",
            image: "filmnoir_cat1"
          },
          {
            prompt: "An obese cat",
            image: "filmnoir_cat2"
          },
          {
            prompt: "Two man looking each other",
            image: "filmnoir_twoman1"
          },
          {
            prompt: "Two man looking each other",
            image: "filmnoir_twoman2"
          },
        ]
      }
    ]
  },
  {
    name: 'Black & White',
    styles: [
      {
        id: "ntntntnt",
        label: "Goodness",
        isRep: true,
        isBnW: true,
        examples: [
          {
            prompt: "A child playing with a ball",
            image: "ntntntnt_child",
          },
          {
            prompt: "A man drinking beer",
            image: "ntntntnt_drink2",
          },
          {
            prompt: "A man drinking beer",
            image: "ntntntnt_drink",
          },
          {
            prompt: "A dog",
            image: "ntntntnt_dog",
          },
          {
            prompt: "A old man face",
            image: "ntntntnt_old",
          },
        ]
      },
      {
        id: "silhouette",
        label: "Silhouette",
        isBnW: true,
        examples: [
          {
            prompt: "An obese cat",
            image: "silhouette_cat"
          },
          {
            prompt: "The planet earth",
            image: "silhouette_earth"
          },
          {
            prompt: "The planet earth",
            image: "silhouette_earth2"
          },
          {
            prompt: "Two man looking each other",
            image: "silhouette_twoman1"
          },
          {
            prompt: "Two man looking each other",
            image: "silhouette_twoman2"
          },
        ]
      },
      {
        id: "doodle",
        label: "Doodle",
        examples: [
          {
            prompt: "A person reading a book",
            image: "doodle_book"
          },
          {
            prompt: "An obese cat",
            image: "doodle_cat1"
          },
          {
            prompt: "An obese cat",
            image: "doodle_cat2"
          },
          {
            prompt: "The planet earth",
            image: "doodle_earth1"
          },
          {
            prompt: "The planet earth",
            image: "doodle_earth2"
          },
          {
            prompt: "Two man looking each other",
            image: "doodle_twoman"
          },
        ]
      },
      {
        id: "technical",
        label: "Technical",
        isBnW: true,
        examples: [
          {
            prompt: "An obese cat",
            image: "technical_cat"
          },
          {
            prompt: "The planet earth",
            image: "technical_earth"
          },
          {
            prompt: "A train",
            image: "technical_train"
          },
          {
            prompt: "Two man looking each other",
            image: "technical_twoman"
          },
        ]
      },
      {
        id: "colouringbook",
        label: "Colouring Book",
        isBnW: true,
        examples: [
          {
            prompt: "An obese cat",
            image: "colouringbook_cat"
          },
          {
            prompt: "The planet earth",
            image: "colouringbook_earth"
          },
          {
            prompt: "A train",
            image: "colouringbook_train"
          }
        ]
      },
      {
        id: "scribble",
        label: "Scribble",
        isBnW: true,
        examples: [
          {
            prompt: "An obese cat",
            image: "scribble_cat"
          },
          {
            prompt: "A train",
            image: "scribble_train"
          },
          {
            prompt: "Two man looking each other",
            image: "scribble_twoman"
          },
          {
            prompt: "Two man looking each other",
            image: "scribble_twoman2"
          },
        ]
      },
      {
        id: "minimalist",
        label: "Minimalist",
        isBnW: true,
        examples: [
          {
            prompt: "An obese cat",
            image: "minimalist_cat"
          },
          {
            prompt: "An obese cat",
            image: "minimalist_cat2"
          },
          {
            prompt: "A train",
            image: "minimalist_train"
          },
          {
            prompt: "Two man looking each other",
            image: "minimalist_twoman"
          },
          {
            prompt: "Two man looking each other",
            image: "minimalist_twoman2"
          },
        ]
      },
    ]
  },
  {
    name: "Logo & Icons",
    styles: [
      {
        id: "logo_abstract",
        label: "Logo Abstract",
        examples: [
          {
            prompt: "A lion face",
            image: "logo_abstract_lion"
          },
          {
            prompt: "A thunder wolf",
            image: "logo_abstract_wolf"
          },
          {
            prompt: "A pear with a worm",
            image: "logo_abstract_pear"
          },
          {
            prompt: "A fly",
            image: "logo_abstract_fly"
          },
        ]
      },
      {
        id: "logo_elegant",
        label: "Logo Elegant",
        examples: [
          {
            prompt: "A ship",
            image: "logo_elegant_ship"
          },
          {
            prompt: "A bunny eating a carrot",
            image: "logo_elegant_bunny"
          },
          {
            prompt: "Rock and scissor",
            image: "logo_elegant_rockscissor"
          },
        ]
      },
      {
        id: "logo_geometric",
        label: "Logo Geometric",
        examples: [
          {
            prompt: "A ship",
            image: "logo_geometric_ship"
          },
          {
            prompt: "A bunny eating a carrot",
            image: "logo_geometric_bunny"
          },
          {
            prompt: "Rock and scissor",
            image: "logo_geometric_rockscissor"
          },
        ]
      },
      {
        id: "logo_funny",
        label: "Logo Funny",
        examples: [
          {
            prompt: "A ship",
            image: "logo_funny_ship"
          },
          {
            prompt: "A ship",
            image: "logo_funny_ship2"
          },
          {
            prompt: "A bunny eating a carrot",
            image: "logo_funny_bunny"
          },
          {
            prompt: "Rock and scissor",
            image: "logo_funny_rockscissor"
          },
          {
            prompt: "Rock and scissor",
            image: "logo_funny_rockscissor2"
          },
        ]
      },
      {
        id: "emoji",
        label: "Emoji",
        examples: [
          {
            prompt: "A ship",
            image: "emoji_ship1"
          },
          {
            prompt: "A ship",
            image: "emoji_ship2"
          },
          {
            prompt: "A bunny eating a carrot",
            image: "emoji_bunny"
          },
          {
            prompt: "Rock and scissor",
            image: "emoji_rockscissor"
          },
        ]
      },
      {
        id: "icon_web",
        label: "Web Icon",
        isBnW: true,
        examples: [
          {
            prompt: "A box",
            image: "icon_web_box"
          },
          {
            prompt: "An open box",
            image: "icon_web_box2"
          },
          {
            prompt: "An angry face",
            image: "icon_web_angry"
          },
          {
            prompt: "An apple with a worm",
            image: "icon_web_apple"
          }
        ]
      },
    ]
  },
  {
    name: "Artists",
    styles: [
      {
        id: "picasso",
        label: "P. Picasso",
        examples: [
          {
            prompt: "A person reading a book",
            image: "picasso_book"
          },
          {
            prompt: "An obese cat",
            image: "picasso_cat"
          },
          {
            prompt: "An obese cat",
            image: "picasso_cat2"
          },
          {
            prompt: "An obese cat",
            image: "picasso_cat3"
          },
          {
            prompt: "The planet earth",
            image: "picasso_earth"
          },
        ]
      },
      {
        id: "dali",
        label: "S. Dalì",
        examples: [
          {
            prompt: "A person reading a book",
            image: "dali_book1"
          },
          {
            prompt: "A person reading a book",
            image: "dali_book2"
          },
          {
            prompt: "The planet earth",
            image: "dali_earth"
          },
          {
            prompt: "A train",
            image: "dali_train"
          },
        ]
      },
      {
        id: "escher",
        label: "E. Escher",
        examples: [
          {
            prompt: "A person reading a book",
            image: "escher_book"
          },
          {
            prompt: "A person reading a book",
            image: "escher_book2"
          },
          {
            prompt: "The planet earth",
            image: "escher_earth"
          },
          {
            prompt: "An obese cat",
            image: "escher_train"
          },
        ]
      },
      {
        id: "vangogh",
        label: "Van Gogh",
        examples: [
          {
            prompt: "A person reading a book",
            image: "vangogh_book1"
          },
          {
            prompt: "A person reading a book",
            image: "vangogh_book2"
          },
          {
            prompt: "An apple tree",
            image: "vangogh_tree"
          },
          {
            prompt: "The planet earth",
            image: "vangogh_earth"
          },
        ]
      },
      {
        id: "matisse",
        label: "E. Matisse",
        examples: [
          {
            prompt: "A person reading a book",
            image: "matisse_book1"
          },
          {
            prompt: "A person reading a book",
            image: "matisse_book2"
          },
          {
            prompt: "An obese cat",
            image: "matisse_cat1"
          },
          {
            prompt: "An obese cat",
            image: "matisse_cat2"
          },
        ]
      },
      {
        id: "basquiat",
        label: "JM Basquiat",
        examples: [
          {
            prompt: "A person reading a book",
            image: "basquiat_book1"
          },
          {
            prompt: "A person reading a book",
            image: "basquiat_book2"
          },
          {
            prompt: "An obese cat",
            image: "basquiat_cat1"
          },
          {
            prompt: "An obese cat",
            image: "basquiat_cat2"
          },
          {
            prompt: "An obese cat",
            image: "basquiat_cat3"
          },
        ]
      },
      {
        id: "rand",
        label: "Paul Rand",
        examples: [
          {
            prompt: "A person reading a book",
            image: "rand_book"
          },
          {
            prompt: "An obese cat",
            image: "rand_cat1"
          },
          {
            prompt: "An obese cat",
            image: "rand_cat2"
          },
          {
            prompt: "Two man looking each other",
            image: "rand_twoman1"
          },
          {
            prompt: "Two man looking each other",
            image: "rand_twoman2"
          },
        ]
      },
      {
        id: "mondrian",
        label: "Piet Mondrian",
        examples: [
          {
            prompt: "A dog",
            image: "mondrian_dog1"
          },
          {
            prompt: "A dog",
            image: "mondrian_dog2"
          },
        ]
      },
      {
        id: "schiele",
        label: "E. Schiele",
        examples: [
          {
            prompt: "A person reading a book",
            image: "schiele_book1"
          },
          {
            prompt: "A person reading a book",
            image: "schiele_book2"
          },
          {
            prompt: "An obese cat",
            image: "schiele_cat1"
          },
          {
            prompt: "An obese cat",
            image: "schiele_cat2"
          },
        ]
      },
      {
        id: "murakami",
        label: "H. Murakami",
        examples: [
          {
            prompt: "A person reading a book",
            image: "murakami_book1"
          },
          {
            prompt: "A person reading a book",
            image: "murakami_book2"
          },
          {
            prompt: "An obese cat",
            image: "murakami_cat1"
          },
          {
            prompt: "An obese cat",
            image: "murakami_cat2"
          },
        ]
      },
      {
        id: "popart",
        label: "Pop Art",
        examples: [
          {
            prompt: "A smiling woman",
            image: "popart_woman1"
          },
          {
            prompt: "A smiling woman",
            image: "popart_woman2"
          },
          {
            prompt: "A person reading a book",
            image: "popart_book"
          },
        ]
      },
    ]
  },
  {
    name: "Tattoo",
    styles: [
      {
        id: "tattoo_tribal",
        label: "Tribal Tattoo",
        isBnW: true,
        examples: [
          {
            prompt: "A mandala",
            image: "tattoo_tribal_mandala1",
          },
          {
            prompt: "A mandala",
            image: "tattoo_tribal_mandala2",
          },
          {
            prompt: "A white shark",
            image: "tattoo_tribal_shark",
          },
        ],
      },
      {
        id: "tattoo_oldschool",
        label: "Oldschool Tattoo",
        isBnW: true,
        examples: [
          {
            prompt: "A dog",
            image: "tattoo_oldschool_dog1",
          },
          {
            prompt: "A dog",
            image: "tattoo_oldschool_dog2",
          },
          {
            prompt: "A dog",
            image: "tattoo_oldschool_dog3",
          },
          {
            prompt: "An eagle",
            image: "tattoo_oldschool_eagle",
          },
          {
            prompt: "An apple with a worm",
            image: "tattoo_oldschool_apple",
          },
          {
            prompt: "A white shark",
            image: "tattoo_oldschool_shark",
          },
        ],
      },
      {
        id: "tattoo_americana",
        label: "Classic Americana Tattoo",
        isBnW: true,
        examples: [
          {
            prompt: "An apple with a worm",
            image: "tattoo_americana_apple1",
          },
          {
            prompt: "An apple with a worm",
            image: "tattoo_americana_apple2",
          },
          {
            prompt: "A white shark",
            image: "tattoo_americana_shark",
          },
        ],
      },
      {
        id: "tattoo_geometric",
        label: "Geometric Tattoo",
        isBnW: true,
        examples: [
          {
            prompt: "A white shark",
            image: "tattoo_geometric_shark1",
          },
          {
            prompt: "A white shark",
            image: "tattoo_geometric_shark2",
          },
          {
            prompt: "A white shark",
            image: "tattoo_geometric_shark3",
          },
          {
            prompt: "An apple with a worm",
            image: "tattoo_geometric_apple1",
          },
          {
            prompt: "An apple with a worm",
            image: "tattoo_geometric_apple2",
          },
        ],
      },
      {
        id: "tattoo_japanese",
        label: "Japanase Tattoo",
        isBnW: true,
        examples: [
          {
            prompt: "A koi fish",
            image: "tattoo_japanese_koi",
          },
        ],
      },
      {
        id: "tattoo_line",
        label: "Continuous Line Tattoo",
        isBnW: true,
        examples: [
          {
            prompt: "An apple with a worm",
            image: "tattoo_line_apple",
          },
          {
            prompt: "A koi fish",
            image: "tattoo_line_koi1",
          },
          {
            prompt: "A koi fish",
            image: "tattoo_line_koi2",
          },
        ],
      },
    ]
  }
];