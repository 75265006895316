import styled from 'styled-components';

const StyledNotFound = styled.div`
  padding-top: 2rem;
  button {
    margin-top: 1rem;
    margin: auto;
  }
`;


export {
  StyledNotFound
}