import styled from 'styled-components'
import { COLORS } from '../../colors'

const StyledButton = styled.button<{
  size?: "medium" | "small"
}>`
  font-size: ${props => (props.size === "small") ? "14px" : "18px"};
  background-color: ${COLORS.mainBlue}; 
  background: ${COLORS.mainGradient};
  color: white;
  padding: ${props => (props.size === "small") ? "0.5rem 1rem" : "0.75rem 2rem"};

  :disabled {
    background-color: ${COLORS.darkGray};
    background: ${COLORS.darkGray};
    cursor: auto;
  }

  //only smartphone
  @media only screen and (max-width: 600px) {
    padding: 0.5rem 2rem;
    font-size: 22px;
  }

  border: none;
  font-weight: bold;
  border-radius: 25px;
  cursor: pointer;

  display: flex;
  justify-content: center;
  align-items: center;
  svg, img {
    width: ${props => (props.size === "small") ? "15px" : "30px"};
    height: auto;
    margin-right: 10px;
  }
`

const StyledButtonSecondary = styled.button<{
  size: "medium" | "small"
}>`
  background-color: transparent;
  color: black;
  padding: 0.25rem 1rem;
  font-weight: bold;
  text-decoration: underline;
  border: none;
  font-size: ${props => (props.size === "medium") ? "16px" : "12px"};
  border-radius: 25px;
  cursor: pointer;
  svg, img {
    width: ${props => (props.size === "small") ? "15px" : "30px"};
    height: auto;
    margin-right: 10px;
  }
`

export {
  StyledButton,
  StyledButtonSecondary
}