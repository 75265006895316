import styled from 'styled-components';

const StyledReplicateConfirmModal = styled.div`
  background-color: white;
  padding: 2rem;
  border-radius: 25px;

  h4 {
    max-width: 60%;
    margin: auto;
    margin-bottom: 0.5rem;
  }
  p {
    font-size: 14px;
    margin: auto;
    margin-bottom: 1rem;
    max-width: 80%;
  }

  .coldBootIcon {
    background-color: #00bcd4;
    width: 30px;
    height: 30px;
    border-radius: 100%;
    opacity: 1;
    padding: 4px;
    margin: auto;
    margin-bottom: 1rem;
    svg {
      width: 25px;
      height: auto;
    }
  }

  .buttons {
    display: flex;
    justify-content: center;
  }

  p.note {
    margin-top: 1rem;
    font-size: 12px;
  }
`

export {
  StyledReplicateConfirmModal
}