import styled from 'styled-components';
import { COLORS } from '../../colors';

const StyledLayout = styled.div`
  header {
    text-align: center;
    background-color: ${COLORS.headerGray};
    .header-desktop {
      padding: 0.5rem;
      display: none;
      @media only screen and (min-width: 600px) {
        max-width: 1200px;
        margin: auto;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .elements {
          display: flex;
          align-items: center;
          gap: 1rem;
          a {
            font-size: 16px;
            text-decoration: none;
            color: black;
            font-weight: bold;
            color: ${COLORS.darkGray};
          }
          a:hover, a.active {
            font-weight: bold;
            color: ${COLORS.mainBlue};
          }
          div.tokens {
            background-color: ${COLORS.mainGray};
            color: ${COLORS.mainBlue};
            padding: 0.5rem 1.5rem;
            width: fit-content;
            border-radius: 25px;
            margin-left: 2rem;
            p {
              font-size: 14px;
              font-weight: bold;
            }
          }
          button.ai-tools {
            font-size: 16px;
            font-weight: bold;
            color: ${COLORS.darkGray};
            border: none;
            background-color: transparent;
            svg {
              margin-right: 5px;
            }
          }
        }
        img {
          width: 40px;
        }
      }
    }
    .sub-header-desktop {
      padding: 1rem;
      display: flex;
      justify-content: center;
      gap: 1rem;
      background-color: ${COLORS.mainGray};
      a {
        font-size: 16px;
        text-transform: uppercase;
        text-decoration: none;
        color: black;
        font-weight: bold;
        color: ${COLORS.darkGray};
      }
      a:hover, a.active {
        font-weight: bold;
        color: ${COLORS.mainBlue};
      }
    }
    .header-mobile {
      padding: 0.5rem;
      .icons {
        display: flex;
        justify-content: space-between;
        img {
          width: 50px;
        }
        button#hamburger {
          border: none;
          background-color: transparent;
          img {
            width: 30px;
          }
        }
      }
      @media only screen and (min-width: 600px) {
        display: none;
      }
      .elements {
        display: flex;
        flex-direction: column;
        a {
          margin-bottom: 1rem;
          font-size: 18px;
            text-transform: uppercase;
            font-weight: bold;
            text-decoration: none;
            color: ${COLORS.mainBlue};
        }
      }
    }
  }
  footer {
    text-align: center;
    background-color: ${COLORS.mainBlue};
    background: ${COLORS.mainGradient};
    color: white;
    padding: 2rem;
    img#footer-logo {
      width: 150px;
    }
    .links, .links-secondary {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 1rem;

      //only smartphone
      @media only screen and (max-width: 600px) {
        flex-direction: column;
      }
      a {
        color: white;
      }
    }

    .links {
      font-size: 18px;
    }

    .links-secondary {
      margin-top: 1rem;
      font-size: 12px;
    }

    .social {
      width: fit-content;
      margin: auto;
      padding: 0.5rem 1rem;
      border-radius: 25px;
      gap: 1rem;
      display: flex;
      align-items: center;
      margin-bottom: 1rem;
      background-color: white;
    }
    .credits {
      padding-top: 2rem;
      font-size: 12px;
      a {
        color: white;
      }
    }
  }
  main {
    text-align: center;
    min-height: 90vh;
    margin: auto;
  }
  > div.loading {
    z-index: 999999;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: ${COLORS.mainBlueOpaque};
    display: flex;
    gap: 1rem;
    //only smartphone
    @media only screen and (max-width: 600px) {
      flex-direction: column;
    }
    img {
      width: 50px;
    }
    .loadingMessage {
      padding: 1rem;
      border-radius: 25px;
      background-color: white;
      color: ${COLORS.mainBlue};
      font-size: 16px;
      max-width: 250px;
      text-align: center;
    }
  }
`;

export {
  StyledLayout
}
