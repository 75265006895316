import { QuestionIcon } from "../../../../../../components/question-icon/QuestionIcon";
import { addColorToColorpalette, colorpaletteToHexList, removeColorFromColorpalette, replaceColorFromColorpalette } from "../../../../../../utils/colors";
import { StyledRadioButtonSettings, StyledSettingsContainer, StyledSettingsInner } from "../../generate-colormode-style";
import { StyledColorPaletteSettings } from "./color-palette-settings-style";
import trashIcon from '../../../../../../images/icons/trash.svg';
import addIcon from '../../../../../../images/icons/add.svg';
import { useRef, useState } from "react";
import { SketchPicker } from "react-color";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../../../store/store";
import { useClickOutsideHook } from "../../../../../../hooks/useClickOutside";
import { addColorToUserPaletteAction } from "../../../../../../store/actions/user";
import { setInfoAction } from "../../../../../../store/actions/ui";

interface IColorPaletteSettingsProps {
  colorpalette?: string,
  setColorPalette: (s: any) => void,
  selected: boolean,
  onClickSelected: () => void
}

export const ColorPaletteSettings = ({
  colorpalette,
  setColorPalette,
  selected,
  onClickSelected
}: IColorPaletteSettingsProps) => {

  const dispatch = useDispatch();

  const userPalette = useSelector((state: RootState) => state.user.userPalette);

  const [showColorPicker, setShowColorPicker] = useState<boolean>(false);
  const colorPickerRef = useRef() as any;
  const [mode, setMode] = useState<"add" | "edit">();
  const [pickerColor, setPickerColor] = useState<any>();
  const [selectedColor, setSelectedColor] = useState<string>();

  const handleChangeColorComplete = (colorPickerObj: any) => {
    if (mode === "edit") {
      setColorPalette(replaceColorFromColorpalette(colorpalette, colorPickerObj.hex, selectedColor));
      setSelectedColor(colorPickerObj.hex);
    } else if (mode === "add") {
      setColorPalette(addColorToColorpalette(colorpalette, colorPickerObj.hex));
      setSelectedColor(colorPickerObj.hex);
      setMode("edit");
    }
    dispatch(addColorToUserPaletteAction(colorPickerObj.hex));
  };

  const handleClickColor = (color: string) => {
    setMode('edit');
    setPickerColor(color);
    setSelectedColor(color);
    setShowColorPicker(true);
  }

  const handleClickRemoveColor = (e: any, color: string) => {
    e.preventDefault();
    e.stopPropagation();
    const len = colorpalette?.split(',')?.length;
    if (len && len > 3) {
      if (selected) setColorPalette(removeColorFromColorpalette(colorpalette, color));
    } else {
      dispatch(setInfoAction("Use at least three colors to get good results!"));
    }
  };

  const handleCloseColorPicker = () => {
    setMode(undefined);
    setSelectedColor(undefined);
    setShowColorPicker(false);
  }

  const handleClickAddColor = () => {
    setMode("add");
    setShowColorPicker(true);
  };

  useClickOutsideHook(colorPickerRef, handleCloseColorPicker);

  return (
    <StyledSettingsContainer>
      <StyledRadioButtonSettings selected={selected} onClick={onClickSelected} />
      <StyledSettingsInner selected={selected}>
        <div>
          <label htmlFor="colorlimit">Color Palette</label>
          <QuestionIcon explanation="Choose the colors of your illustration to create a perfect graphic for your brand. Please note that you can always change colors after generation." />
        </div>
        <StyledColorPaletteSettings>
          {colorpaletteToHexList(colorpalette).map((color) => (
            <button className="fill" style={{backgroundColor: color}} key={'hex' + color} disabled={!selected} onClick={() => handleClickColor(color)}>
              <img src={trashIcon} onClick={(e) => handleClickRemoveColor(e, color)} alt="remove color" />
            </button>
          ))}
          <button className="add" disabled={!selected} onClick={handleClickAddColor}>
            <img src={addIcon} alt="add icon" />
          </button>
        </StyledColorPaletteSettings>
      </StyledSettingsInner>

      {showColorPicker && (
        <div className="picker" ref={colorPickerRef}>
          <SketchPicker presetColors={userPalette} color={pickerColor} onChange={(c) => setPickerColor(c)} onChangeComplete={handleChangeColorComplete} />
        </div>
      )}
    </StyledSettingsContainer>
  )
};