import styled from 'styled-components';

const StyledSuccessModal = styled.div`
  background-color: white;
  padding: 2rem;
  border-radius: 25px;
  h1 {
    color: green;
  }
  p {
    color: green;
  }
`

export {
  StyledSuccessModal
}