import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { GOOGLE_FONTS_API_URL } from '../../constants/fonts';
import { GoogleFont } from '../../types/google-font-type';


export const fetchFontList = createAsyncThunk('fontList/fetchFontListStatus', async (_, thunkAPI) => {
  try {
    const response = await fetch(
      `${GOOGLE_FONTS_API_URL}webfonts/v1/webfonts?key=${import.meta.env.VITE_GOOGLE_FONTS_API_KEY}&sort=popularity&subset=latin`,
    );
    const data = await response.json();
    return data.items.slice(0, 150); //Get only first 150 most popular fonts
  } catch (err: any) {
    return thunkAPI.rejectWithValue(err.response.data);
  }
});

type FontListState = {
  fontList: GoogleFont[];
  fontFamilyLoadedList: string[];
  loading: 'idle' | 'pending' | 'succeeded' | 'failed';
};

const initialState: FontListState = {
  fontList: [],
  fontFamilyLoadedList: [],
  loading: 'idle',
};

const fontListSlice = createSlice({
  name: 'fontList',
  initialState,
  reducers: {
    addFontFamilyLoaded(state, { payload }) {
      const fontFamilyLoadedListCopy = state.fontFamilyLoadedList.slice();
      fontFamilyLoadedListCopy.push(payload.fontFamily)
      state.fontFamilyLoadedList = [...new Set(fontFamilyLoadedListCopy)];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchFontList.fulfilled, (state, action) => {
        state.fontList = action.payload;
        state.loading = 'succeeded';
      })
      .addCase(fetchFontList.pending, (state) => {
        state.loading = 'pending';
      })
      .addCase(fetchFontList.rejected, (state) => {
        state.loading = 'failed';
      });
  },
});

export const { addFontFamilyLoaded } = fontListSlice.actions;

export default fontListSlice.reducer;
