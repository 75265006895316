import styled from 'styled-components';
import { COLORS } from '../../../../colors';

const StyledPromptInput = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1rem;
  width: 100%;
  justify-content: center;
  label {
    font-size: 14px;
    font-weight: bold;
  }
  textarea {
    font-weight: bold;
    cursor: pointer;
    text-align: center;
    border: none;
    padding: 1rem;
    font-style: italic;
    font-size: 24px;
    background-color: white;
    white-space: nowrap;
    border-top: solid 1px #e4e4e4;
    color: ${COLORS.mainBlue};
    flex: 1;
    opacity: 0.7;
    //only smartphone
    @media only screen and (max-width: 600px) {
      font-size: 20px;
    }
  }
  input:hover {
    opacity: 1;
    font-style: normal;
  }
`;

export {
  StyledPromptInput
}