import { ISocial } from '../../onlyFeTypes';
import { StyledSocialLink } from './social-button-style';
import instagramLogo from '../../images/social/instagram.svg';
import tiktokLogo from '../../images/social/tiktok.svg';
import xLogo from '../../images/social/x.svg';


interface ISocialButtonProps {
  social: ISocial
}

export const SocialButton = ({
  social
}: ISocialButtonProps) => {

  const renderSocialImage = (social: ISocial) => {
    switch (social) {
      case "instagram":
        return instagramLogo
      case "x":
      case "x-personal":
        return xLogo
      case "tiktok":
        return tiktokLogo
      default:
        break;
    }
  }

  const socialHref = (social: ISocial) => {
    switch (social) {
      case "instagram":
        return "https://www.instagram.com/illustrokeapp/"
      case "x":
        return "https://x.com/illustroke"
      case "x-personal":
        return "https://x.com/fabiocarbonejs"
      case "tiktok":
        return "https://www.tiktok.com/@illustrokeapp"
      default:
        break;
    }
  }

  return (
    <StyledSocialLink href={socialHref(social)} target="_blank" rel="noopener noreferrer">
      <img src={renderSocialImage(social)} alt='social logo' />
    </StyledSocialLink>
  );
};