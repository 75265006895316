import styled from 'styled-components';

const StyledSpacer = styled.div`
  height: 1rem;
`;

export const Spacer = () => {
  return (
    <StyledSpacer />
  )
}