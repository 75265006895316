import { StyledTextInput } from './text-input-style';

interface ITextInputProps {
  value: any,
  onChange: any,
  placeholder: string,
  type?: string
}

export const TextInput = ({
  value,
  onChange,
  placeholder,
  type = "text"
}: ITextInputProps) => {
  return (
    <StyledTextInput type={type} value={value} onChange={onChange} placeholder={placeholder} />
  );
};