import { StyledPageHeader } from './page-header-style';

interface IPageHeaderProps {
  title: string,
  subtitle: string
}

export const PageHeader = ({ title, subtitle }: IPageHeaderProps) => {
  return (
    <StyledPageHeader>
      <h1>{title}</h1>
      <p>{subtitle}</p>
    </StyledPageHeader>
  )
}