
import { IGenerateRepRequestParam, IGenerateRequestParam, IStatusRepRequestParam, IVariateRequestParam, IRedeemRequestParam, ICreatePromoRequestParam, ICanvaLoginRequestParams, ICreatePaymentIntentResponse, IConvertToSvgOnlyRequestParam, ICreatePaymentIntentRequestParams, IGenerateResponse, IGenerateRepResponse, IStatusRepResponse, IVariateResponse, IRedeemResponse, IJoinAffiliateResponse, ICreatePromoResponse, IConvertToSvgOnlyResponse, IGeneratePixelRequestParam } from "./apiTypes";
import { getAuth } from "firebase/auth";

import { setErrorAction } from "./store/actions/ui";
import { Dispatch } from "redux";

const BASE_URL = import.meta.env.VITE_BASE_URL;

let authenticatedPostRequestRepeat = true;

const authenticatedPostRequest = async (token: string, path: string, body: any, dispatch?: Dispatch) => {
  if (token) {
    try {
      const response = await fetch(`${BASE_URL}${path}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + token,
        },
        body: JSON.stringify(body)
      });

      if ((response.status === 403) && authenticatedPostRequestRepeat) {
        authenticatedPostRequestRepeat = false;
        const newToken = await getAuth().currentUser?.getIdToken();
        if (newToken) {
          const promise: any = authenticatedPostRequest(newToken, path, body);
          return promise
        } else {
          if(dispatch){
            dispatch(setErrorAction("Your session has expired. Refresh the page or login again."));
          }
          return { success: false }
        }
      }

      if (response.status === 200) {
        authenticatedPostRequestRepeat = true;
        return response.json();
      } else {
        return { success: false }
      }
    } catch (error) {
      return { error, success: false }
    }
  }
}

// Firebase functions call

export const generateApi = async (token: string, body: IGenerateRequestParam, dispatch: Dispatch) => {
  return authenticatedPostRequest(token, '/app/generate', body, dispatch) as Promise<IGenerateResponse>;
}

export const generateRepApi = async (token: string, body: IGenerateRepRequestParam) => {
  return authenticatedPostRequest(token, '/app/generateRep', body) as Promise<IGenerateRepResponse>;
}

export const statusRepApi = async (token: string, body: IStatusRepRequestParam) => {
  return authenticatedPostRequest(token, '/app/statusRep', body) as Promise<IStatusRepResponse>;
}

export const variateApi = async (token: string, body: IVariateRequestParam) => {
  return authenticatedPostRequest(token, '/app/variate', body) as Promise<IVariateResponse>;
}

export const redeemApi = async (token: string, body: IRedeemRequestParam) => {
  return authenticatedPostRequest(token, '/app/redeem', body) as Promise<IRedeemResponse>;
}

export const joinAffiliateApi = async (token: string) => {
  return authenticatedPostRequest(token, '/app/joinAffiliate', {}) as Promise<IJoinAffiliateResponse>;
}

export const createPromoApi = async (token: string, body: ICreatePromoRequestParam) => {
  return authenticatedPostRequest(token, '/app/createPromo', body) as Promise<ICreatePromoResponse>;
}

export const createPaymentIntentApi = async (token: string, body: ICreatePaymentIntentRequestParams) => {
  return authenticatedPostRequest(token, '/app/createPaymentIntent', body) as Promise<ICreatePaymentIntentResponse>;
}

export const createApiKeyApi = async (token: string) => {
  return authenticatedPostRequest(token, '/app/createApiKey', {});
}

export const deleteApiKeyApi = async (token: string) => {
  return authenticatedPostRequest(token, '/app/deleteApiKey', {});
}

export const canvaLoginApi = async (token: string, body: ICanvaLoginRequestParams) => {
  return authenticatedPostRequest(token, '/app/canvaLogin', body);
}

export const convertToSvgOnlyApi = async (token: string, body: IConvertToSvgOnlyRequestParam) => {
  return authenticatedPostRequest(token, '/app/convertToSvgOnly', body) as Promise<IConvertToSvgOnlyResponse>;
}

export const generatePixelApi = async (token: string, body: IGeneratePixelRequestParam, dispatch: Dispatch) => {
  return authenticatedPostRequest(token, '/app/generatePixel', body, dispatch) as Promise<IGenerateResponse>;
}
