import styled from 'styled-components';

const StyledInfoModal = styled.div`
  background-color: white;
  padding: 2rem;
  border-radius: 25px;
  h1 {
  }
  p {
  }
`

export {
  StyledInfoModal
}