import styled from 'styled-components';
import { COLORS } from '../../colors';

const StyledPageHeader = styled.div`
  background-color: ${COLORS.mainBlue};
  background: ${COLORS.mainGradient};
  width: 100%;
  padding: 2rem;
  box-sizing: border-box;
  color: white;
  p {
    max-width: 600px;
    margin: auto;
  }
`;

export {
  StyledPageHeader
}
