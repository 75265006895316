import { useState } from "react";
import LazyImage from "../../../../components/LazyImage";
import { Modal } from "../../../../components/modal/Modal";
import { EXAMPLE_IMAGES } from "../../../../images/examples";
import { StyledExampleModal } from "./example-modal-style";
import { IStyle } from "../../../../onlyFeTypes";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleChevronLeft, faCircleChevronRight } from "@fortawesome/free-solid-svg-icons";
import { COLORS } from "../../../../colors";

interface IExampleModalProps {
  show: boolean,
  handleClose: () => void,
  style: IStyle | null
}

export const ExampleModal = ({
  show,
  handleClose,
  style
}: IExampleModalProps) => {

  const [selectedExampleIndex, setSelectedExampleIndex] = useState<number>(0);

  const handleClickBack = () => {
    if (style?.examples?.length) {
      if (selectedExampleIndex > 0) setSelectedExampleIndex(prev => prev - 1);
      if (selectedExampleIndex === 0) setSelectedExampleIndex(style?.examples?.length - 1);
    }
  };
  const handleClickNext = () => {
    if (style?.examples?.length) {
      if (selectedExampleIndex < style?.examples?.length - 1) {
        setSelectedExampleIndex(prev => prev + 1);
      } else {
        setSelectedExampleIndex(0);
      }
    }
  };

  return (
    <Modal show={show} onClose={handleClose}>
      <StyledExampleModal>
        <h2>Examples | {style?.label}</h2>

        {style?.examples && style?.examples[selectedExampleIndex] && (
          <div>
            <p id="prompt"><i>"{style?.examples[selectedExampleIndex].prompt}"</i></p>
            <div className="slides">
              <button onClick={handleClickBack}>
                <FontAwesomeIcon icon={faCircleChevronLeft} color={COLORS.mainBlue} />
              </button>
              <div className="slides_center">
                <LazyImage customClassName="main" src={EXAMPLE_IMAGES[style?.examples[selectedExampleIndex].image]} alt={style?.examples[selectedExampleIndex].prompt} />
                <p>Right click to download the SVG</p>
              </div>
              <button onClick={handleClickNext}>
                <FontAwesomeIcon icon={faCircleChevronRight} color={COLORS.mainBlue} />
              </button>
            </div>
          </div>
        )}
      </StyledExampleModal>
    </Modal>
  );
};