import styled from 'styled-components';

const StyledCanvaLogin = styled.div`
  padding: 2rem;
  box-sizing: border-box;
  text-align: center;
  width: 100%;
  margin: auto;
  max-width: 400px;

  border-radius: 25px;

  #illustroke-logo {
    width: 50px;
  }

  .titles {
    margin-bottom: 1rem;
  }

  .select {
    display: flex;
    flex-direction: column;
    margin-bottom: 1rem;
    p.disclaimer {
      font-size: 12px;
    }
  }

  .login {
    padding-top: 1rem;
    display: flex;
    flex-direction: column;
    input {
      padding: 1rem;
      font-size: 16px;
      border-radius: 25px;
      margin-bottom: 1rem;
      text-align: center;
    }
    .buttons {
      display: flex;
      flex-direction: column;
    }
    button.forget {
      background-color: transparent;
      border: none;
      text-decoration: underline;
      margin-bottom: 1rem;
      cursor: pointer;
    }
  }

  .signup {
    .inputs {
      padding-top: 1rem;
      display: flex;
      flex-direction: column;
      input {
        margin-bottom: 1rem;
      }
    }
    .buttons {
      display: flex;
      flex-direction: column;
    }
  }

  .accountQuestion {
    div {
      display: flex;
      flex-direction: column;
    }

    .buttons {
      display: flex;
      flex-direction: column;
    }
  }

  .forget {
    .buttons {
      display: flex;
      flex-direction: column;
    }
  }

  .verify {
    .buttons {
      display: flex;
      flex-direction: column;
    }
  }
`

export {
  StyledCanvaLogin
}