import styled from 'styled-components';
import { COLORS } from '../../colors';

const StyledHome = styled.div`

  section {
    padding-top: 2rem;
    padding-bottom: 2rem;
    .titles {
      max-width: 600px;
      margin-bottom: 1rem;
      h1 {
        color: ${COLORS.mainBlue};
        font-size: 48px;
        line-height: 45px;
        margin-bottom: 1rem;
        //only smartphone
        @media only screen and (max-width: 600px) {
          font-size: 38px;
          line-height: 35px;
        }
      }
      p {
        font-size: 20px;
        //only smartphone
        @media only screen and (max-width: 600px) {
          font-size: 16px;
        }
      }
      margin-left: auto;
      margin-right: auto;
    }
  }

  section#main {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 90vh;
    position: relative;

    .counter {
      background-color: red;
      color: white;
      width: fit-content;
      padding: 5px 10px;
      font-size: 12px;
      border-radius: 10px;
      margin: auto;
      margin-bottom: 1rem;
    }
    //only smartphone
    @media only screen and (max-width: 600px) {
      padding-left: 2rem;
      padding-right: 2rem;
    }
    
    .inputs {
      textarea {
        display: block;
        margin: auto;
        width: 100%;
        text-align: center;
        border: 0;
        padding: 1rem 2rem;
        background-color: white;
        border-radius: 25px;
        font-size: 18px;
        box-sizing: border-box;
        max-height: 300px;
      }
      button {
        margin: auto;
      }
    }

    .tips {
      text-align: right;
      button {
        border: none;
        font-weight: bold;
        font-size: 16px;
        color: ${COLORS.mainBlue};
        background-color: transparent;

        //only smartphone
        @media only screen and (max-width: 600px) {
          font-size: 14px;
        }
      }
    }
  
    img#img1 {
      position: absolute;
      left: 0;
      width: 200px;
      bottom: -10px;
      @media only screen and (max-width: 600px) {
        width: 100px;
      }
      @media only screen and (max-width: 400px) {
        width: 100px;
      }
  
    }
    img#img2 {
      position: absolute;
      right: 0;
      top: 0;
      width: 200px;
  
      @media only screen and (max-width: 600px) {
        width: 150px;
      }
      @media only screen and (max-width: 400px) {
        width: 100px;
      }
    }
  }

  section#whyvector {  
    //only smartphone
    @media only screen and (max-width: 600px) {
      padding-left: 2rem;
      padding-right: 2rem;
    }

    background-color: ${COLORS.mainGray};
    padding-top: 6rem;
    padding-bottom: 6rem;

    .explanation {
      max-width: 500px;
      margin: auto;
      p {
        padding: 1rem;
      }
    }

    table {
      svg {
        width: 25px;
        height: auto;
      }
      td, th {
        border-radius: 5px;
        padding: 0.25rem 1rem;
        //only smartphone
        @media only screen and (max-width: 600px) {
          font-size: 14px;
        }
      }
      thead {
        th {
          color: white;
          background-color: ${COLORS.darkGray};
        } 
      }
      td{
        background-color: ${COLORS.headerGray};
      }
    }

    img.whyVectorImg {
      width: 300px;
    }
    h1 {
      font-size: 42px;
      line-height: 40px;
      color: ${COLORS.mainBlue};
    }
    table {
      margin: auto;
      background-color: white;
      padding: 1rem;
    }
  }

  section#howitworks{
    padding-top: 6rem;
    padding-bottom: 6rem;
    
    .howitworks-container{

      display: grid;
      grid-template-columns: 1fr 1fr;
      row-gap: 2rem;
      column-gap: 2rem;

      //only smartphone
      @media only screen and (max-width: 600px) {
        grid-template-columns: 1fr;
      }

      > div {
        background-color: ${COLORS.mainGray};
        padding: 1rem;
        border-radius: 25px;
        display: flex;
        flex-direction: column;
        h2 {
          font-size: 20px;
        }
        p {
          font-size: 14px;
        }
        img {
          padding: 1rem;
          width: 100%;
          margin: auto;
          max-width: 400px;
        }
        position: relative;

        .number {
          position: absolute;
          background-color: ${COLORS.mainBlue};
          width: 35px;
          height: 35px;
          border-radius: 25px;
          top: -10px;
          left: -10px;
          span {
            font-weight: bold;
            color: white;
            font-size: 25px;
            line-height: 35px;
          }
        }
      }

      > div.left {
        margin-left: auto;
        width: 65%;

        //only smartphone
        @media only screen and (max-width: 600px) {
          margin-right: auto;
        }
      }

      > div.rigth {
        margin-right: auto;
        width: 65%;

        //only smartphone
        @media only screen and (max-width: 600px) {
          margin-left: auto;
        }
      }
    }
  }

  section#example {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    min-height: 90vh;
    position: relative;

    //only smartphone
    @media only screen and (max-width: 600px) {
      padding: 2rem;
    }

    
    .examples {
      img {
        width: 200px;
        //only smartphone
        @media only screen and (max-width: 600px) {
          width: 100px;
        }
      }
    }
  }

  section#alltools {
    padding-top: 6rem;
    padding-bottom: 6rem;
    background-color: ${COLORS.headerGray};
    .cta {
      padding-top: 1rem;
      display: flex;
      flex-direction: column;
      gap: 1rem;
      a {
        background-color: ${COLORS.mainBlue};
        color: white;
        padding: 1rem 2rem;
        display: block;
        width: fit-content;
        margin: auto;
        border-radius: 25px;
      }
    }
  }
 
  section#seenin{
    

    .images {
      display: flex;
      width: 80vw;
      margin: auto;
      justify-content: center;
      align-items: center;

      //only smartphone
      @media only screen and (max-width: 600px) {
        width: 100%;
        flex-direction: column;
      }
      a {
        width: 20%;
        height: fit-content;
        margin: 1rem;
        //only smartphone
        @media only screen and (max-width: 600px) {
          width: 50%;
        }
        img {
          width: 100%;
          filter: grayscale(100%);
          
        }
      }
    }
  }
`

export {
  StyledHome
}