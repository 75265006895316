import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Footer } from './components/Footer';
import { Header } from './components/Header';
import { StyledLayout } from './layout-style';
import { RootState } from '../../store/store';
import { ErrorModal } from '../error-modal/ErrorModal';
import { LoginModal } from '../login-modal/LoginModal';
import { SuccessModal } from '../success-modal/SuccessModal';
import { InfoModal } from '../info-modal/InfoModal';
import { useLocation, useSearchParams } from 'react-router-dom';
import { getObjectFromLocalStorage } from '../../utils/localStorage';
import { setUserPaletteAction } from '../../store/actions/user';
import GenerateLoader from '../generate-loader/GenerateLoader';
import { Loader } from '../Loader';

type Props = {
  children: React.ReactNode,
};

export const Layout = ({ children }: Props) => {

  const location = useLocation();
  const dispatch = useDispatch();

  const loading = useSelector((state: RootState) => state.ui.loading);
  const generateLoading = useSelector((state: RootState) => state.ui.generateLoading);
  const loadingMessage = useSelector((state: RootState) => state.ui.loadingMessage);
  const error = useSelector((state: RootState) => state.ui.error);
  const success = useSelector((state: RootState) => state.ui.success);
  const info = useSelector((state: RootState) => state.ui.info);

  const [searchParams, _] = useSearchParams();

  // Set affiliate key in session storage if we found one:
  const affiliateKey = searchParams.get('aff');
  if (affiliateKey) {
    window.sessionStorage.setItem('affiliateKey', affiliateKey);
  }

  const showHeaderAndFooter = useMemo(() => {
    return (
      (location.pathname !== "/editor") &&
      (location.pathname !== "/canva-login")
    )
  }, [location.pathname]);

  useEffect(() => {
    const customUserPalette = getObjectFromLocalStorage('colors-palette-v0');
    if (customUserPalette) {
      dispatch(setUserPaletteAction(customUserPalette));
    }
  }, []);

  return (
    <StyledLayout>

      {showHeaderAndFooter && (<Header />)}

      <main>
        {children}
      </main>

      {showHeaderAndFooter && (<Footer />)}


      <LoginModal />

      {success && (
        <SuccessModal success={success} />
      )}

      {error && (
        <ErrorModal error={error} />
      )}

      {info && (
        <InfoModal info={info} />
      )}

      {loading && (
        <div className='loading'>
          <Loader />
          {loadingMessage && <div className='loadingMessage'><p>{loadingMessage}</p></div>}
        </div>
      )}

      {generateLoading && (
        <GenerateLoader />
      )}

    </StyledLayout>
  )
};