import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../store/store";
import { Button } from "../../button/Button";
import { Link, useLocation } from "react-router-dom";
import { setShowLoginModalAction } from "../../../store/actions/ui";

import logo from '../../../images/logo.png';
import menu from '../../../images/icons/menu.svg';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";

export const Header = () => {

  const location = useLocation();
  const userFirebase = useSelector((state: RootState) => state.user.userFirebase);
  const userInfo = useSelector((state: RootState) => state.user.userInfo);
  const dispatch = useDispatch();

  const [showSubHeader, setShowSubHeader] = useState<boolean>(false);
  
  const handleClickLogin = () => {
    dispatch(setShowLoginModalAction(true));
  };
  const [isMobileMenuOpened, setIsMobileMenuOpened] = useState<boolean>(false);
  const handleToggleSubHeader = () => {
    setShowSubHeader(!showSubHeader);
  };

  return (
    <header className="bg-orange text-white font-bold">
      <div className="header-desktop">
        <div>
          <Link to="/"><img src={logo} alt="illustroke logo" /></Link>
        </div>
        <div className="elements">
          <Link to="/" className={(location.pathname === '/' ? 'active' : '')}>HOME</Link>
          <Link to="/pricing" className={(location.pathname === '/pricing' ? 'active' : '')}>PRICING</Link>
          <Link to="/api" className={(location.pathname === '/api' ? 'active' : '')}>API</Link>
          <button onClick={handleToggleSubHeader} className="ai-tools" >
            {showSubHeader ? <FontAwesomeIcon icon={faChevronUp} /> : <FontAwesomeIcon icon={faChevronDown} />}
            AI TOOLS
          </button>
          {userFirebase ? (
            <>
              <Link to="/collection" className={(location.pathname === '/collection' ? 'active' : '')}>COLLECTION</Link>
              <Link to="/designs" className={(location.pathname === '/designs' ? 'active' : '')}>DESIGNS</Link>
              <Link to="/profile" className={(location.pathname === '/profile' ? 'active' : '')}>MY PROFILE</Link>
              {userInfo?.tokens !== undefined && (<Link to="/pricing"><div className="tokens"><p>{userInfo?.tokens} TOKENS</p></div></Link>)}
            </>
          ) : (
            <Button onClick={handleClickLogin} >
              <span data-testid="header-desktop-login-button">LOGIN</span>
            </Button>
          )}
        </div>
      </div>
      {showSubHeader && (
        <div className="sub-header-desktop">
          <Link to="/" className={(location.pathname === '/' ? 'active' : '')} onClick={handleToggleSubHeader} >VECTOR ILLUSTRATIONS</Link>
          <Link to="/convert" className={(location.pathname === '/convert' ? 'active' : '')} onClick={handleToggleSubHeader}>IMAGE TO VECTOR</Link>
          <Link to="/pixel-illustrations" className={(location.pathname === '/pixel-illustrations' ? 'active' : '')} onClick={handleToggleSubHeader}>PIXEL ILLUSTRATIONS</Link>
        </div>
      )}
      <div className="header-mobile">
        <div className="icons">
          <Link to="/"><img src={logo} alt="illustroke logo" /></Link>
          <button onClick={() => setIsMobileMenuOpened(!isMobileMenuOpened)} id="hamburger"><img src={menu} alt="hamburger icon" /></button>
        </div>
        {isMobileMenuOpened && (
          <div className="elements">
            <Link onClick={() => setIsMobileMenuOpened(false)} to="/api">API</Link>
            <Link onClick={() => setIsMobileMenuOpened(false)} to="/pricing">PRICING</Link>
            <Link onClick={() => setIsMobileMenuOpened(false)} to="/convert">IMAGE TO VECTOR</Link>
            <Link onClick={() => setIsMobileMenuOpened(false)} to="/pixel-illustrations">PIXEL ILLUSTRATIONS</Link>
            {userFirebase ? (
              <>
                <Link onClick={() => setIsMobileMenuOpened(false)} to="/collection">COLLECTION</Link>
                <Link onClick={() => setIsMobileMenuOpened(false)} to="/profile">MY PROFILE</Link>
              </>
            ) : (
              <Button onClick={handleClickLogin}>
                <span>LOGIN</span>
              </Button>
            )}
          </div>
        )}
      </div>
    </header>
  )
};