import { ColorMode } from "../../../../types";
import { StyledImageToVectorColormode } from "./image-to-vector-colormode-style";
import bwImage from '../../../../images/bw.png';
import colorImage from '../../../../images/color.png';
import { useEffect } from "react";
import { NoAdvancedSettingsApplied } from "../../../generate/components/GenerateColormode/components/no-advanced-settings-applied/NoAdvancedSettingsApplied";
import { ColorLimitSettings } from "../../../generate/components/GenerateColormode/components/color-limit-settings/ColorLimitSettings";
import { ColorPaletteSettings } from "../../../generate/components/GenerateColormode/components/color-palette-settings/ColorPaletteSettings";
import { IAdvancedSetting } from "../../../generate/Generate";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import { COLORS } from "../../../../colors";

export const isColorLimitValid = (colorlimit?: number) => {
  return (!!colorlimit) && (colorlimit > 1) && (colorlimit < 10)
}

interface IImageToVectorColormodeProps {
  colormode: ColorMode,
  setColormode: (c: ColorMode) => void,
  colorlimit?: number,
  setColorLimit: (l: number | undefined) => void,
  colorpalette?: string,
  setColorPalette: (palette: string) => void,
  selectedAdvancedSettings: IAdvancedSetting,
  setSelectedAdvancedSettings: (a: IAdvancedSetting) => void,
  showAdvancedSettings: boolean,
  setShowAdvancedSettings: (b: boolean) => void
}

export const ImageToVectorColormode = ({
  colormode,
  setColormode,
  colorlimit,
  setColorLimit,
  colorpalette,
  setColorPalette,
  selectedAdvancedSettings,
  setSelectedAdvancedSettings,
  showAdvancedSettings,
  setShowAdvancedSettings
}: IImageToVectorColormodeProps) => {

  const handleToggleShowAdvancedSetting = () => {
    setShowAdvancedSettings(!showAdvancedSettings);
  };

  useEffect(() => {
    // Reset some parameters when click on black and white
    if (colormode === "bw") {
      setShowAdvancedSettings(false);
      setSelectedAdvancedSettings("none");
    }
  }, [colormode]);

  const handleClickNoSettings = () => {
    setSelectedAdvancedSettings("none");
  };

  return (
    <StyledImageToVectorColormode>
      <div className="colormodeSelector">
        {(
          <button className={colormode === 'color' ? "selected" : ""} onClick={() => setColormode('color')}>
            <img src={colorImage} alt="colored" />
            {(colormode === 'color') && <FontAwesomeIcon icon={faCheckCircle} color={COLORS.mainBlue} className="checkImg" />}
          </button>
        )}
        <button className={colormode === 'color' ? "" : "selected"} onClick={() => setColormode('bw')}>
          <img src={bwImage} alt="black and white" />
          {(colormode === 'bw') && <FontAwesomeIcon icon={faCheckCircle} color={COLORS.mainBlue} className="checkImg" />}
        </button>
      </div>

      <div className="guides">
        {(colormode === "color") && <p>Convert to a <b>full colored</b> vector illustration.</p>}
        {(colormode === "bw") && (<p>Convert to a <b>black and white</b> vector illustration.</p>)}
      </div>

      {(colormode === "color") && (
        <div className="showAdvancedSettings">
          <button onClick={handleToggleShowAdvancedSetting}>
            {showAdvancedSettings ? 'Close' : 'Show'} advanced setting
          </button>
        </div>
      )}

      {showAdvancedSettings && (colormode === "color") && (
        <div className="advancedSettings">
          <NoAdvancedSettingsApplied
            onClickSelected={handleClickNoSettings}
            selected={(selectedAdvancedSettings === "none")}
          />
          <ColorLimitSettings
            onClickSelected={() => setSelectedAdvancedSettings("colorlimit")}
            selected={(selectedAdvancedSettings === "colorlimit")}
            colorlimit={colorlimit}
            setColorLimit={setColorLimit}
          />
          <ColorPaletteSettings
            onClickSelected={() => setSelectedAdvancedSettings("colorpalette")}
            selected={(selectedAdvancedSettings === "colorpalette")}
            colorpalette={colorpalette}
            setColorPalette={setColorPalette}
          />
        </div>
      )}
    </StyledImageToVectorColormode>
  )
};