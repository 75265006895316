import React from 'react';
import closeIcon from '../../images/icons/close.svg';
import { StyledModal } from './modal-style';

type ModalProps = {
  children: React.ReactNode,
  show: boolean,
  onClose: () => void
};

export const Modal = ({ children, show, onClose }: ModalProps) => {
  if (show) {
    const handleClickInside = (e: any) => {
      e.stopPropagation();
    }
    return (
      <StyledModal onClick={onClose}>
        <div onClick={handleClickInside}>
          <button className='closeButton' type='button' onClick={onClose}>
            <img src={closeIcon} alt='close icon' />
          </button>
          {children}
        </div>
      </StyledModal>
    );
  }
  return null;
};