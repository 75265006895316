import styled from 'styled-components';
import { COLORS } from '../../../../colors';

const StyledImageToVectorColormode = styled.div`
  padding: 2rem;
  .colormodeSelector {
    display: flex;
    flex-direction: row;
    gap: 2rem;
    justify-content: center;
    button {
      width: 125px;
      height: 125px;
      border: none;
      background-color: transparent;
      opacity: 0.25;
      position: relative;
      padding: 0;

      border-radius: 25px;
      box-shadow: rgba(0, 0, 0, 0.5) 0px 5px 15px;

      img {
        border-radius: 25px;
        width: 100%;
        height: 100%;
      }

      svg.checkImg {
        position: absolute;
        width: 35px;
        height: 35px;
        bottom: 5px;
        left: 5px;
        background-color: white;
        border-radius: 100%;
      }
    }
    button.selected {
      opacity: 1;
    }
  }
  .guides {
    padding-top: 1rem;
  }
  .buttons {
    padding-top: 1rem;
    button {
      margin: auto;
    }
  }

  .showAdvancedSettings {
    padding-top: 1rem;
    button {
      background-color: transparent;
      border: none;
      color: ${COLORS.mainBlue};
      font-weight: bold;
    }
  }

  .advancedSettings {
    padding-top: 2rem;
    padding-bottom: 1rem;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
  }
`;

const StyledRadioButtonSettings = styled.button < { selected?: boolean }>`
  width: 35px;
  height: 35px;
  border-radius: 100%;
  border: solid 7px white;
  background-color: ${props => (props.selected) ? "blue" : "white"};
`;

const StyledSettingsContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
  position: relative;
  .picker {
    position: absolute;
    right: 0%;
    top: -100%;
  }
`;

const StyledSettingsInner = styled.div< { selected?: boolean }>`
  flex: 1;
  opacity: ${props => (props.selected) ? 1 : 0.5};
  background-color: white;
  padding: 1rem;
  border-radius: 25px;
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 2rem;
`;

export {
  StyledImageToVectorColormode,
  StyledRadioButtonSettings,
  StyledSettingsContainer,
  StyledSettingsInner
}