import { createSlice } from '@reduxjs/toolkit';

export const SELECTED_OBJECT_SLICE = 'selected';

//array of ids
const initialState: { selected: string[], objectToSelectWhenCreated: string } = {
  selected: [],
  objectToSelectWhenCreated: "" //id of the object to select when it will be created
};

export const selectedObjectSlice = createSlice({
  name: SELECTED_OBJECT_SLICE,
  initialState,
  reducers: {
    setAll(state, { payload }) {
      state.selected = payload;
    },
    addOne(state, { payload }) {
      state.selected = [...state.selected, payload];
    },
    removeOne(state, { payload }) {
      state.selected = state.selected.filter((id) => id !== payload);
    },
    resetAll(state) {
      state.selected = [];
    },
    setObjectToSelectWhenCreated(state, { payload }) {
      state.objectToSelectWhenCreated = payload
    },
    resetObjectToSelectWhenCreated(state) {
      state.objectToSelectWhenCreated = ""
    }
  },
});

const selectedObjectReducer = selectedObjectSlice.reducer;

export const selectedObjectActions = selectedObjectSlice.actions;
export default selectedObjectReducer;
