import { DESIGNS_SUB_COLLECTION } from "../firestoreApi";

export const removeQueryStringFromUrl = (url: string) => {
  return url.split("?")[0];
};

export const getIllustrationUri = (uid: string, itemId: string) => {
  return `${import.meta.env.VITE_FIREBASE_STORAGE_URL}/${uid}/${itemId}.svg`
};

export const getDesignPreview = (uid: string, designId: string) => {
  return `${import.meta.env.VITE_FIREBASE_STORAGE_URL}/${uid}/${DESIGNS_SUB_COLLECTION}/${designId}/preview.jpg`
};

export const getEditorShapeSvg = (shapeName: string) => {
  return `${import.meta.env.VITE_FIREBASE_STORAGE_URL}/shapes/${shapeName}.svg`;
};

export const getPixelIllustrationUri = (uid: string, itemId: string) => {
  return `${import.meta.env.VITE_FIREBASE_STORAGE_URL}/${uid}/${itemId}.png`
};