import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button } from "../../../../components/button/Button";
import { Modal } from "../../../../components/modal/Modal";
import { StyledReplicateConfirmModal } from "./replicate-confirm-modal-style";
import { faIcicles } from "@fortawesome/free-solid-svg-icons";

interface IReplicateConfirmModalProps {
  show: boolean,
  handleClose: () => void,
  handleClickConfirm: () => void
}

export const ReplicateConfirmModal = ({
  show,
  handleClose,
  handleClickConfirm
}: IReplicateConfirmModalProps) => {

  return (
    <Modal show={show} onClose={handleClose}>
      <StyledReplicateConfirmModal>

        <div className="coldBootIcon">
          <FontAwesomeIcon icon={faIcicles} color="white" />
        </div>

        <h2>You selected a cold boot model</h2>

        <h4>✅ Better style consistency</h4>
        <p>The cold boot models have been trained with more specific images, allowing them to maintain the original style. Use them to create a collection with a unique style.</p>

        <h4>⌛ The generation takes longer</h4>
        <p>When a cold boot model hasn't been used for a while, we turn it off and can take time to turn it on (from 3 to 5 minutes). This only happens on startup.</p>


        <div className="buttons">
          <Button onClick={handleClickConfirm}>Understood</Button>
          <Button variant="secondary" onClick={handleClose}>Cancel</Button>
        </div>

      </StyledReplicateConfirmModal>
    </Modal>
  );
};