import styled from 'styled-components';
import { COLORS } from '../../../../colors';

const StyledGenerateConfirm = styled.div`
  padding-top: 3rem;
  .promptResume {
    cursor: pointer;
  }
  .resume {
    display: flex;
    > div {
      padding: 0 1rem;

      //only smartphone
      @media only screen and (max-width: 600px) {
        padding: 0.5rem;
      }
      h3 {
        font-size: 16px;
        padding-bottom: 0.5rem;
      }
      p {
        font-size: 16px;
        font-style: italic;
        font-weight: bold;
        color: ${COLORS.mainBlue};
      }
      img {
        width: 100px;
        //only smartphone
        @media only screen and (max-width: 600px) {
          width: 80px;
        }
        border-radius: 25px;
        box-shadow: rgba(0, 0, 0, 0.25) 0px 5px 15px;
      }
    }
    
    .colormodeResume{
      cursor: pointer;
      position: relative;
    }
    .styleResume {
      cursor: pointer;
    }
    .objectmodeResume {
      cursor: pointer;
    }
  }
  .buttons {
    button {
      margin: auto;
    }
  }
  .advancedSetting {
    width: fit-content;
    margin: auto;
    align-items: center;
    background-color: white;
    padding: 0.5rem 1rem;
    margin-bottom: 1rem;
    margin-top: 1rem;
    border-radius: 25px;
    position: relative;
    text-align: left;
    display: flex;
    justify-content: space-between;
    gap: 1rem;
    .fills {
      display: flex;
      gap: 5px;
      .fill {
        box-shadow: 0px 0px 2px #888888;
        width: 35px;
        height: 35px;
        border-radius: 5px;
      }
    }
  }
`

export {
  StyledGenerateConfirm,
}