import styled from 'styled-components';

const StyledExampleModal = styled.div`
  background-color: white;
  padding: 2rem;
  border-radius: 25px;
  
  p#prompt {
    font-size: 20px;
  }

  .slides {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    .slides_center {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 30vw;
      padding-top: 2rem;
      
      //only smartphone
      @media only screen and (max-width: 600px) {
        width: 80vw;
      }
      p {
        padding-top: 1rem;
        font-size: 12px;
      }
    }
    button {
      background-color: transparent;
      border: none;
      svg {
        width: 40px;
        height: auto;
        //only smartphone
        @media only screen and (max-width: 600px) {
          width: 30px;
        }
      }
    }
  }
`

export {
  StyledExampleModal
}