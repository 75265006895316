import { ItemType } from "../types"

export const itemToDefaultTokens = (item: ItemType): number => {
  switch (item) {
    case "personal":
      return parseInt(import.meta.env.VITE_PERSONAL_TIER_TOKENS || "0")
    case "growth":
      return parseInt(import.meta.env.VITE_PROFESSIONAL_TIER_TOKENS || "0")
    default:
      return 0
  }
}

export const itemToDefaultPrice = (item: ItemType, applyAffiliateDiscount?: boolean): number => {
  let price = 0;
  switch (item) {
    case "personal":
      price = parseFloat(import.meta.env.VITE_PERSONAL_TIER_PRICE || "0")
      break;
    case "growth":
      price = parseFloat(import.meta.env.VITE_PROFESSIONAL_TIER_PRICE || "0")
      break
    default:
      break
  }

  if (applyAffiliateDiscount && import.meta.env.VITE_AFFILIATE_USER_DISCOUNT) {
    price = price - (price * parseInt(import.meta.env.VITE_AFFILIATE_USER_DISCOUNT)) / 100
  }

  return price
}

const currencyToSymbol = (currency: string) => {
  switch (currency) {
    case "usd":
      return "$"
    case "eur":
      return "€"
    default:
      return currency
  }
}

export const stripeAmountAndCurrencyToPrice = (amount?: number, currency?: string) => {
  if (amount && typeof (amount) === "number" && currency) {
    return `${(amount / 100).toFixed(2)} ${currencyToSymbol(currency)}`
  }
  return "PRICE ERROR"
}

export const formatPrice = (price: number, currency = "usd") => {
  if (price && typeof (price) === "number" && currency) {
    return `${(price).toFixed(2)} ${currencyToSymbol(currency)}`
  }
  return "PRICE ERROR"
}