import styled from 'styled-components';

const StyledSocialLink = styled.a`
  height: 40px;
  img {
    width: 40px;
    height: 40px;
  }
`

export {
  StyledSocialLink
}