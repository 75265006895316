import styled from 'styled-components';
import { COLORS } from '../../../../colors';

const StyledGenerateStyle = styled.div`
  padding-top: 2rem;
  h2 {
    padding-bottom: 1rem;
  }
  .categories {
  
    padding-bottom: 1rem;
    
    //only smartphone
    @media only screen and (max-width: 600px) {
      width: 100vw;
      overflow-x: scroll;
    }

    .wrapperButtons {
      display: flex;
      gap: 1rem;
      padding-left: 1rem;
      padding-right: 1rem;
      justify-content: center;

      //only smartphone
      @media only screen and (max-width: 600px) {
        justify-content: left;
      }
      
      button {
        border-radius: 25px;
        padding: 0.75rem 1rem;
        background-color: white;
        color: ${COLORS.mainBlue};
        border: none;
        font-size: 16px;
        font-weight: bold;
      }
      button.selected {
        background-color: ${COLORS.mainBlue};
        color: white;
      }
    }
    
  }
  .styles {
    max-width: 800px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;

    //only smartphone
    @media only screen and (max-width: 600px) {
      grid-template-columns: 1fr 1fr;
    }

    grid-gap: 1rem;
    background-color: ${COLORS.headerGray};
    border-radius: 25px;
    padding: 1rem;
    button {
      border-radius: 50px;
      border: 0;
      padding: 0;
      cursor: pointer;
      background-color: transparent;
      img {
        border-radius: 25px;
        width: 100%;
        height: 100%;
        min-width: 100px;
        min-height: 100px;
      }

      position: relative;
      .coldBootIcon {
        background-color: #00bcd4;
        position: absolute;
        top: -4px;
        right: -4px;
        width: 30px;
        height: 30px;
        border-radius: 100%;
        opacity: 1;
        padding: 4px;
        svg {
          width: 25px;
          height: auto;
        }
      }
      
      .overlay {
        position: absolute;
        background-color: #0000007d;
        width: 100%;
        height: 100%;
        justify-content: space-evenly;
        border-radius: 25px;
        display: none;
        flex-direction: column;
        //only smartphone
        @media only screen and (max-width: 600px) {
          display: flex;
        }
        
        p {
          color: white;
          font-weight: bold;
          font-size: 22px;
        }
        div {
          background-color: white;
          color: ${COLORS.mainBlue};
          padding: 0.4rem 0.8rem;
          border-radius: 25px;
          width: fit-content;
          margin-left: auto;
          margin-right: auto;
        }
        div:hover {
          opacity: 0.8;
        }
      }
    }
    button:hover {
      .overlay {
        display: flex;
      }
    }
    button.suggest {
      background-color: white;
      border-radius: 25px;
      font-size: 20px;
      min-height: 150px;
    }
  }
  .no-style {
    background-color: white;
    border-radius: 25px;
    padding: 1rem;
    max-width: 800px;
    button {
      margin:auto;
      margin-top: 1rem;
    }
  }
  .disclaimer {
    padding-top: 1rem;
    color: ${COLORS.mainBlue};
    font-size: 12px;
  }
`;

export {
  StyledGenerateStyle,
}