import { createUserWithEmailAndPassword, getAuth, GoogleAuthProvider, sendEmailVerification, sendPasswordResetEmail, signInWithEmailAndPassword, signInWithPopup } from "firebase/auth";
import { useDispatch, useSelector } from 'react-redux';
import { StyledCanvaLogin } from './canva-login-style';
import { useCallback, useEffect, useState } from 'react';
import googleLogo from '../../images/google.png';
import { setErrorAction, setLoadingAction, setSuccessAction } from '../../store/actions/ui';
import { isValidEmail } from '../../utils/emails';
import { Link, useLocation } from 'react-router-dom';
import { Button } from "../../components/button/Button";
import { Spacer } from "../../components/Spacer";
import { TextInput } from "../../components/text-input/TextInput";
import { canvaLoginApi } from "../../api";
import { RootState } from "../../store/store";
import logo from '../../images/logo.png';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";

type IStep = "select" | "accountQuestion" | "login" | "signup" | "forget" | "verify";

export const CanvaLogin = () => {

  // When we open the canva popup users are redirected here
  // http://localhost:3000/canva-login?user=......&brand=......&time=.....&extensions=.....&state=.....&canva_user_token=....
  // We log the user, then we redirect to canva, so the popup close

  const dispatch = useDispatch();
  const location = useLocation();

  const auth = getAuth();

  const [step, setStep] = useState<IStep>("select");
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [newEmail, setNewEmail] = useState<string>("");
  const [newPassword, setNewPassword] = useState<string>("");
  const [newRepeatPassword, setNewRepeatPassword] = useState<string>("");
  const [forgetEmail, setForgetEmail] = useState<string>("");


  const token: any = useSelector((state: RootState) => state.user.token);
  const userFirebase = useSelector((state: RootState) => state.user.userFirebase);

  useEffect(() => {
    console.log(userFirebase);
    if (token && userFirebase && userFirebase.emailVerified) {
      redirectToCanva();
    }
  }, [token, userFirebase]);

  const redirectToCanva = useCallback(() => {
    if (token) {
      const urlSearchParams = new URLSearchParams(location.search);

      const canva_user_token = urlSearchParams.get('canva_user_token');
      const state = urlSearchParams.get('state');
      const nonceQuery = urlSearchParams.get('nonce') || "";

      if (canva_user_token && state && nonceQuery) {
        // First call canvaLogin on firebase (it associate the canvaId with firebaseId)
        canvaLoginApi(token, { canva_user_token, nonceQuery, state }).then(data => {
          const newUrlSearchParam = new URLSearchParams();
          if (data?.success) {
            // Create query parameters for redirecting back to Canva (this close the popup)
            newUrlSearchParam.append("success", "true");
            newUrlSearchParam.append("state", state);
            const redirectUrl = `https://canva.com/apps/configured?${newUrlSearchParam.toString()}`;
            window.location.href = redirectUrl;
          } else {
            dispatch(setErrorAction("Unexpecter error during association. Pleas retry or contact Illustroke support."));
            newUrlSearchParam.append("success", "false");
            const redirectUrl = `https://canva.com/apps/configured?${newUrlSearchParam.toString()}`;
            window.location.href = redirectUrl;
          }
        })
      } else {
        const newUrlSearchParam = new URLSearchParams();
        dispatch(setErrorAction("Unexpecter error during association. Pleas retry or contact Illustroke support."));
        newUrlSearchParam.append("success", "false");
        const redirectUrl = `https://canva.com/apps/configured?${newUrlSearchParam.toString()}`;
        window.location.href = redirectUrl;
      }
    }
  }, [token, location.search]);

  const handeClickSigninWithGoole = () => {
    const provider = new GoogleAuthProvider();
    dispatch(setLoadingAction(true));
    signInWithPopup(auth, provider)
      .then(() => {
      }).catch((error) => {
        dispatch(setLoadingAction(false));
        dispatch(setErrorAction(error.message));
      });
  }

  const handleClickCreateWithEmail = () => {
    if (newEmail) {
      if (isValidEmail(newEmail)) {
        if (newPassword === newRepeatPassword) {
          const auth = getAuth();
          dispatch(setLoadingAction(true));
          createUserWithEmailAndPassword(auth, newEmail, newPassword)
            .then(() => {
              if (auth?.currentUser) {
                sendEmailVerification(auth.currentUser)
                  .then(() => {
                    dispatch(setLoadingAction(false));
                    setStep('verify');
                  });
              }
            })
            .catch((error) => {
              dispatch(setLoadingAction(false));
              dispatch(setErrorAction(error.message));
            });
        } else {
          dispatch(setErrorAction("Passwords does not match. Retry"));
        }
      } else {
        dispatch(setLoadingAction(true));
        setTimeout(() => {
          dispatch(setLoadingAction(false));
          dispatch(setErrorAction("Email not valid. Retry"));
        }, 5000);
      }
    } else {
      dispatch(setErrorAction("Missing email"));
    }
  };

  const handeClickSigninWithEmail = () => {
    dispatch(setLoadingAction(true));
    signInWithEmailAndPassword(auth, email, password)
      .then(() => {
      })
      .catch((error) => {
        dispatch(setLoadingAction(false));
        dispatch(setErrorAction(error.message));
      });
  };

  const handleClickForget = () => {
    const auth = getAuth();
    dispatch(setLoadingAction(true));
    sendPasswordResetEmail(auth, email)
      .then(() => {
        dispatch(setLoadingAction(false));
        dispatch(setSuccessAction("We sent a password reset email. Check your email"));
        setStep("login");
      })
      .catch((error) => {
        dispatch(setLoadingAction(false));
        dispatch(setErrorAction(error.message));
      });

  };

  const handleClickVerified = () => {
    const auth = getAuth();
    auth.currentUser?.reload().then(() => {
      if (auth.currentUser?.emailVerified) {
        redirectToCanva();
      } else {
        dispatch(setErrorAction(`Your email is not verified. We have sent a verification email to ${auth.currentUser?.email}. Before continuing click on the link and refresh the page. If you can't find the email, check your spam.`))
      }
    });
  };

  return (
    <StyledCanvaLogin>

      <img id='illustroke-logo' src={logo} alt="illustroke logo white" />

      {(step !== 'verify') && (
        <div className='titles'>
          <h1>Welcome!</h1>
          <p>Associate your illustroke account with your <b>canva</b> account.</p>
        </div>
      )}

      {(step === 'select') && (
        <div className='select'>

          <Button onClick={handeClickSigninWithGoole}>
            <img src={googleLogo} alt='google logo' />
            <span>Continue with Google</span>
          </Button>

          <Spacer />

          <Button onClick={() => setStep('accountQuestion')}>
            <FontAwesomeIcon icon={faEnvelope} color='white' />
            <span>Continue with Email</span>
          </Button>

          <Spacer />

          <p className='disclaimer'>By clicking the continue, I hereby agree to and accept the <Link to="/terms">Terms and Conditions</Link> and <Link to="/privacy">Privacy Policy</Link> </p>

        </div>
      )}

      {(step === 'accountQuestion') && (
        <div className='accountQuestion'>
          <p><b>Do you have an account?</b></p>
          <Spacer />

          <div>
            <Button onClick={() => setStep("login")}>
              <span>Yes, I have already an account</span>
            </Button>

            <Spacer />

            <Button onClick={() => setStep("signup")}>
              <span>No, I want to create one</span>
            </Button>
          </div>
        </div>
      )}

      {(step === 'login') && (
        <div className='login'>
          <TextInput value={email} onChange={(e: any) => setEmail(e.target.value)} placeholder="your@email.com" />
          <TextInput type='password' value={password} onChange={(e: any) => setPassword(e.target.value)} placeholder="your password" />

          <button className='forget' onClick={() => setStep("forget")}>
            <span>Forgot your password?</span>
          </button>

          <div className='buttons'>
            <Button onClick={handeClickSigninWithEmail}>
              <span>Login</span>
            </Button>
            <Button variant='secondary' onClick={() => setStep("select")}>
              <span>Back</span>
            </Button>
          </div>
        </div>
      )}

      {(step === 'signup') && (
        <div className='signup'>
          <p><b>Let's create it</b></p>
          <Spacer />

          <div className='inputs'>
            <TextInput value={newEmail} onChange={(e: any) => setNewEmail(e.target.value)} placeholder="your@email.com" />
            <TextInput type='password' value={newPassword} onChange={(e: any) => setNewPassword(e.target.value)} placeholder="Password" />
            <TextInput type='password' value={newRepeatPassword} onChange={(e: any) => setNewRepeatPassword(e.target.value)} placeholder="Repeat Password" />
          </div>

          <div className='buttons'>
            <Button onClick={handleClickCreateWithEmail}>
              <span>Enter</span>
            </Button>
            <Button variant='secondary' onClick={() => setStep("select")}>
              <span>Back</span>
            </Button>
          </div>
        </div>
      )}

      {(step === 'forget') && (
        <div className='forget'>
          <p><b>Write your email</b></p>
          <Spacer />
          <TextInput value={forgetEmail} onChange={(e: any) => setForgetEmail(e.target.value)} placeholder="your@email.com" />
          <Spacer />
          <div className='buttons'>
            <Button onClick={handleClickForget}>
              <span>Send me an email</span>
            </Button>
            <Button variant='secondary' onClick={() => setStep("login")}>
              <span>Back</span>
            </Button>
          </div>
        </div>
      )}

      {(step === 'verify') && (
        <div className='verify'>
          <p>We have sent a verification email to <b>{newEmail}</b>. Before continuing click on the link. If you can't find the email, check your spam.</p>
          <Spacer />
          <div className='buttons'>
            <Button onClick={handleClickVerified}>
              <span>Yes, I have verified my account</span>
            </Button>
          </div>
        </div>
      )}

    </StyledCanvaLogin>
  );
};