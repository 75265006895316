import { Spacer } from "../../../../components/Spacer";
import { ColorMode } from "../../../../types";
import { StyledImageToVectorConfirm } from "./image-to-vector-confirm";
import colorImage from '../../../../images/color.png';
import bwImage from '../../../../images/bw.png';
import { colorpaletteToHexList } from "../../../../utils/colors";
import { ImageToVectorStep } from "../../ImageToVector";
import { IAdvancedSetting } from "../../../generate/Generate";
import { isColorLimitValid } from "../../../generate/components/GenerateColormode/GenerateColormode";

interface IImageToVectorConfirmProps {
  setStep: (s: ImageToVectorStep) => void,
  colormode: ColorMode,
  colorpalette?: string,
  colorlimit?: number,
  selectedAdvancedSettings: IAdvancedSetting,
  fileDropped?: File
}

export const ImageToVectorConfirm = ({
  setStep,
  colormode,
  colorpalette,
  colorlimit,
  selectedAdvancedSettings,
  fileDropped
}: IImageToVectorConfirmProps) => {
  
  return (
    <StyledImageToVectorConfirm>
      <div className="resume">
        <div className="colormodeResume" onClick={() => setStep("colormode")}>
          <h3>Color mode:</h3>
          {(colormode === 'color') && (
            <img src={colorImage} alt="color" />
          )}
          {(colormode === 'bw') && (
            <img src={bwImage} alt="black and white" />
          )}
        </div>

        <div className="fileResume">
            <h3>File:</h3>
            <p>{fileDropped?.name}</p>
        </div>
      </div>

      {(selectedAdvancedSettings === "colorlimit") && !!colorlimit && isColorLimitValid(colorlimit) && (
        <div className="advancedSetting">
          <p>Color limit:</p>
          <span><b>{colorlimit}</b></span>
        </div>
      )}

      {(selectedAdvancedSettings === "colorpalette") && !!colorpalette && (
        <div className="advancedSetting">
          <p>Color palette:</p>
          <div className="fills">
            {colorpaletteToHexList(colorpalette).map(color => (
              <div className="fill" key={color} style={{ backgroundColor: color }} />
            ))}
          </div>
        </div>
      )}

      <Spacer />
      <Spacer />

      <h2>It's everything ok?</h2>
      <p>This will cost you <b>1</b> token</p>
      <Spacer />
    </StyledImageToVectorConfirm>
  )
};