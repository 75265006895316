import React, { useState, useEffect } from 'react';
import styled, { keyframes } from 'styled-components';

const fadeIn = keyframes`
  from {
    opacity: 0.05;
  }
  to {
    opacity: 0.15;
  }
`;

const rotateAnimation = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;


const StledLazyImage = styled.div`
  width: 100%;
  min-width: 100px;
  .loader {
    width: 100%;
    aspect-ratio: 1;
    border-radius: 25px;
    animation: ${fadeIn} 1s ease-in-out forwards;
    animation-iteration-count: infinite;
    background-color: blue;
    .spinning-loader {
      border: 10px solid #f3f3f3;
      border-top: 10px solid blue;
      border-radius: 50%;
      width: 20px;
      height: 20px;
      animation: ${rotateAnimation} 1.5s linear infinite;
      position: absolute;
      left: calc(50% - 20px);
      top: calc(50% - 25px);
    }
  }
`;

interface ILazyImageProps {
  src: string,
  alt: string,
  customClassName?: string,
  id?: string
}

const areEqual = (
  prevProps: ILazyImageProps,
  nextProps: ILazyImageProps
) => {
  return (
    prevProps.src === nextProps.src &&
    prevProps.alt === nextProps.alt &&
    prevProps.customClassName === nextProps.customClassName
  );
};

const LazyImage = React.memo(
  ({ src, alt, customClassName, id }: ILazyImageProps) => {
    const [loaded, setLoaded] = useState(false);
    const [retryCount, setRetryCount] = useState(0);
    const [imageSrc, setImageSrc] = useState(src);

    const handleImageLoaded = () => {
      setLoaded(true);
    };

    const handleImageError = () => {
      setRetryCount(prevRetryCount => prevRetryCount + 1);
    };

    useEffect(() => {
      if (retryCount > 0) {
        const retryTimeout = setTimeout(() => {
          setImageSrc(`${src}?retry=${retryCount}`);
        }, 3000);

        return () => clearTimeout(retryTimeout);
      }
    }, [retryCount, src]);

    useEffect(() => {
      setImageSrc(src);
    }, [src]);

    return (
      <StledLazyImage>
        {!loaded && <div className="loader" ><div className='spinning-loader'></div></div>}
        <img
          id={id}
          src={imageSrc}
          alt={alt}
          onLoad={handleImageLoaded}
          onError={handleImageError}
          style={{ display: loaded ? 'block' : 'none' }}
          className={customClassName || ''}
        />
      </StledLazyImage>
    );
  },
  areEqual
);

export default LazyImage;
