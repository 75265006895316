import styled from 'styled-components';

const StyledPageMain = styled.div`
  padding: 4rem 2rem;
  //only smartphone
  @media only screen and (max-width: 600px) {
    padding: 4rem 1rem;
  }
`;

export {
  StyledPageMain
}
