import React from 'react'
import ReactDOM from 'react-dom/client'
import App from './App.tsx'
import * as Sentry from "@sentry/react"

import './index.css'

//Call this to initialize firebase
import './fire.ts';

Sentry.init({
  dsn: "https://422c1f237316203325ede7e00e155ad2@o4507673159532544.ingest.de.sentry.io/4507673161498704",
  integrations: [],
});


ReactDOM.createRoot(document.getElementById('root')!).render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
)
