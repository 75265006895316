
// common

import flat_boxer from './flat/flat_boxer.svg';
import flat_dog from './flat/flat_dog.svg';
import flat_dog2 from './flat/flat_dog2.svg';
import flat_earth from './flat/flat_earth.svg';
import flat_twoman from './flat/flat_twoman.svg';
import flat_koala from './flat/flat_koala.svg';

import bluness_doctor from './bluness/doctor.svg';
import bluness_lion from './bluness/lion.svg';
import bluness_ski from './bluness/ski.svg';
import bluness_kiss from './bluness/kiss.svg';
import bluness_jump from './bluness/jump.svg';

import alegria_boxer from './alegria/alegria_boxer.svg';
import alegria_bus from './alegria/alegria_bus.svg';
import alegria_cat1 from './alegria/alegria_cat1.svg';
import alegria_twoman1 from './alegria/alegria_twoman1.svg';
import alegria_twoman2 from './alegria/alegria_twoman2.svg';

import abstract_book1 from './abstract/abstract_book1.svg';
import abstract_book2 from './abstract/abstract_book2.svg';
import abstract_earth from './abstract/abstract_earth.svg';
import abstract_twoman1 from './abstract/abstract_twoman1.svg';

import colouringbook_cat from './colouringbook/colouringbook_cat.svg';
import colouringbook_earth from './colouringbook/colouringbook_earth.svg';
import colouringbook_train from './colouringbook/colouringbook_train.svg';

import comic_book from './comic/comic_book.svg';
import comic_cat from './comic/comic_cat.svg';
import comic_earth from './comic/comic_earth.svg';
import comic_dog from './comic/dog.svg';
import comic_woman from './comic/woman.svg';

import filmnoir_book from './filmnoir/filmnoir_book.svg';
import filmnoir_cat1 from './filmnoir/filmnoir_cat1.svg';
import filmnoir_cat2 from './filmnoir/filmnoir_cat2.svg';
import filmnoir_twoman1 from './filmnoir/filmnoir_twoman1.svg';
import filmnoir_twoman2 from './filmnoir/filmnoir_twoman2.svg';

import futurist_book from './futurist/futurist_book.svg';
import futurist_cat from './futurist/futurist_cat.svg';
import futurist_earth from './futurist/futurist_earth.svg';
import futurist_twoman from './futurist/futurist_twoman.svg';

import doodle_book from './doodle/doodle_book.svg';
import doodle_cat1 from './doodle/doodle_cat1.svg';
import doodle_cat2 from './doodle/doodle_cat2.svg';
import doodle_earth1 from './doodle/doodle_earth1.svg';
import doodle_earth2 from './doodle/doodle_earth2.svg';
import doodle_twoman from './doodle/doodle_twoman.svg';

import isometric_book from './isometric/isometric_book.svg';
import isometric_book2 from './isometric/isometric_book2.svg';
import isometric_cat from './isometric/isometric_cat.svg';
import isometric_twoman from './isometric/isometric_twoman.svg';

import scribble_cat from './scribble/scribble_cat.svg';
import scribble_train from './scribble/scribble_train.svg';
import scribble_twoman from './scribble/scribble_twoman.svg';
import scribble_twoman2 from './scribble/scribble_twoman2.svg';

import minimalist_cat from './minimalist/minimalist_cat.svg';
import minimalist_cat2 from './minimalist/minimalist_cat2.svg';
import minimalist_train from './minimalist/minimalist_train.svg';
import minimalist_twoman from './minimalist/minimalist_twoman.svg';
import minimalist_twoman2 from './minimalist/minimalist_twoman.svg';

import psychedelic_book from './psychedelic/psychedelic_book.svg';
import psychedelic_cat from './psychedelic/psychedelic_cat.svg';
import psychedelic_twoman from './psychedelic/psychedelic_twoman.svg';
import psychedelic_man from './psychedelic/psychedelic_man.svg';

import childbook_book from './childbook/book.svg';
import childbook_castle1 from './childbook/castle1.svg';
import childbook_castle2 from './childbook/castle2.svg';

import realism_book from './realism/realism_book.svg';
import realism_woman from './realism/woman.svg';
import realism_earth from './realism/realism_earth.svg';
import realism_twoman from './realism/realism_twoman.svg';

import silhouette_cat from './silhouette/silhouette_cat.svg';
import silhouette_earth from './silhouette/silhouette_earth.svg';
import silhouette_earth2 from './silhouette/silhouette_earth2.svg';
import silhouette_twoman1 from './silhouette/silhouette_twoman1.svg';
import silhouette_twoman2 from './silhouette/silhouette_twoman2.svg';

import technical_cat from './technical/technical_cat.svg';
import technical_earth from './technical/technical_earth.svg';
import technical_train from './technical/technical_train.svg';
import technical_twoman from './technical/technical_twoman.svg';

// artist

import basquiat_book1 from './basquiat/basquiat_book1.svg';
import basquiat_book2 from './basquiat/basquiat_book2.svg';
import basquiat_cat1 from './basquiat/basquiat_cat1.svg';
import basquiat_cat2 from './basquiat/basquiat_cat2.svg';
import basquiat_cat3 from './basquiat/basquiat_cat3.svg';

import dali_book1 from './dali/dali_book1.svg';
import dali_book2 from './dali/dali_book2.svg';
import dali_earth from './dali/dali_earth.svg';
import dali_train from './dali/dali_train.svg';

import escher_book from './escher/escher_book.svg';
import escher_book2 from './escher/escher_book2.svg';
import escher_earth from './escher/escher_earth.svg';
import escher_train from './escher/escher_train.svg';

import murakami_book1 from './murakami/murakami_book1.svg';
import murakami_book2 from './murakami/murakami_book2.svg';
import murakami_cat1 from './murakami/murakami_cat1.svg';
import murakami_cat2 from './murakami/murakami_cat2.svg';

import picasso_book from './picasso/picasso_book.svg';
import picasso_cat from './picasso/picasso_cat.svg';
import picasso_cat2 from './picasso/picasso_cat2.svg';
import picasso_cat3 from './picasso/picasso_cat3.svg';
import picasso_earth from './picasso/picasso_earth.svg';

import popart_book from './popart/book.svg';
import popart_woman1 from './popart/woman1.svg';
import popart_woman2 from './popart/woman2.svg';

import schiele_book1 from './schiele/schiele_book1.svg';
import schiele_book2 from './schiele/schiele_book2.svg';
import schiele_cat1 from './schiele/schiele_cat1.svg';
import schiele_cat2 from './schiele/schiele_cat2.svg';

import vangogh_book1 from './vangogh/vangogh_book1.svg';
import vangogh_book2 from './vangogh/vangogh_book2.svg';
import vangogh_tree from './vangogh/tree.svg';
import vangogh_earth from './vangogh/vangogh_earth.svg';

import matisse_book1 from './matisse/matisse_book1.svg';
import matisse_book2 from './matisse/matisse_book2.svg';
import matisse_cat1 from './matisse/matisse_cat1.svg';
import matisse_cat2 from './matisse/matisse_cat2.svg';

import rand_book from './rand/rand_book.svg';
import rand_cat1 from './rand/rand_cat1.svg';
import rand_cat2 from './rand/rand_cat2.svg';
import rand_twoman1 from './rand/rand_twoman1.svg';
import rand_twoman2 from './rand/rand_twoman2.svg';

import ntntntnt_child from './ntntntnt/ntntntnt_child.svg';
import ntntntnt_drink from './ntntntnt/ntntntnt_drink.svg';
import ntntntnt_drink2 from './ntntntnt/ntntntnt_drink2.svg';
import ntntntnt_dog from './ntntntnt/ntntntnt_dog.svg';
import ntntntnt_old from './ntntntnt/ntntntnt_old.svg';

import logo_abstract_pear from './logo_abstract/pear.svg';
import logo_abstract_wolf from './logo_abstract/wolf.svg';
import logo_abstract_fly from './logo_abstract/fly.svg';
import logo_abstract_lion from './logo_abstract/lion.svg';
import logo_abstract_ship from './logo_abstract/ship.svg';

import logo_elegant_ship from './logo_elegant/ship.svg';
import logo_elegant_bunny from './logo_elegant/bunny.svg';
import logo_elegant_rockscissor from './logo_elegant/rockscissors.svg';

import logo_funny_ship from './logo_funny/ship.svg';
import logo_funny_ship2 from './logo_funny/ship2.svg';

import logo_funny_bunny from './logo_funny/bunny.svg';
import logo_funny_rockscissor from './logo_funny/rockscissor.svg';
import logo_funny_rockscissor2 from './logo_funny/rockscissor2.svg';

import logo_geometric_ship from './logo_geometric/ship.svg';
import logo_geometric_bunny from './logo_geometric/bunny.svg';
import logo_geometric_rockscissor from './logo_geometric/rockscissor.svg';

import icon_web_angry from './icon_web/angry.svg';
import icon_web_apple from './icon_web/apple.svg';
import icon_web_box from './icon_web/box.svg';
import icon_web_box2 from './icon_web/box2.svg';


import emoji_ship1 from './emoji/ship1.svg';
import emoji_ship2 from './emoji/ship2.svg';
import emoji_bunny from './emoji/bunny.svg';
import emoji_rockscissor from './emoji/rockscissor.svg';

import pixel_dog1 from './pixel/dog1.svg';
import pixel_dog2 from './pixel/dog2.svg';
import pixel_dog3 from './pixel/dog3.svg';

import mondrian_dog1 from './mondrian/dog1.svg';
import mondrian_dog2 from './mondrian/dog2.svg';

import lowpoly_dog1 from './lowpoly/dog1.svg';
import lowpoly_dog2 from './lowpoly/dog2.svg';
import lowpoly_woman from './lowpoly/woman.svg';
import lowpoly_lion from './lowpoly/lion.svg';


import tattoo_americana_apple1 from './tattoo_americana/apple1.svg';
import tattoo_americana_apple2 from './tattoo_americana/apple2.svg';
import tattoo_americana_shark from './tattoo_americana/shark.svg';

import tattoo_geometric_shark1 from './tattoo_geometric/shark1.svg';
import tattoo_geometric_shark2 from './tattoo_geometric/shark2.svg';
import tattoo_geometric_shark3 from './tattoo_geometric/shark3.svg';
import tattoo_geometric_apple1 from './tattoo_geometric/apple1.svg';
import tattoo_geometric_apple2 from './tattoo_geometric/apple2.svg';

import tattoo_japanese_koi from './tattoo_japanese/koi.svg';

import tattoo_line_apple from './tattoo_line/apple.svg';
import tattoo_line_koi1 from './tattoo_line/koi1.svg';
import tattoo_line_koi2 from './tattoo_line/koi2.svg';

import tattoo_oldschool_apple from './tattoo_oldschool/apple.svg';
import tattoo_oldschool_dog1 from './tattoo_oldschool/dog1.svg';
import tattoo_oldschool_dog2 from './tattoo_oldschool/dog2.svg';
import tattoo_oldschool_dog3 from './tattoo_oldschool/dog3.svg';
import tattoo_oldschool_eagle from './tattoo_oldschool/eagle.svg';
import tattoo_oldschool_shark from './tattoo_oldschool/shark.svg';

import tattoo_tribal_shark from './tattoo_tribal/shark.svg';
import tattoo_tribal_mandala1 from './tattoo_tribal/mandala1.svg';
import tattoo_tribal_mandala2 from './tattoo_tribal/mandala2.svg';

type IStyleImages = {
  [key: string]: string
}

export const EXAMPLE_IMAGES: IStyleImages = {
  flat_boxer,
  flat_dog,
  flat_dog2,
  flat_earth,
  flat_twoman,
  flat_koala,

  alegria_boxer,
  alegria_bus,
  alegria_cat1,
  alegria_twoman1,
  alegria_twoman2,

  bluness_kiss,
  bluness_jump,
  bluness_doctor,
  bluness_lion,
  bluness_ski,

  abstract_book1,
  abstract_book2,
  abstract_earth,
  abstract_twoman1,

  colouringbook_cat,
  colouringbook_earth,
  colouringbook_train,

  comic_book,
  comic_cat,
  comic_earth,
  comic_dog,
  comic_woman,

  filmnoir_book,
  filmnoir_cat1,
  filmnoir_cat2,
  filmnoir_twoman1,
  filmnoir_twoman2,

  futurist_book,
  futurist_cat,
  futurist_earth,
  futurist_twoman,

  doodle_book,
  doodle_cat1,
  doodle_cat2,
  doodle_earth1,
  doodle_earth2,
  doodle_twoman,

  isometric_book,
  isometric_book2,
  isometric_cat,
  isometric_twoman,

  scribble_cat,
  scribble_train,
  scribble_twoman,
  scribble_twoman2,

  minimalist_cat,
  minimalist_cat2,
  minimalist_train,
  minimalist_twoman,
  minimalist_twoman2,

  psychedelic_book,
  psychedelic_cat,
  psychedelic_twoman,
  psychedelic_man,

  childbook_book,
  childbook_castle1,
  childbook_castle2,

  realism_book,
  realism_woman,
  realism_earth,
  realism_twoman,

  silhouette_cat,
  silhouette_earth,
  silhouette_twoman1,
  silhouette_twoman2,
  silhouette_earth2,

  technical_cat,
  technical_earth,
  technical_train,
  technical_twoman,

  basquiat_book1,
  basquiat_book2,
  basquiat_cat1,
  basquiat_cat2,
  basquiat_cat3,

  dali_book1,
  dali_book2,
  dali_earth,
  dali_train,

  escher_book,
  escher_book2,
  escher_earth,
  escher_train,

  murakami_book1,
  murakami_book2,
  murakami_cat1,
  murakami_cat2,

  picasso_book,
  picasso_cat,
  picasso_cat2,
  picasso_cat3,
  picasso_earth,

  popart_book,
  popart_woman1,
  popart_woman2,

  schiele_book1,
  schiele_book2,
  schiele_cat1,
  schiele_cat2,

  vangogh_book1,
  vangogh_book2,
  vangogh_tree,
  vangogh_earth,

  matisse_book1,
  matisse_book2,
  matisse_cat1,
  matisse_cat2,

  rand_book,
  rand_cat1,
  rand_cat2,
  rand_twoman1,
  rand_twoman2,

  ntntntnt_child,
  ntntntnt_drink,
  ntntntnt_drink2,
  ntntntnt_dog,
  ntntntnt_old,

  logo_abstract_pear,
  logo_abstract_fly,
  logo_abstract_wolf,
  logo_abstract_lion,
  logo_abstract_ship,

  logo_elegant_ship,
  logo_elegant_bunny,
  logo_elegant_rockscissor,

  logo_funny_ship,
  logo_funny_ship2,
  logo_funny_bunny,
  logo_funny_rockscissor,
  logo_funny_rockscissor2,

  logo_geometric_ship,
  logo_geometric_bunny,
  logo_geometric_rockscissor,

  icon_web_angry,
  icon_web_apple,
  icon_web_box,
  icon_web_box2,

  emoji_ship1,
  emoji_ship2,
  emoji_bunny,
  emoji_rockscissor,

  pixel_dog1,
  pixel_dog2,
  pixel_dog3,

  lowpoly_dog1,
  lowpoly_dog2,
  lowpoly_woman,
  lowpoly_lion,

  mondrian_dog1,
  mondrian_dog2,


  tattoo_americana_apple1,
  tattoo_americana_apple2,
  tattoo_americana_shark,

  tattoo_geometric_apple1,
  tattoo_geometric_apple2,
  tattoo_geometric_shark1,
  tattoo_geometric_shark2,
  tattoo_geometric_shark3,

  tattoo_japanese_koi,

  tattoo_line_apple,
  tattoo_line_koi1,
  tattoo_line_koi2,

  tattoo_oldschool_apple,
  tattoo_oldschool_shark,
  tattoo_oldschool_dog1,
  tattoo_oldschool_dog2,
  tattoo_oldschool_dog3,
  tattoo_oldschool_eagle,

  tattoo_tribal_mandala1,
  tattoo_tribal_mandala2,
  tattoo_tribal_shark

}