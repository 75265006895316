import { useDispatch } from 'react-redux';
import { setErrorAction } from '../../store/actions/ui';
import { Modal } from '../modal/Modal';
import { Spacer } from '../Spacer';

import { StyledErrorModal } from './error-modal-style';

interface IErrorModalProps {
  error: string
}

export const ErrorModal = ({ error }: IErrorModalProps) => {

  const dispatch = useDispatch();

  const handleClose = () => {
    dispatch(setErrorAction(""));
  };

  return (
    <Modal show={!!error} onClose={handleClose}>
      <StyledErrorModal>
        <p>Ops, something went wrong:</p>
        <h1><b>{error}</b></h1>
        <Spacer />
        <p>If the issue persists, please contact us for assistance: <a href="mailto:info@illustroke.com">info@illustroke.com</a>.</p>
      </StyledErrorModal>
    </Modal>
  );
};