import { User } from "firebase/auth";
import { IIllustrokeUser, IUserRequest } from "../../types";

function setUserAction(user: User | null) {
  return {
    type: 'SET_USER_FIREBASE',
    payload: user
  };
}

function setUserInfoAction(userInfo: IIllustrokeUser | null) {
  return {
    type: 'SET_USER_INFO',
    payload: userInfo
  };
}

function setUserTokenAction(token: string) {
  return {
    type: 'SET_USER_TOKEN',
    payload: token
  };
}

function addUserRequestAction(value: IUserRequest) {
  return {
    type: 'ADD_USER_REQUEST',
    payload: value
  };
}

function setUserRequestsInitializedAction(value: boolean) {
  return {
    type: 'SET_USER_REQUESTS_INITIALIZED',
    payload: value
  };
}

function editUserRequestAction(value: IUserRequest) {
  return {
    type: 'EDIT_USER_REQUEST',
    payload: value
  };
}

function deleteUserRequestAction(value: IUserRequest) {
  return {
    type: 'DELETE_USER_REQUEST',
    payload: value
  };
}

function deleteAllUserRequestAction() {
  return {
    type: 'DELETE_ALL_USER_REQUEST',
    payload: null
  };
}

function setUserPaletteAction(colors: string[]) {
  return {
    type: 'SET_USER_PALETTE',
    payload: colors
  };
}

function addColorToUserPaletteAction(value: string) {
  return {
    type: 'ADD_COLOR_TO_USER_PALETTE',
    payload: value
  };
}

function changeUserTokensByXAction(value: number) {
  return {
    type: 'CHANGE_USER_TOKENS_BY_X',
    payload: value
  };
}

export {
  setUserAction,
  setUserTokenAction,
  setUserInfoAction,
  addUserRequestAction,
  addColorToUserPaletteAction,
  setUserPaletteAction,
  editUserRequestAction,
  deleteUserRequestAction,
  changeUserTokensByXAction,
  deleteAllUserRequestAction,
  setUserRequestsInitializedAction
}