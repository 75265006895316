import { getAuth, onAuthStateChanged } from "firebase/auth";
import { doc, onSnapshot } from "firebase/firestore";
import { useEffect } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { getUserInfoApi } from "../firestoreApi";
import { db } from "../fire";
import { setUserAction, setUserInfoAction, setUserTokenAction } from "../store/actions/user";
import { RootState } from "../store/store";

type AuthManagerProps = {
  children: React.ReactElement
}

export const AuthManager = ({
  children
}: AuthManagerProps) => {

  const dispatch = useDispatch();
  const auth = getAuth();
  const userInfo = useSelector((state: RootState) => state.user?.userInfo);

  const subscribeForAccountCreation = (uid: string) => {
    const unsub = onSnapshot(doc(db, "users", uid), (doc: any) => {
      const newUser = doc.data();
      if (newUser) {
        dispatch(setUserInfoAction(doc.data()));
        unsub(); //Unsubscribe to avoid too much read from firestore (if needed, remove it)
      }
    });
  }

  useEffect(() => {
    onAuthStateChanged(auth, (user) => {
      if (user) {
        user.getIdToken().then(function (data) {
          if (data) dispatch(setUserTokenAction(data));
        });
        if (!userInfo) {
          getUserInfoApi(user.uid).then(res => {
            if (res) {
              //userInfo already exist (normally on login)
              dispatch(setUserInfoAction(res));
            } else {
              //user not exist yet, normally on signup, so subscribe for it
              subscribeForAccountCreation(user.uid);
            }
          });
        }

        dispatch(setUserAction(user));
      } else {
        dispatch(setUserAction(null));
        dispatch(setUserInfoAction(null));
        dispatch(setUserTokenAction(""));
      }
    });
  }, []);

  useEffect(() => {
    const unsubscribe = auth.onIdTokenChanged(async (user) => {
      console.log('onIdTokenChanged!');
      if (user) {
        user.getIdToken().then(function (data) {
          if (data) dispatch(setUserTokenAction(data));
        });
      }
    });
    return unsubscribe;
  }, []);

  return (
    <>
      {children}
    </>
  )
}