import styled from 'styled-components';
import { COLORS } from '../../colors';

const StyledPricing = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  .pageMain {

    display: flex;
    flex-direction: column;
    gap: 3rem;

    .bundles {
      display: flex;
      flex-direction: row;
  
      //only smartphone
      @media only screen and (max-width: 600px) {
        flex-direction: column;
      }
  
      gap: 2rem;
  
      .bundle {
        background-color: ${COLORS.headerGray};
        padding: 1rem;
        border-radius: 25px;
        flex: 1; 
        max-width: 350px;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;

        .affiliateDiscountActive {
          font-size: 14px;
          color: ${COLORS.success};
          font-weight: bold;
          margin-top: 1rem;
        }
  
        h1 {
          padding-bottom: 0.5rem;
        }
  
        p {
          margin-bottom: 1rem;
          b {
            font-size: 20px;
            text-decoration: underline;
          }
        }
  
        button {
          s {
            padding: 0 5px;
            color: ${COLORS.error};
          }
        }
  
        button#free {
          background-color: ${COLORS.success};
        }
        button#free:disabled {
          background-color: ${COLORS.darkGray};
        }
      }
    }
  
    .promo {
      h2 {
        padding-bottom: 1rem;
      }
      input {
        margin-top: 1rem;
        margin-bottom: 1rem;
      }
      button {
        margin: auto;
      }
    }
  
    .howitworks {
      .howitworks_1 {
        font-size: 18px;
      }
      h1 {
        padding-bottom: 1rem;
      }
    }
  
    .license {
      h1 {
        padding-bottom: 1rem;
      }
      p {
        margin-bottom: 0.5rem;
      }
    }
  }
`

export {
  StyledPricing
}