import styled from 'styled-components'

const StyledModal = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: #050202c9;
  text-align: center;
  padding: 2rem;

  //only smartphone
  @media only screen and (max-width: 600px) {
    padding: 1rem;
  }
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;

  > div {
    max-width: 800px;
    max-height: 90vh;
    overflow-y: auto;
  }

  .closeButton {
    position: absolute;
    right: 50px;
    top: 50px;
    width: 35px;
    background-color: transparent;
    border: none;
    cursor: pointer;
    img {
      width: 100%;
      background-color: transparent;
    }
  }
`;
const StyledModalOverlay = styled.div``;

export {
  StyledModal,
  StyledModalOverlay
}