import { Link, useNavigate } from "react-router-dom";
import { useState } from "react";
import { StyledHome } from "./home-style";
import { Button } from "../../components/button/Button";

import img1 from '../../images/home/img1.svg';
import img2 from '../../images/home/img2.svg';
import whyvector from '../../images/whyvectors.png';

import example1 from '../../images/home/dog.svg';
import example2 from '../../images/home/book.svg';
import example3 from '../../images/home/cat.svg';

import allthingsaiImg from '../../images/feat/allthingsai.png';
import designertipsImg from '../../images/feat/designertips.png';
import futuretoolsImg from '../../images/feat/futuretools.png';
import mediumImg from '../../images/feat/medium.png';
import producthuntImg from '../../images/feat/producthunt.png';
import dailybailyImg from '../../images/feat/dailybaily.png';
import loveaitoolsImg from '../../images/feat/loveaitools.png';
import aitoolsupdateImg from '../../images/feat/aitoolsupdate.png';

import { PromptTipsModal } from "../../components/prompts-tips-modal/PromptsTipsModal";
import { logEvent } from "firebase/analytics";
import { analytics } from "../../fire";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle, faCircleXmark } from "@fortawesome/free-solid-svg-icons";
import { COLORS } from "../../colors";

export const Home = () => {
  const navigate = useNavigate();
  const [prompt, setPrompt] = useState<string>("");
  const [showPromptTipsModal, setShowPromptTipsModal] = useState<boolean>(false);

  const handleClickSubmit = () => {
    if (prompt.length > 0) {
      logEvent(analytics, 'select_prompt', { prompt });
      navigate('/generate', {
        state: {
          prompt
        }
      });
    }
  };


  return (
    <>
      <HelmetProvider>
        <Helmet>
          <title>Illustroke | Vector illustrations from text prompts AI</title>
          <link rel="canonical" href={window.location.origin} />
        </Helmet>
      </HelmetProvider>
      <StyledHome>
        <section id="main">
          <img id="img2" src={img2} alt="example of illustroke illustration " />
          <img id="img1" src={img1} alt="example of illustroke illustration" />

          <div>

            <div className="titles">
              <div className="counter">
                More than 1M illustration created!
              </div>
              <h1>Stunning vector illustrations from text prompts</h1>
              <p>Create <b>website illustrations</b>, <b>logos</b> and <b>icons</b> in seconds thanks to our advanced generative AI design tool.</p>
            </div>

            <div className="inputs">
              <textarea value={prompt} onChange={(e) => setPrompt(e.target.value)} placeholder="A smiling face of an old man" />
              <div className="tips">
                <button onClick={() => setShowPromptTipsModal(true)}>Prompt tips</button>
              </div>
              <Button onClick={handleClickSubmit}>Let's start!</Button>
            </div>

          </div>
        </section>

        <section id="whyvector">
          <img className="whyVectorImg" src={whyvector} alt="why vector" />
          <div className="explanation">
            <h1>Why Vector?</h1>
            <p>Illustroke, unlike many other AI image generators out there, <b>generates images in vector format</b> (SVG). Using the vector format brings many advantages during the development of your projects, here are some of them: </p>
          </div>

          <table>
            <thead>
              <tr>
                <th>

                </th>
                <th>
                  VECTOR (SVG)
                </th>
                <th>
                  PIXELS (PNG)
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  Scale image withous loss
                </td>
                <td>
                  <FontAwesomeIcon icon={faCheckCircle} color={COLORS.mainBlue}  />
                </td>
                <td>
                  <FontAwesomeIcon icon={faCircleXmark} color={COLORS.error}  />
                </td>
              </tr>
              <tr>
                <td>
                  Change colors and shapes
                </td>
                <td>
                  <FontAwesomeIcon icon={faCheckCircle} color={COLORS.mainBlue}  />
                </td>
                <td>
                  <FontAwesomeIcon icon={faCircleXmark} color={COLORS.error}  />
                </td>
              </tr>
              <tr>
                <td>
                  Print in high quality
                </td>
                <td>
                  <FontAwesomeIcon icon={faCheckCircle} color={COLORS.mainBlue}  />
                </td>
                <td>
                  <FontAwesomeIcon icon={faCircleXmark} color={COLORS.error}  />
                </td>
              </tr>
              <tr>
                <td>
                  Apply animation
                </td>
                <td>
                  <FontAwesomeIcon icon={faCheckCircle} color={COLORS.mainBlue}  />
                </td>
                <td>
                  <FontAwesomeIcon icon={faCircleXmark} color={COLORS.error}  />
                </td>
              </tr>
              <tr>
                <td>
                  Average file size
                </td>
                <td>
                  <b style={{ color: 'green' }}>50kB</b>
                </td>
                <td>
                  <b style={{ color: 'red' }}>500kB</b>
                </td>
              </tr>
            </tbody>
          </table>
        </section>

        <section id="example">
          <div className="titles">
            <h1>Vectorize your imagination!</h1>
            <p><b>Illustroke</b> is a <b>generative AI design tool</b> that allows you to create beautiful <b>vector illustrations</b>. You write what you want to illustrate and Illustroke will do the rest.</p>
          </div>

          <div className="examples">

            <img className="whyVectorImg" src={example1} alt="why vector" />

            <img className="whyVectorImg" src={example2} alt="why vector" />

            <img className="whyVectorImg" src={example3} alt="why vector" />

          </div>

        </section>

        <section id="alltools">
          <div className="titles">
            <h1>Discover all the other AI tools</h1>
            <p>Illustroke is part of a suite of tools that allows you solve all your design needs. Discover all the tools available to you.</p>
          </div>
          <div className="cta">
            <Link to="/convert">IMAGE TO VECTOR CONVERTER</Link>
            <Link to="/pixel-illustrations">PIXEL ILLUSTRATIONS GENERATOR</Link>
          </div>
        </section>

        <section id="howitworks">

          <div className="titles">
            <h1>It's simple</h1>
            <p>Just follow these four steps:</p>
          </div>

          <div className="howitworks-container">
            <div className="left">
              <div className="number">
                <span>1</span>
              </div>
              <h2>Type what do you want to illustrate.</h2>
              <p>From text to vector illustrations thanks to AI in seconds. The possibilities to generate vectorized illustrations with AI are endless.</p>
            </div>
            <div className="rigth">
              <div className="number">
                <span>2</span>
              </div>
              <h2>Select your preferred style.</h2>
              <p>You can choose from more than <b>40 styles</b>. From the flat style to the isometric one. Each style of this AI design tool is unique and <b>consistent</b>.</p>
            </div>
            <div className="left">
              <div className="number">
                <span>3</span>
              </div>
              <h2>You got three cool illustrations!</h2>
              <p>Illustrations are in vector format (SVG). <b>Scalable and customizable.</b> But you can also download the pixelated version (PNG).</p>
            </div>
            <div className="rigth">
              <div className="number">
                <span>4</span>
              </div>
              <h2>Modify as you like and download.</h2>
              <p>Vector images can be edited at will thanks to our editor. Save to the cloud and reopen your work whenever you want. <b>What are you waiting for to try this vector image illustrator?</b></p>
            </div>
          </div>
        </section>

        <section id="seenin">
          <div className="titles">
            <h1>Loved by</h1>
          </div>
          <div className="images">
            <a target="_blank" rel="noopener noreferrer" href="https://medium.com/illumination/the-must-have-tool-for-writers-to-create-killer-vector-images-e62509bfef97"><img src={mediumImg} alt="medium logo" /></a>
            <a target="_blank" rel="noopener noreferrer" href="https://www.designer.tips/tools/illustroke"><img src={designertipsImg} alt="designer.tips logo" /></a>
            <a target="_blank" rel="noopener noreferrer" href="https://allthingsai.com/tool/illustroke"><img src={allthingsaiImg} alt="allthigs ai logo" /></a>
          </div>

          <div className="images">
            <a target="_blank" rel="noopener noreferrer" href="https://www.futuretools.io/tools/illustroke"><img src={futuretoolsImg} alt="futuretools logo" /></a>
            <a target="_blank" rel="noopener noreferrer" href="https://www.producthunt.com/products/illustroke"><img src={producthuntImg} alt="producthunt logo" /></a>
            <a target="_blank" rel="noopener noreferrer" href="https://dailybaileyai.com/software/illustroke.php"><img src={dailybailyImg} alt="dailybaileyai logo" /></a>
          </div>

          <div className="images">
            <a target="_blank" rel="noopener noreferrer" href="https://loveaitools.com/aitool/illustroke/"><img src={loveaitoolsImg} alt="loveaitools logo" /></a>
            <a target="_blank" rel="noopener noreferrer" href="https://aitoolsupdate.com/product/illustroke"><img src={aitoolsupdateImg} alt="aitoolsupdate logo" /></a>
          </div>
        </section>

        <PromptTipsModal show={showPromptTipsModal} handleClose={() => setShowPromptTipsModal(false)} />
      </StyledHome>
    </>
  )
};