import React from 'react';
import { Navigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootState } from '../store/store';

type PrivateRouteProps = {
  children: React.ReactElement
}

export const PrivateRoute = ({ children }: PrivateRouteProps) => {
  const userFirebase = useSelector((state: RootState) => state.user.userFirebase);
  return userFirebase ? children : <Navigate to="/" />;
};