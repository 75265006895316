import { StyledNotFound } from './notfound-style';
import { Button } from '../../components/button/Button';
import { useNavigate } from 'react-router-dom';
import { Spacer } from '../../components/Spacer';

const NotFound = () => {
  const navigate = useNavigate();
  return (
    <StyledNotFound>
      <h1>Ops, page not found</h1>
      <p>The requested page does not exist.</p>
      <Spacer />
      <Button onClick={() => navigate('/')}>Back to Home</Button>
    </StyledNotFound>
  );
};

export default NotFound;
