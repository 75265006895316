import { QuestionIcon } from "../../../../../../components/question-icon/QuestionIcon";
import { StyledRadioButtonSettings, StyledSettingsContainer, StyledSettingsInner } from "../../generate-colormode-style";
import { StyledColorLimitSettings } from "./color-limit-settings-style";

interface IColorLimitSettingsProps {
  colorlimit?: number,
  setColorLimit: (s: number) => void,
  selected: boolean,
  onClickSelected: () => void
}

export const ColorLimitSettings = ({
  colorlimit,
  setColorLimit,
  selected,
  onClickSelected
}: IColorLimitSettingsProps) => {
  return (
    <StyledSettingsContainer>
      <StyledRadioButtonSettings selected={selected} onClick={onClickSelected} />
      <StyledSettingsInner selected={selected}>
        <div>
          <label htmlFor="colorlimit">Color Limit</label>
          <QuestionIcon explanation="Limit the number of colors that appear in the illustration. Use this setting to create visually simpler illustrations. Must be a number between 2 and 9." />
        </div>
        <StyledColorLimitSettings>
          {[2, 3, 4, 5, 6, 7, 8, 9].map((item) => (
            <button key={item.toString()} disabled={!selected} onClick={() => setColorLimit(item)} className={colorlimit === item ? 'selected' : ''}>
              {item}
            </button>
          ))}
        </StyledColorLimitSettings>
      </StyledSettingsInner>
    </StyledSettingsContainer>
  )
};