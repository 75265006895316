import { Button } from "../../../../components/button/Button";
import { ColorMode } from "../../../../types";
import { StyledGenerateColormode } from "./generate-colormode-style";
import bwImage from '../../../../images/bw.png';
import colorImage from '../../../../images/color.png';
import { IAdvancedSetting, IGenerateStep } from "../../Generate";
import { logEvent } from "firebase/analytics";
import { analytics } from "../../../../fire";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { setErrorAction } from "../../../../store/actions/ui";
import { ColorLimitSettings } from "./components/color-limit-settings/ColorLimitSettings";
import { ColorPaletteSettings } from "./components/color-palette-settings/ColorPaletteSettings";
import { NoAdvancedSettingsApplied } from "./components/no-advanced-settings-applied/NoAdvancedSettingsApplied";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import { COLORS } from "../../../../colors";
import { IStyle } from "../../../../onlyFeTypes";

export const isColorLimitValid = (colorlimit?: number) => {
  return (!!colorlimit) && (colorlimit > 1) && (colorlimit < 10)
}

interface IGenerateColormodeProps {
  colormode: ColorMode,
  style?: IStyle,
  setColormode: (c: ColorMode) => void,
  setStep: (s: IGenerateStep) => void,
  colorlimit?: number,
  setColorLimit: (l: number | undefined) => void,
  colorpalette?: string,
  setColorPalette: (palette: string) => void,
  selectedAdvancedSettings: IAdvancedSetting,
  setSelectedAdvancedSettings: (a: IAdvancedSetting) => void,
  showAdvancedSettings: boolean,
  setShowAdvancedSettings: (b: boolean) => void
}

export const GenerateColormode = ({
  colormode,
  setColormode,
  setStep,
  style,
  colorlimit,
  setColorLimit,
  colorpalette,
  setColorPalette,
  selectedAdvancedSettings,
  setSelectedAdvancedSettings,
  showAdvancedSettings,
  setShowAdvancedSettings
}: IGenerateColormodeProps) => {
  const dispatch = useDispatch();
  const handleClickContinue = () => {
    if (!!colorlimit && !isColorLimitValid(colorlimit)) {
      dispatch(setErrorAction("Color limit must be a number between 2 and 9"));
      return
    }
    setStep('objectmode');
    logEvent(analytics, 'select_colormode', { colormode });
  };

  const handleToggleShowAdvancedSetting = () => {
    setShowAdvancedSettings(!showAdvancedSettings);
  };

  useEffect(() => {
    // Reset some parameters when click on black and white
    if (colormode === "bw") {
      setShowAdvancedSettings(false);
      setSelectedAdvancedSettings("none");
    }
  }, [colormode]);

  useEffect(() => {
    if (style && style.isBnW) {
      setColormode('bw');
    } else {
      setColormode('color');
    }
  }, []);

  const handleClickNoSettings = () => {
    setSelectedAdvancedSettings("none");
  };

  return (
    <StyledGenerateColormode>
      <div className="colormodeSelector">
        {!!style && !style.isBnW && (
          <button className={colormode === 'color' ? "selected" : ""} onClick={() => setColormode('color')}>
            <img src={colorImage} alt="colored" />
            {(colormode === 'color') && <FontAwesomeIcon icon={faCheckCircle} color={COLORS.mainBlue} className="checkImg" />}
          </button>
        )}
        <button className={colormode === 'color' ? "" : "selected"} onClick={() => setColormode('bw')}>
          <img src={bwImage} alt="black and white" />
          {(colormode === 'bw') && <FontAwesomeIcon icon={faCheckCircle} color={COLORS.mainBlue} className="checkImg" />}
        </button>
      </div>

      <div className="guides">
        {(colormode === "color") && <p>Create a <b>full colored</b> illustration.</p>}
        {(colormode === "bw") && !!style && !style.isBnW && (<p>Create a <b>black and white</b> illustration.</p>)}
        {!!style && !!style.isBnW && (colormode === "bw") && (
          <p>This style is <b>black and white</b> only</p>
        )}
      </div>

      {(colormode === "color") && (
        <div className="showAdvancedSettings">
          <button onClick={handleToggleShowAdvancedSetting}>
            {showAdvancedSettings ? 'Close' : 'Show'} advanced setting
          </button>
        </div>
      )}

      {showAdvancedSettings && (colormode === "color") && (
        <div className="advancedSettings">
          <NoAdvancedSettingsApplied
            onClickSelected={handleClickNoSettings}
            selected={(selectedAdvancedSettings === "none")}
          />
          <ColorLimitSettings
            onClickSelected={() => setSelectedAdvancedSettings("colorlimit")}
            selected={(selectedAdvancedSettings === "colorlimit")}
            colorlimit={colorlimit}
            setColorLimit={setColorLimit}
          />
          <ColorPaletteSettings
            onClickSelected={() => setSelectedAdvancedSettings("colorpalette")}
            selected={(selectedAdvancedSettings === "colorpalette")}
            colorpalette={colorpalette}
            setColorPalette={setColorPalette}
          />
        </div>
      )}

      <div className="buttons">
        <Button onClick={handleClickContinue}>Continue</Button>
        <Button variant="secondary" onClick={() => setStep("style")}>Go Back</Button>
      </div>
    </StyledGenerateColormode>
  )
};