import styled from 'styled-components';
import { COLORS } from '../../colors';

const StyledImageToVector = styled.div`
  
  
  section {
    padding: 2rem;

    h2 {
      color: ${COLORS.mainBlue};
    }
  }
  
  section.main {
    height: 90vh;

    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;

    .titles {
      padding: 1rem;
      max-width: 600px;
      h1 {
        font-size: 48px;
        color: ${COLORS.mainBlue};
      }

      .bees {
        position: absolute;
        top: 8rem;
        left: calc(50% - 200px);
        display: flex;
        justify-content: center;
        align-items: center;
        img {
          width: 200px;
        }
      }
    }
    
    div.buttons {
      display: flex;
      justify-content: center;
    }
  
    div.upload {
      .dropzone {
        border: 2px dashed ${COLORS.darkGray};
        color: ${COLORS.darkGray};
        border-radius: 4px;
        padding: 4rem 8rem;
        width: 100%;
        box-sizing: border-box;
        text-align: center;
        cursor: pointer;
      }
  
  
      .files {
        padding: 2rem;
        span {
          font-size: small;
        }
      }
  
    }
  
    div.confirm {
      .info {
        padding: 2rem;
        text-align: left;
        span {
          font-weight: bold;
        }
      }
    }
  
    div.result {
  
      div.svgContainer {
        position: relative;
    
        svg {
          max-width: 400px;
          height: auto;
        }
    
        //only smartphone
        @media only screen and (max-width: 600px) {
          width: 90%;
          margin: auto;
          margin-bottom: 1rem;
          svg {
            width: 100%;
            height: 100%;
          }
        }
    
        
    
        .zoomButton {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          background-color: transparent;
          border: none;
          opacity: 0.7;
          display: none;
          padding: 2rem;
          img {
            width: 40px;
          }
        }
      }
  
      div.svgContainer:hover {
        .topButtons {
          display: flex;
        }
        .zoomButton {
          display: block;
        }
      }
  
      .buttons {
        display: flex;
        justify-content: center;
        gap: 1rem;
        margin-top: 1rem;
      }
  
      
    }
  }

  section.info {
    max-width: 800px;
    margin: auto;
  }

  padding-bottom: 4rem;

`

export {
  StyledImageToVector
}