

import { StyledGenerateStyle } from "./generate-style-style";
import { IStyleId, IStyleReplicateId } from '../../../../types';
import { CATEGORY_LIST } from '../../../../constants/styles';
import { useState } from 'react';
import { STYLE_IMAGES } from "../../../../images/styles";
import { ExampleModal } from "../ExampleModal/ExampleModal";
import LazyImage from "../../../../components/LazyImage";
import { ReplicateConfirmModal } from "../ReplicateConfirmModal/ReplicateConfirmModal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faIcicles } from "@fortawesome/free-solid-svg-icons";
import { Button } from "../../../../components/button/Button";
import { IStyle } from "../../../../onlyFeTypes";

interface IGenerateStyleProps {
  selectedStyleId: IStyleId | IStyleReplicateId | null
  handleSelectStyle: (s: IStyle) => void,
  selectedCategory: string,
  setSelectedCategory: (c: string) => void
}

const GenerateStyle = ({
  selectedStyleId,
  handleSelectStyle,
  selectedCategory,
  setSelectedCategory
}: IGenerateStyleProps) => {

  const [styleExampleSelected, setStyleExampleSelected] = useState<IStyle | null>(null);
  const [showReplicateConfirmModal, setShowReplicateConfirmModal] = useState<boolean>(false);
  const [replicateStyleSelected, setReplicateStyleSelected] = useState<IStyle>();

  const handleClickSeeExample = (event: any, style: IStyle) => {
    event.stopPropagation();
    setStyleExampleSelected(style);
  };

  const handleSelectStyleBefore = (style: IStyle) => {
    if (style.isRep) {
      setReplicateStyleSelected(style);
      setShowReplicateConfirmModal(true);
    } else {
      handleSelectStyle(style);
    }
  };

  const styles = CATEGORY_LIST.find(c => c.name === selectedCategory)?.styles || [];

  return (
    <StyledGenerateStyle>
      <div>
        <div className="categories">
          <div className="wrapperButtons">
            {CATEGORY_LIST.map(category => (
              <button key={category.name} onClick={() => setSelectedCategory(category.name)} className={(category.name === selectedCategory) ? "selected" : ""}>
                {category.name}
              </button>
            ))}
            <button onClick={() => setSelectedCategory("no-style-category")} className={(selectedCategory === "no-style-category") ? "selected" : ""}>
              Continue without a <br/> predefined style
            </button>
          </div>
        </div>
          

        {(styles.length > 0) && (
          <div className="styles">
            {styles.map((style: IStyle) => (
              <button onClick={() => handleSelectStyleBefore(style)} key={style.id} className={(selectedStyleId === style.id) ? 'selected' : ''}>
                <div className="overlay">
                  <p>{style.label}</p>
                  {style.examples && (
                    <div onClick={(e) => handleClickSeeExample(e, style)}>See examples</div>
                  )}
                </div>
                <LazyImage src={STYLE_IMAGES[style.id]} alt='style preview' />

                {style.isRep && (
                  <div className="coldBootIcon">
                    <FontAwesomeIcon icon={faIcicles} color="white" />
                  </div>
                )}
              </button>
            ))}
          </div>
        )}

        {(selectedCategory === "no-style-category") && (
          <div className="no-style">
            <p>No predefined style will be applied to your illustration. The result will depend only on the prompt you provide. Be sure to provide a detailed prompt to get the best results.</p>
            <Button onClick={() => handleSelectStyle({ id: "no-style", label: "No predefined style" })}>
              Continue
            </Button>
          </div>
        )}

        <div className="disclaimer">
          <p>These illustrations are just examples to help you choose the style. The real magic happens later.</p>
        </div>

      </div>

      {styleExampleSelected && <ExampleModal style={styleExampleSelected} show={!!styleExampleSelected} handleClose={() => setStyleExampleSelected(null)} />}
      <ReplicateConfirmModal
        show={showReplicateConfirmModal}
        handleClose={() => setShowReplicateConfirmModal(false)}
        handleClickConfirm={() => {
          if (replicateStyleSelected) handleSelectStyle(replicateStyleSelected)
        }}
      />
      {/* <SuggestModal show={showSuggestModal} handleClose={() => setShowSuggestModal(false)} /> */}

    </StyledGenerateStyle>
  )
};

export default GenerateStyle;