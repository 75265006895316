import { Fragment } from "react";
import { StyledStepper, StyledStepperMobile } from "./stepper-style";

interface StepperProps {
  currentStep: any,
  steps: any[],
  titles: string[],
  handleClickStep: (step: any) => void
}

export const Stepper = ({
  currentStep,
  steps,
  handleClickStep,
  titles
}: StepperProps) => {

  const currentStepToIndex = (step: any) => {
    return steps.indexOf(step);
  }

  const stepTitle = (step: any) => {
    return titles[steps.indexOf(step)];
  };

  return (
    <>
      <StyledStepper>
        {Array.from({ length: steps.length }, (_, index) => (
          <Fragment key={index}>
            {index > 0 && <div className={`line ${index <= currentStepToIndex(currentStep) ? 'passed' : ''}`} />}
            <div onClick={() => handleClickStep(steps[index])} role="button" className={`dot ${steps[index] === currentStep ? 'active' : ''} ${index <= currentStepToIndex(currentStep) ? 'passed' : ''}`}>
              <span>{stepTitle(steps[index])}</span>
            </div>
          </Fragment>
        ))}
      </StyledStepper>
      <StyledStepperMobile>
        <h3>{stepTitle(currentStep)}</h3>
      </StyledStepperMobile>
    </>
  );
};