import { Modal } from '../modal/Modal';

import { createUserWithEmailAndPassword, getAuth, GoogleAuthProvider, sendEmailVerification, sendPasswordResetEmail, signInWithEmailAndPassword, signInWithPopup } from "firebase/auth";
import { useDispatch, useSelector } from 'react-redux';
import { StyledLoginModal } from './login-modal-style';
import { useEffect, useState } from 'react';
import { setShowLoginModalAction } from '../../store/actions/ui';
import { Button } from '../button/Button';
import { TextInput } from '../text-input/TextInput';
import googleLogo from '../../images/google.png';
import { Spacer } from '../Spacer';
import { setErrorAction, setLoadingAction, setSuccessAction } from '../../store/actions/ui';
import { isValidEmail } from '../../utils/emails';
import { RootState } from '../../store/store';
import { Link } from 'react-router-dom';
import { logEvent } from 'firebase/analytics';
import { analytics } from '../../fire';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';

type IStep = "select" | "accountQuestion" | "login" | "signup" | "forget" | "verify";

export const LoginModal = () => {

  const dispatch = useDispatch();
  const auth = getAuth();
  const showLoginModal = useSelector((state: RootState) => state.ui.showLoginModal);
  const [step, setStep] = useState<IStep>("select");

  const handleClose = () => {
    dispatch(setShowLoginModalAction(false));
  }

  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");

  const [newEmail, setNewEmail] = useState<string>("");
  const [newPassword, setNewPassword] = useState<string>("");
  const [newRepeatPassword, setNewRepeatPassword] = useState<string>("");
  const [forgetEmail, setForgetEmail] = useState<string>("");

  const handeClickSigninWithGoole = () => {
    const provider = new GoogleAuthProvider();
    dispatch(setLoadingAction(true));
    signInWithPopup(auth, provider)
      .then(() => {
        logEvent(analytics, 'login', { method: "Google" });
        dispatch(setLoadingAction(false));
        handleClose();
      }).catch((error) => {
        dispatch(setLoadingAction(false));
        dispatch(setErrorAction(error.message));
      });
  }

  const handleClickCreateWithEmail = () => {
    if (newEmail) {
      if (isValidEmail(newEmail)) {
        if (newPassword === newRepeatPassword) {
          const auth = getAuth();
          dispatch(setLoadingAction(true));
          createUserWithEmailAndPassword(auth, newEmail, newPassword)
            .then(() => {
              if (auth?.currentUser) {
                logEvent(analytics, 'sign_up', { method: "Email" });
                sendEmailVerification(auth.currentUser)
                  .then(() => {
                    dispatch(setLoadingAction(false));
                    setStep('verify');
                  });
              }
            })
            .catch((error) => {
              dispatch(setLoadingAction(false));
              dispatch(setErrorAction(error.message));
            });
        } else {
          dispatch(setErrorAction("Passwords does not match. Retry"));
        }
      } else {
        dispatch(setLoadingAction(true));
        setTimeout(() => {
          dispatch(setLoadingAction(false));
          dispatch(setErrorAction("Email not valid. Retry"));
        }, 5000);
      }
    } else {
      dispatch(setErrorAction("Missing email"));
    }
  };

  useEffect(() => {
    if (!showLoginModal) {
      setStep("select");
    }
  }, [showLoginModal]);

  const handeClickSigninWithEmail = () => {
    dispatch(setLoadingAction(true));
    signInWithEmailAndPassword(auth, email, password)
      .then(() => {
        logEvent(analytics, 'login', { method: "Email" });
        dispatch(setLoadingAction(false));
        handleClose();
      })
      .catch((error) => {
        dispatch(setLoadingAction(false));
        dispatch(setErrorAction(error.message));
      });
  };

  const handleClickForget = () => {
    const auth = getAuth();
    dispatch(setLoadingAction(true));
    sendPasswordResetEmail(auth, email)
      .then(() => {
        dispatch(setLoadingAction(false));
        dispatch(setSuccessAction("We sent a password reset email. Check your email"));
        setStep("login");
      })
      .catch((error) => {
        dispatch(setLoadingAction(false));
        dispatch(setErrorAction(error.message));
      });

  };

  const handleClickVerified = () => {
    const auth = getAuth();
    auth.currentUser?.reload().then(() => {
      if (auth.currentUser?.emailVerified) {
        handleClose();
      } else {
        dispatch(setErrorAction(`Your email is not verified. We have sent a verification email to ${auth.currentUser?.email}. Before continuing click on the link and refresh the page. If you can't find the email, check your spam.`))
      }
    });
  };

  return (
    <Modal show={showLoginModal} onClose={handleClose}>
      <StyledLoginModal className='LoginModal'>

        {(step !== 'verify') && (
          <div className='titles'>
            <h1>Welcome!</h1>
            <p>You need an <b>account</b> to continue and <b>tokens</b> to generate the illustrations. Follow us and send a message through our social channels to get <b>free tokens</b>.</p>
          </div>
        )}

        {(step === 'select') && (
          <div className='select'>

            <Button onClick={handeClickSigninWithGoole}>
              <img src={googleLogo} alt='google logo' />
              <span>Continue with Google</span>
            </Button>

            <Spacer />

            <Button onClick={() => setStep('accountQuestion')}>
              <FontAwesomeIcon icon={faEnvelope} color='white' />
              <span data-testid="continue-with-email">Continue with Email</span>
            </Button>

            <Spacer />

            <p className='disclaimer'>By clicking the continue, I hereby agree to and accept the <Link onClick={handleClose} to="/terms">Terms and Conditions</Link> and <Link onClick={handleClose} to="/privacy">Privacy Policy</Link> </p>

          </div>
        )}

        {(step === 'accountQuestion') && (
          <div className='accountQuestion'>
            <p><b>Do you have an account?</b></p>
            <Spacer />

            <div>
              <Button onClick={() => setStep("login")}>
                <span data-testid="yes-i-have-an-account-button">Yes, I have already an account</span>
              </Button>

              <Spacer />

              <Button onClick={() => setStep("signup")}>
                <span>No, I want to create one</span>
              </Button>
            </div>
          </div>
        )}

        {(step === 'login') && (
          <div className='login'>
            <TextInput type='email' value={email} onChange={(e: any) => setEmail(e.target.value)} placeholder="your@email.com" />
            <TextInput type='password' value={password} onChange={(e: any) => setPassword(e.target.value)} placeholder="your password" />

            <button className='forget' onClick={() => setStep("forget")}>
              <span>Forgot your password?</span>
            </button>

            <div className='buttons'>
              <Button onClick={handeClickSigninWithEmail}>
                <span data-testid="login-button">Login</span>
              </Button>
              <Button variant='secondary' onClick={() => setStep("select")}>
                <span>Back</span>
              </Button>
            </div>
          </div>
        )}

        {(step === 'signup') && (
          <div className='signup'>
            <p><b>Let's create it</b></p>
            <Spacer />

            <div className='inputs'>
              <TextInput value={newEmail} onChange={(e: any) => setNewEmail(e.target.value)} placeholder="your@email.com" />
              <TextInput type='password' value={newPassword} onChange={(e: any) => setNewPassword(e.target.value)} placeholder="Password" />
              <TextInput type='password' value={newRepeatPassword} onChange={(e: any) => setNewRepeatPassword(e.target.value)} placeholder="Repeat Password" />
            </div>

            <div className='buttons'>
              <Button onClick={handleClickCreateWithEmail}>
                <span>Enter</span>
              </Button>
              <Button variant='secondary' onClick={() => setStep("select")}>
                <span>Back</span>
              </Button>
            </div>
          </div>
        )}

        {(step === 'forget') && (
          <div className='forget'>
            <p><b>Write your email</b></p>
            <Spacer />
            <TextInput value={forgetEmail} onChange={(e: any) => setForgetEmail(e.target.value)} placeholder="your@email.com" />
            <Spacer />
            <div className='buttons'>
              <Button onClick={handleClickForget}>
                <span>Send me an email</span>
              </Button>
              <Button variant='secondary' onClick={() => setStep("login")}>
                <span>Back</span>
              </Button>
            </div>
          </div>
        )}

        {(step === 'verify') && (
          <div className='verify'>
            <p>We have sent a verification email to <b>{newEmail}</b>. Before continuing click on the link. If you can't find the email, check your spam.</p>
            <Spacer />
            <div className='buttons'>
              <Button onClick={handleClickVerified}>
                <span>Yes, I have verified my account</span>
              </Button>
            </div>
          </div>
        )}

      </StyledLoginModal>
    </Modal>
  );
};