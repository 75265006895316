import { StageObjectData } from "./types/stage-object";
import { IUserDesign } from "./onlyFeTypes";
import { addDoc, collection, deleteDoc, doc, getDoc, getDocs, limit, orderBy, query, setDoc, updateDoc } from "firebase/firestore";
import { IIllustrokeUser, IUserRequest } from "./types";
import { db } from './fire';

const USERS_COLLECTION = "users";
const REQUEST_SUB_COLLECTION = "requests";
const DELETE_REQUEST_COLLECTION = "delete_requests";
const STYLE_SUGGESTIONS_COLLECTION = "style_suggestions";
const GENERIC_SUGGESTIONS_COLLECTION = "generic_suggestions";
export const DESIGNS_SUB_COLLECTION = "designs2";

export const getUserInfoApi = async (uid: string): Promise<IIllustrokeUser | null> => {
  if (uid) {
    const docRef = doc(db, USERS_COLLECTION, uid);
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
      return docSnap.data() as IIllustrokeUser;
    } else {
      return null
    }
  }
  return null
}

export const getUserRequestsApi = async (uid: string): Promise<IUserRequest[] | []> => {
  if (uid) {
    const requests: IUserRequest[] = [];
    const querySnapshot = await getDocs(collection(db, `users/${uid}/requests`));
    querySnapshot.forEach((doc) => {
      requests.push(doc.data() as IUserRequest)
    });
    return requests
  }
  return []
}

export const deleteUserRequestOnFirestoreAPi = async (uid: string | undefined, request: IUserRequest) => {
  if (uid && request) {
    const docRef = doc(db, USERS_COLLECTION, uid, REQUEST_SUB_COLLECTION, request.id);
    await deleteDoc(docRef);
    return true
  }
  return null
}

export const createDeleteUserRequestApi = async (token: string, uid: string, email: string) => {
  if (token && uid && email) {
    await setDoc(doc(db, DELETE_REQUEST_COLLECTION, uid), { email, created: new Date() });
  }
  return null
}

export const addStyleSuggestionApi = async (token: string, uid: string, suggestion: string) => {
  if (token) {
    await addDoc(collection(db, STYLE_SUGGESTIONS_COLLECTION), { suggestion, created: new Date(), uid });
  }
  return null
}

export const addGenericSuggestionApi = async (token: string, suggestion: string) => {
  if (token) {
    await addDoc(collection(db, GENERIC_SUGGESTIONS_COLLECTION), { suggestion, created: new Date() });
  }
  return null
}

export const getUserOrdersApi = async (uid: string): Promise<any[] | []> => {
  if (uid) {
    const orders: any[] = [];
    const querySnapshot = await getDocs(collection(db, `users/${uid}/orders`));
    querySnapshot.forEach((doc) => {
      orders.push(doc.data() as any)
    });
    return orders
  }
  return []
}

export const getAffiliateOrdersApi = async (uid: string): Promise<any[] | []> => {
  if (uid) {
    const orders: any[] = [];
    const querySnapshot = await getDocs(collection(db, `users/${uid}/affiliate_orders`));
    querySnapshot.forEach((doc) => {
      orders.push(doc.data() as any)
    });
    return orders
  }
  return []
}


export const createDesignApi = async (token?: string, uid?: string, newDesign?: IUserDesign) => {
  if (token && uid && newDesign) {
    await setDoc(doc(db, USERS_COLLECTION, uid, DESIGNS_SUB_COLLECTION, newDesign.id), newDesign);
  }
  return null
}

export const updateDesignImagesApi = async (token?: string, uid?: string, designId?: string, objs?: StageObjectData[]) => {
  if (token && uid && designId) {
    const docRef = doc(db, USERS_COLLECTION, uid, DESIGNS_SUB_COLLECTION, designId);
    await updateDoc(docRef, {
      stageObjects: objs,
      updated: new Date()
    });
  }
  return null
}

export const updateDesignNameApi = async (token?: string, uid?: string, designId?: string, newName?: string) => {
  if (token && uid && designId) {
    const docRef = doc(db, USERS_COLLECTION, uid, DESIGNS_SUB_COLLECTION, designId);
    await updateDoc(docRef, {
      name: newName,
      updated: new Date(),
    });
  }
  return null
}

export const getUserDesignsApi = async (uid: string): Promise<IUserDesign[] | []> => {
  if (uid) {
    const designs: IUserDesign[] = [];
    const querySnapshot = await getDocs(query(
      collection(db, `users/${uid}/${DESIGNS_SUB_COLLECTION}`),
      orderBy("updated", "desc"),
      limit(12)
    ));
    querySnapshot.forEach((doc) => {
      designs.push({ ...doc.data(), id: doc.id } as IUserDesign)
    });
    return designs
  }
  return []
}

export const deleteUserDesignApi = async (uid: string | undefined, design: IUserDesign) => {
  if (uid && design) {
    const docRef = doc(db, USERS_COLLECTION, uid, DESIGNS_SUB_COLLECTION, design.id);
    await deleteDoc(docRef);
    return true
  }
  return null
}