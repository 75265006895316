import { Modal } from '../modal/Modal';
import { Spacer } from '../Spacer';

import { StyledPromptTipsModal } from './prompts-tips-modal-style';

interface IPromptTipsModalProps {
  show: boolean,
  handleClose: () => void
}

export const PromptTipsModal = ({ show, handleClose }: IPromptTipsModalProps) => {


  return (
    <Modal show={show} onClose={handleClose}>
      <StyledPromptTipsModal>
        <h2>Do</h2>
        <p>✅ Write in english</p>
        <p>✅ Describe directly what do you want to illustrate: <br /><b><i>"A desert island"</i></b></p>
        <p>✅ Try the same prompt multiple times. It may take a few tries to get the perfect illustration.</p>

        <Spacer />
        <h2>Don't</h2>
        <p>☹️ Don't specify a style. You can choose it in the next step. <br /> <b><i style={{ textDecoration: 'line-through' }}>"A desert island. Vectorized. Flat design.   Logo design."</i></b></p>
        {/* <p>☹️ Don't specify colors. You can change colors later. <br /><b><i>"A yellow desert island"</i></b></p> */}

      </StyledPromptTipsModal>
    </Modal>
  );
};