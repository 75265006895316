import styled from 'styled-components';

const StyledGenerateObjectMode = styled.div`
  padding-top: 2rem;
  .objectmodeSelector {
    display: flex;
    flex-direction: row;
    gap: 2rem;
    justify-content: center;
    button {
      width: 125px;
      height: 125px;
      border: none;
      background-color: transparent;
      opacity: 0.25;
      position: relative;
      padding: 0;
      border-radius: 25px;
      box-shadow: rgba(0, 0, 0, 0.5) 0px 5px 15px;

      img {
        border-radius: 25px;
        width: 100%;
        height: 100%;
      }
      svg.checkImg {
        position: absolute;
        width: 35px;
        height: 35px;
        bottom: 5px;
        left: 5px;
        background-color: white;
        border-radius: 100%;
      }
    }
    button.selected {
      opacity: 1;
    }
  }
  .buttons {
    padding-top: 2rem;
    button {
      margin: auto;
    }
  }
  .guides {
    padding-top: 1rem;
  }
`

export {
  StyledGenerateObjectMode,
}