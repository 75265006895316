import { CATEGORY_LIST } from "../constants/styles";
import { IStyle } from "../onlyFeTypes";
import { IStyleId } from "../types";

export const getStyleLabelFromStyleId = (styleId: string) => {
  let label = "";
  CATEGORY_LIST.forEach(category => {
    const style = category.styles.find(s => s.id === styleId)
    if (style) label = style.label
  });
  return label
};

export const getStyleFromStyleId = (styleId: IStyleId) => {
  if(styleId === "no-style"){
    return {
      id: "no-style",
      label: "No Style",
    } as IStyle
  }
  let style;
  CATEGORY_LIST.forEach(category => {
    const s = category?.styles?.find(ss => ss.id === styleId);
    if (s) {
      style = s;
      return; // Exit the loop once a matching style is found
    }
  });
  return style as IStyle | undefined;
};