import { StyledPromptInput } from "./prompt-input-style";

interface IPromptInputProps {
  prompt: string,
  setPrompt: (p: string) => void
}

export const PromptInput = ({
  prompt,
  setPrompt
}: IPromptInputProps) => {
  return (
    <StyledPromptInput>
      <textarea name="prompt" id="prompt" value={prompt} onChange={(e) => setPrompt(e.target.value)} />
    </StyledPromptInput>
  );
};