import { ColorMode, IStyleId, IStyleReplicateId, ItemType, IUserRequest, ObjectMode } from "./types"


/*
  This file is used to define the types of the API requests and responses.
  It is shared between the frontend and the backend.
  When you change this file, you need to update the frontend and the backend.
*/


//-------------------------------------------  /generate

export enum GenerateErrorCodes {
  GENERIC_ERROR = "GENERIC_ERROR",
  MISSING_PARAMETERS = "MISSING_PARAMETERS",
  RUN_OUT_TOKENS = "RUN_OUT_TOKENS",
  INVALID_STYLE = "INVALID_STYLE",
  DALLE_EMPTY_RESPONSE = "DALLE_EMPTY_RESPONSE",
  DALLE_REQUEST_ERROR = "DALLE_REQUEST_ERROR",

  //only for canva
  CANVA_MISSING_USER = "CANVA_MISSING_USER",

  //only for replicate
  REPLICATE_CONF_ERROR = "REPLICATE_CONF_ERROR",
  REPLICATE_PREDICT_ERROR_1 = "REPLICATE_PREDICT_ERROR_1",
  REPLICATE_PREDICT_ERROR_2 = "REPLICATE_PREDICT_ERROR_2",
  REPLICATE_PREDICT_ERROR_3 = "REPLICATE_PREDICT_ERROR_3",
  REPLICATE_STATUS_ERROR = "REPLICATE_STATUS_ERROR",
}

export interface IGenerateRequestParam {
  style: IStyleId,
  prompt: string,
  colormode: ColorMode,
  objectmode: ObjectMode,
  colorlimit?: number,
  colorpalette?: string
}

export interface IGenerateResponse {
  success: boolean,
  error?: Error,
  customErrorCode?: GenerateErrorCodes,
  data?: IUserRequest
}


//-------------------------------------------  /ConvertToSvgOnly

export interface IConvertToSvgOnlyRequestParam {
  imageUrl: string,
  colormode: ColorMode,
  colorlimit?: number,
  colorpalette?: string
}

export interface IConvertToSvgOnlyResponse {
  success: boolean,
  error?: Error,
  customErrorCode?: GenerateErrorCodes,
  data?: { id: string }
}


// -------------------------------------------  /generateRep

export interface IGenerateRepRequestParam {
  style: IStyleReplicateId,
  prompt: string,
  colormode: ColorMode,
}

export interface IGenerateRepResponse {
  success: boolean,
  error?: Error,
  customErrorCode?: GenerateErrorCodes,
  data?: {
    status: IStatusRep,
    id: string
  }
}



// -------------------------------------------  /statusRep

export interface IStatusRepRequestParam {
  id: string, //the replicate request id
  colormode: ColorMode,
  style: IStyleReplicateId,
  prompt: string,
  colorlimit?: number,
  colorpalette?: string
}

export interface IStatusRepResponse {
  success: boolean,
  error?: Error,
  customErrorCode?: GenerateErrorCodes,
  data?: {
    status: IStatusRep,
    request?: IUserRequest
  }
}

export type IStatusRep = "succeeded" | "starting" | "processing" | "failed" | "canceled"; // https://replicate.com/docs/reference/http#get-prediction




// -------------------------------------------  /variate

export interface IVariateRequestParam extends IGenerateRequestParam {
  originRequestId: string,
  index: number,
  colorlimit?: number
}

export interface IVariateResponse {
  success: boolean,
  error?: Error,
  customErrorCode?: GenerateErrorCodes,
  data?: IUserRequest
}



// -------------------------------------------  /redeem

export interface IRedeemRequestParam {
  promoCode: string,
}

export interface IRedeemResponse {
  success: boolean,
  customErrorCode?: "PROMO_CODE_NOT_FOUND" | "MISSING_PARAMETERS" | "PROMO_CODE_ALREADY_REDEEMED",
  data?: {
    tokens: number
  }
}



// -------------------------------------------  /createPromo
export interface ICreatePromoRequestParam {
  quantity: number,
}

export interface ICreatePromoResponse {
  success: boolean,
  customErrorCode?: "USER_NOT_ADMIN" | "MISSING_PARAMETERS",
  data?: string
}



// -------------------------------------------  /createPaymentIntent

export interface ICreatePaymentIntentRequestParams {
  item: ItemType,
  affiliateKey?: string, //only for affiliate sales
}

export interface ICreatePaymentIntentResponse {
  success: boolean,
  error?: Error,
  customErrorCode?: "MISSING_PARAMETERS" | "INVALID_REQUEST" | "TOO_MANY_UNUSED_TOKENS" | "STRIPE_ERROR" | "FIREBASE_EMAIL_NOT_FOUND",
  data?: {
    clientSecret: string,
    amount: number,
    currency: string,
    isAmountDiscounted: boolean
  }
}




// -------------------------------------------  /joinAffiliate

export interface IJoinAffiliateResponse {
  success: boolean,
  customErrorCode?: "MISSING_PARAMETERS",
  data?: {
    affiliateKey: string
  }
}



// -------------------------------------------  /canvaLogin

export interface ICanvaLoginRequestParams {
  canva_user_token: string,
  nonceQuery: string,
  state: string
}


// -------------------------------------------  /externalApiGenerate

export interface IExernalApiGenerateParam {
  style: IStyleId,
  n: number,
  prompt: string,
  colormode: ColorMode,
  objectmode: ObjectMode,
  colorlimit?: number,
  colorpalette?: string
}


// -------------------------------------------  /canvaApiGenerate

export interface ICanvaApiGenerateParam {
  style: IStyleId,
  prompt: string,
  colormode: ColorMode,
  objectmode: ObjectMode,
  colorlimit?: number,
  colorpalette?: string
}


// -------------------------------------------  /generatePixel

export interface IGeneratePixelRequestParam {
  prompt: string
}