import { useDispatch } from 'react-redux';
import { setInfoAction } from '../../store/actions/ui';
import { Modal } from '../modal/Modal';

import { StyledInfoModal } from './info-modal-style';

interface IInfoModalProps {
  info: string
}

export const InfoModal = ({ info }: IInfoModalProps) => {

  const dispatch = useDispatch();

  const handleClose = () => {
    dispatch(setInfoAction(""));
  };

  return (
    <Modal show={!!info} onClose={handleClose}>
      <StyledInfoModal>
        <p><b>{info}</b></p>
      </StyledInfoModal>
    </Modal>
  );
};