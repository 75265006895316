
export function storeObjectInLocalStorage(key: string, object: any) {
  localStorage.setItem(key, JSON.stringify(object));
}

export function getObjectFromLocalStorage(key: string) {
  const object = localStorage.getItem(key);
  if (object) return JSON.parse(object);
  return null
}

export function deleteObjectFromLocalStorage(key: string) {
  localStorage.removeItem(key);
}

export function deleteAllObjectFromLocalStorageStartingWith(prefix: string) {
  const keys = getKeysStartingWith(prefix);
  keys.forEach(key => localStorage.removeItem(key));
}

export function getKeysStartingWith(prefix: string) {
  var keys = [];
  for (var i = 0; i < localStorage.length; i++) {
    var key = localStorage.key(i);
    if (key?.startsWith(prefix)) {
      keys.push(key);
    }
  }
  return keys;
}