function setLoadingAction(value: boolean) {
  return {
    type: 'SET_LOADING',
    payload: value
  };
}

function setGenerateLoadingAction(value: boolean) {
  return {
    type: 'SET_GENERATE_LOADING',
    payload: value
  };
}

function setLoadingMessageAction(value: string) {
  return {
    type: 'SET_LOADING_MESSAGE',
    payload: value
  };
}

function setErrorAction(value: string) {
  return {
    type: 'SET_ERROR',
    payload: value
  };
}

function setSuccessAction(value: string) {
  return {
    type: 'SET_SUCCESS',
    payload: value
  };
}

function setInfoAction(value: string) {
  return {
    type: 'SET_INFO',
    payload: value
  };
}


function setShowLoginModalAction(value: boolean) {
  return {
    type: 'SET_SHOW_LOGIN_MODAL',
    payload: value
  };
}

export { setLoadingAction, setLoadingMessageAction, setErrorAction, setSuccessAction, setInfoAction, setShowLoginModalAction, setGenerateLoadingAction }