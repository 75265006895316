import { StyledQuestionIcon } from './question-icon-style';
import { useDispatch } from 'react-redux';
import { setInfoAction } from '../../store/actions/ui';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons';
import { COLORS } from '../../colors';

interface IQuestionIconProps {
  explanation: string
}

export const QuestionIcon = ({
  explanation
}: IQuestionIconProps) => {
  const dispatch = useDispatch();
  const handleClickQuestion = () => {
    dispatch(setInfoAction(explanation));
  };
  return (
    <StyledQuestionIcon role='button' onClick={handleClickQuestion}>
      <FontAwesomeIcon icon={faQuestionCircle} color={COLORS.mainBlue} />
    </StyledQuestionIcon>
  );
};