import { User } from "firebase/auth"
import { IIllustrokeUser, IUserRequest } from "../../types"
import { storeObjectInLocalStorage } from "../../utils/localStorage"

export interface IUserState {
  userFirebase: User | null,
  userInfo: IIllustrokeUser | null,
  token: string | null,
  userPalette: string[],
  userRequests: IUserRequest[],
  userRequestsInitialized: boolean // we use to avoid too much userRequests calls
}

const initialState: IUserState = {
  userInfo: null,
  userFirebase: null,
  token: null,
  userPalette: ['#D0021B', '#F5A623', '#F8E71C', '#8B572A', '#7ED321', '#417505', '#BD10E0', '#9013FE', '#4A90E2', '#50E3C2', '#B8E986', '#000000', '#4A4A4A', '#9B9B9B', '#FFFFFF'],
  userRequests: [],
  userRequestsInitialized: false
}

function user(state = initialState, action: any) {
  switch (action.type) {
    case 'SET_USER_FIREBASE':
      return {
        ...state,
        userFirebase: action.payload
      }
    case 'SET_USER_INFO':
      return {
        ...state,
        userInfo: action.payload
      }
    case 'SET_USER_TOKEN':
      return {
        ...state,
        token: action.payload
      }
    case 'SET_USER_REQUESTS_INITIALIZED':
      return {
        ...state,
        userRequestsInitialized: action.payload
      }
    case 'ADD_USER_REQUEST':
      if (!state.userRequests.find(r => r.id === action.payload.id)) {
        return {
          ...state,
          userRequests: [...state.userRequests, action.payload]
        }
      }
      return state
    case 'EDIT_USER_REQUEST':
      const reqIndex = state.userRequests.findIndex((req => req.id === action.payload.id));
      if (reqIndex > -1) {
        const copyUserRequests = state.userRequests.slice();
        copyUserRequests[reqIndex] = action.payload;
        return {
          ...state,
          userRequests: copyUserRequests
        }
      }
      return state
    case 'DELETE_USER_REQUEST':
      const reqIndexToDelete = state.userRequests.findIndex((req => req.id === action.payload.id));
      if (reqIndexToDelete > -1) {
        const copyUserRequests = state.userRequests.slice();
        copyUserRequests.splice(reqIndexToDelete, 1)
        return {
          ...state,
          userRequests: copyUserRequests
        }
      }
      return state
    case 'DELETE_ALL_USER_REQUEST':
      return {
        ...state,
        userRequests: []
      }
    case 'SET_USER_PALETTE':
      return {
        ...state,
        userPalette: action.payload
      }

    case 'ADD_COLOR_TO_USER_PALETTE':
      //If color exist in palette do nothing
      if (state.userPalette.includes(action.payload.toUpperCase())) {
        return state
      }

      const copyUserPalette = state.userPalette.slice();
      copyUserPalette.pop();

      storeObjectInLocalStorage("colors-palette-v0", [action.payload.toUpperCase(), ...copyUserPalette]);

      return {
        ...state,
        userPalette: [action.payload.toUpperCase(), ...copyUserPalette]
      }

    case 'CHANGE_USER_TOKENS_BY_X':
      const copyUserInfo = JSON.parse(JSON.stringify(state.userInfo)) as IIllustrokeUser;
      if (state.userInfo && action.payload) {
        copyUserInfo.tokens = state.userInfo?.tokens + action.payload;
      }
      return {
        ...state,
        userInfo: copyUserInfo
      }
    default:
      return state
  }
}

export default user