import styled from 'styled-components';

const StyledZoomModal = styled.div`
  //only smartphone
  @media only screen and (max-width: 600px) {
    width: 100%;
  }

  svg {
    width: 100%;
  }
`

export {
  StyledZoomModal
}