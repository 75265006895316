import styled from 'styled-components';

const StyledErrorModal = styled.div`
  background-color: white;
  padding: 2rem;
  border-radius: 25px;
  h1 {
    color: red;
  }
  p {
    color: red;
  }
`

export {
  StyledErrorModal
}