import styled from 'styled-components';

const StyledColorPaletteSettings = styled.div`
  display: flex;
  gap: 5px;
  button.add {
    border: none;
    background-color: transparent;
    img {
      width: 20px;
    }
  }
  button.add:hover {
    transform: scale(1.1);
  }
  button.fill {
    border: solid 1px black;
    width: 35px;
    height: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    border: none;
    border-radius: 5px;
    box-shadow: 0px 0px 2px #888888;
    img {
      display: none;
      width: 15px;
      height: 15px;
      top: -5px;
      position: absolute;
      right: -5px;
    }
  }
  button.fill:hover {
    transform: scale(1.1);
    img {
      display: block;
    }
  }
`;

export {
  StyledColorPaletteSettings,
}