import { useLocation, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { StyledPixelIllustrations } from "./pixel-illustrations-style";
import { Button } from "../../components/button/Button";
import { PromptTipsModal } from "../../components/prompts-tips-modal/PromptsTipsModal";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store/store";
import { setErrorAction, setGenerateLoadingAction } from "../../store/actions/ui";
import { GenerateErrorCodes, IGeneratePixelRequestParam } from "../../apiTypes";
import { generatePixelApi } from "../../api";
import { addUserRequestAction, changeUserTokensByXAction } from "../../store/actions/user";
import pixelTopImg from "../../images/top1.jpg";
import pixelDownImg from "../../images/down1.jpg";

export const PixelIllustrations = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const [prompt, setPrompt] = useState<string>("");
  const [showPromptTipsModal, setShowPromptTipsModal] = useState<boolean>(false);
  const token = useSelector((state: RootState) => state.user.token);

  const handleClickSubmit = () => {
    startGeneration();
  };

  const startGeneration = () => {
    if (token) {
      dispatch(setGenerateLoadingAction(true));
      if (prompt) {
        const generatePayload: IGeneratePixelRequestParam = {
          prompt,
        };
        generatePixelApi(token, generatePayload, dispatch).then((res) => {
          dispatch(setGenerateLoadingAction(false));
          if (res.success && res.data) {
            dispatch(addUserRequestAction(res.data));
            dispatch(changeUserTokensByXAction(-1)); //decrement tokens by 1
            navigate('/collection', {
              state: {
                data: res.data,
              }
            });
          } else {
            if (res.customErrorCode) {
              switch (res.customErrorCode) {
                case GenerateErrorCodes.MISSING_PARAMETERS:
                case GenerateErrorCodes.INVALID_STYLE:
                  dispatch(setErrorAction("Missing or wrong parameters. Please contact the support."));
                  break;
                case GenerateErrorCodes.RUN_OUT_TOKENS:
                  navigate('/pricing');
                  break;
                case GenerateErrorCodes.DALLE_EMPTY_RESPONSE:
                  dispatch(setErrorAction("Server are down. Please retry later. Error Code: " + res.customErrorCode));
                  break;
                case GenerateErrorCodes.DALLE_REQUEST_ERROR:
                  // check if we have a message:
                  const message = (res.error as any)?.error?.message;
                  if (message) {
                    dispatch(setErrorAction(message));
                  } else {
                    dispatch(setErrorAction("Server are down. Please retry later. Error Code: " + res.customErrorCode));
                  }
                  break;
                default:
                  break;
              }
            } else if (res.error) {
              console.error(res.error);
              dispatch(setErrorAction(res.error.toString()));
            } else {
              dispatch(setErrorAction("Unknow error"));
            }
          }
        });
      } else {
        dispatch(setGenerateLoadingAction(false));
        dispatch(setErrorAction("Prompt is empty. Please write something."));
      }
    }
  };

  useEffect(() => {
    const locationPrompt = location?.state?.prompt;
    if (locationPrompt) setPrompt(locationPrompt);
  }, []);

  return (
    <>
      <HelmetProvider>
        <Helmet>
          <title>Illustroke | Pixel illustrations from text prompts AI</title>
          <link rel="canonical" href={window.location.origin} />
        </Helmet>
      </HelmetProvider>
      <StyledPixelIllustrations>
        <section id="main">

          <img id="pixel-top" src={pixelTopImg} />
          <img id="pixel-down" src={pixelDownImg} />
          <div>

            <div className="titles">
              <h1>Beautiful images from text prompts</h1>
              <p>Leave your immagination free and create your own <b>pixel images</b> with Illustroke. Just write a prompt and let the AI do the rest.</p>
            </div>

            <div className="inputs">
              <div>
                <textarea value={prompt} onChange={(e) => setPrompt(e.target.value)} placeholder="The moon being destroyed" />
              </div>
              <Button onClick={handleClickSubmit}>Create!</Button>

              <div className="details">
                <p>COST: <b>1 TOKEN</b></p>
                <p>WHAT YOU GET: <b>2 PNG IMAGES (1024X1024px)</b></p>
              </div>
            </div>

          </div>
        </section>

        <section className="info">
          <h2>Why Choose Our AI Image Generator?</h2>
          <p>Our AI image generator offers a revolutionary way to create beautiful and unique images from simple text prompts. Whether you are an artist, designer, or creative enthusiast, our tool leverages advanced algorithms to bring your ideas to life in any style imaginable.</p>
        </section>

        <section className="info">
          <h2>How to Create Images from Text Prompts</h2>
          <h3>Step 1: Enter Your Text Prompt</h3>
          <p>Type your creative idea or description into the text input box. Our AI will interpret your prompt and start generating the image.</p>

          <h3>Step 2: Customize Your Image</h3>
          <p>Adjust the settings to fine-tune the generated image. You can modify colors, styles, and other parameters to achieve the perfect look.</p>

          <h3>Step 3: Download and Share</h3>
          <p>Once satisfied with the generated image, download it to your device and share it with others. You can also save it for future use in your projects.</p>
        </section>

        <section className="info">
          <h2>Features of Our AI Image Generator</h2>
          <p><strong>High-Quality Images:</strong> Our tool produces detailed and vibrant images from text prompts.</p>
          <p><strong>Fast and Efficient:</strong> Generate images in just a few seconds with minimal effort.</p>
          <p><strong>Customizable:</strong> Fine-tune your images to match your vision with adjustable settings.</p>
          <p><strong>Free to Use:</strong> Enjoy unlimited access to our image generator at no cost.</p>
          <p><strong>User-Friendly Interface:</strong> Intuitive design makes it easy for anyone to create stunning images.</p>
        </section>

        <section className="info">
          <h2>An Alternative to DALL-E 3 and MidJourney</h2>
          <p>Our AI image generator stands out as a versatile and accessible alternative to popular tools like DALL-E 3 and MidJourney. While both DALL-E 3 and MidJourney are known for their impressive capabilities, our tool offers a unique combination of high-quality output, user-friendly interface, and customization options. Whether you're looking for an alternative due to pricing, accessibility, or specific features, our AI image generator provides a robust solution for all your creative needs.</p>
        </section>
  
        <section className="info">
          <h2>Supported Text Prompts</h2>
          <p>Our AI image generator can handle a wide range of text prompts, including:</p>
          <p>Descriptive phrases (e.g., "a sunset over a mountain range")</p>
          <p>Single words (e.g., "dragon")</p>
          <p>Abstract concepts (e.g., "dreamlike landscape")</p>
          <p>Character descriptions (e.g., "a wizard with a staff")</p>
        </section>

        <section className="info">
          <h2>FAQs About AI Image Generation</h2>
          <h3>What is an AI image generator?</h3>
          <p>An AI image generator uses advanced algorithms and machine learning to create images from text prompts. It interprets the descriptive elements of the prompt and converts them into visual art.</p>

          <h3>How does the AI generate images from text?</h3>
          <p>Our AI uses natural language processing and image synthesis algorithms to interpret the text prompt and generate a corresponding image. The process involves analyzing the descriptive elements of the prompt and converting them into visual art.</p>

          <h3>Can I customize the generated images?</h3>
          <p>Yes, you can customize various aspects of the generated images, including colors, styles, and other parameters, to match your vision.</p>
        </section>

        <PromptTipsModal show={showPromptTipsModal} handleClose={() => setShowPromptTipsModal(false)} />
      </StyledPixelIllustrations>
    </>
  )
};