import { combineReducers, configureStore } from "@reduxjs/toolkit";
import frameReducer from './slices/frame-slice';
import selectedObjectReducer from './slices/selected-objects-slice';
import stageObjectReducer from "./slices/stage-object-slice";
import copiedObjectReducer from "./slices/copied-objects-slice";
import fontListReducer from './slices/font-list-slice';
import uiReducer from './reducers/ui';
import userReducer from './reducers/user';

export const store = configureStore({
  reducer: combineReducers({
    frame: frameReducer,
    fontList: fontListReducer,
    stage: stageObjectReducer,
    selected: selectedObjectReducer,
    copied: copiedObjectReducer,
    ui: uiReducer,
    user: userReducer
  }),
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
      // immutableCheck: { warnAfter: 128 },
      immutableCheck: false,
    })
});

export type RootState = ReturnType<typeof store.getState>;