import styled from 'styled-components';

const StyledGenerate = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

export {
  StyledGenerate,
}