import { Button } from "../../../../components/button/Button";
import { Spacer } from "../../../../components/Spacer";
import { ColorMode, ObjectMode } from "../../../../types";
import { StyledGenerateConfirm } from "./generate-confirm-style";

import objectModeCentered from '../../../../images/objectModeCentered.png';
import objectModeFull from '../../../../images/objectModeFull.png';
import colorImage from '../../../../images/color.png';
import bwImage from '../../../../images/bw.png';
import { IAdvancedSetting, IGenerateStep } from "../../Generate";
import LazyImage from "../../../../components/LazyImage";
import { STYLE_IMAGES } from "../../../../images/styles";
import { isColorLimitValid } from "../GenerateColormode/GenerateColormode";
import { colorpaletteToHexList } from "../../../../utils/colors";
import { IStyle } from "../../../../onlyFeTypes";

interface IGenerateConfirmProps {
  handleClickSumbit: () => void,
  setStep: (s: IGenerateStep) => void,
  prompt: string,
  colormode: ColorMode,
  colorpalette?: string,
  objectmode: ObjectMode,
  style?: IStyle,
  colorlimit?: number,
  selectedAdvancedSettings: IAdvancedSetting
}

export const GenerateConfirm = ({
  handleClickSumbit,
  setStep,
  style,
  colormode,
  colorpalette,
  objectmode,
  colorlimit,
  selectedAdvancedSettings
}: IGenerateConfirmProps) => {
  const isNoStyle = !!(style?.id === "no-style");
  const imageSrc = (style) ? STYLE_IMAGES[style.id] : "";
  
  return (
    <StyledGenerateConfirm>
      <div className="resume">
        <div className="styleResume" onClick={() => setStep("style")}>
          <h3>Style:</h3>
          {isNoStyle ? (
            <div>
              <p>No style</p>
            </div>
          ) : (
            <LazyImage src={imageSrc} alt='generation preview' />
          )}
        </div>
        <div className="colormodeResume" onClick={() => setStep("colormode")}>
          <h3>Color mode:</h3>
          {(colormode === 'color') && (
            <img src={colorImage} alt="color" />
          )}
          {(colormode === 'bw') && (
            <img src={bwImage} alt="black and white" />
          )}
        </div>
        <div className="objectmodeResume" onClick={() => setStep("objectmode")}>
          <h3>Object mode:</h3>
          {(objectmode === "centered") && (
            <img src={objectModeCentered} alt="centered objectmode" />
          )}
          {(objectmode === "full") && (
            <img src={objectModeFull} alt="full objectmode" />
          )}
        </div>
      </div>

      {(selectedAdvancedSettings === "colorlimit") && !!colorlimit && isColorLimitValid(colorlimit) && (
        <div className="advancedSetting">
          <p>Color limit:</p>
          <span><b>{colorlimit}</b></span>
        </div>
      )}

      {(selectedAdvancedSettings === "colorpalette") && !!colorpalette && (
        <div className="advancedSetting">
          <p>Color palette:</p>
          <div className="fills">
            {colorpaletteToHexList(colorpalette).map(color => (
              <div className="fill" key={color} style={{ backgroundColor: color }} />
            ))}
          </div>
        </div>
      )}

      <Spacer />
      <Spacer />

      <h2>It's everything ok?</h2>
      <p>This will cost you <b>1</b> token</p>
      <Spacer />
      <div className="buttons">
        <Button onClick={handleClickSumbit}>Yes, generate!</Button>
        <Button variant="secondary" onClick={() => setStep("objectmode")}>Go Back</Button>
      </div>
    </StyledGenerateConfirm>
  )
};