import styled from 'styled-components';

const StyledPromptTipsModal = styled.div`
  background-color: white;
  padding: 2rem;
  border-radius: 25px;
  max-width: 600px;
  text-align: left; 
  p {
    padding-bottom: 0.5rem;
  }
`

export {
  StyledPromptTipsModal
}