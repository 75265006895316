import styled from 'styled-components';
import { COLORS } from '../../colors';

const StyledPixelIllustrations = styled.div`
  section {
    padding-top: 2rem;
    padding-bottom: 2rem;
    .titles {
      max-width: 600px;
      margin-bottom: 1rem;
      h1 {
        color: ${COLORS.mainBlue};
        font-size: 48px;
        line-height: 45px;
        margin-bottom: 1rem;
        //only smartphone
        @media only screen and (max-width: 600px) {
          font-size: 38px;
          line-height: 35px;
        }
      }
      p {
        font-size: 20px;
        //only smartphone
        @media only screen and (max-width: 600px) {
          font-size: 16px;
        }
      }
      margin-left: auto;
      margin-right: auto;
    }
  }
    
  section#main {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 90vh;
    position: relative;

    //only smartphone
    @media only screen and (max-width: 600px) {
      padding-left: 2rem;
      padding-right: 2rem;
    }
    
    .inputs {
      select {
          border: 0;
          padding: 1rem;
          background-color: white;
          border-radius: 25px;
          font-size: 18px;
      }
      textarea {
        display: block;
        margin: auto;
        width: 100%;
        text-align: center;
        border: 0;
        padding: 1rem 2rem;
        background-color: white;
        border-radius: 25px;
        font-size: 18px;
        box-sizing: border-box;
      }
      button {
        margin: auto;
        margin-top: 1rem;
      }

      .details {
        margin-top: 1rem;
        font-size: 14px;
        display: flex;
        flex-direction: column;
      }
    }

    img#pixel-top {
      position: absolute;
      top: 0;
      width: 100%;
      max-height: 100px;
      object-fit: cover;
    }
    img#pixel-down {
      position: absolute;
      bottom: 0;
      width: 100%;
      max-height: 100px;
      object-fit: cover;
    }
  }

  section.info {
    h2 {
      color: ${COLORS.mainBlue};
    }
    max-width: 800px;
    margin: auto;
  }
`

export {
  StyledPixelIllustrations
}