import styled from 'styled-components';
import { COLORS } from '../../colors';

const StyledStepper = styled.div`
  padding-top: 2rem;
  padding-bottom: 6rem;
  padding-left: 1rem;
  padding-right: 1rem;
  box-sizing: border-box;
  width: 100%;
  max-width: 800px;
  justify-content: center;
  align-items: center;
  display: flex;

  //only smartphone
  @media only screen and (max-width: 600px) {
    display: none;
  }
  
  .dot {
    cursor: pointer;
    width: 32px;
    height: 32px;
    background-color: #ccc;
    display: inline-block;
    border-radius: 50%;
    position: relative;

    span {
      position: absolute;
      top: 38px;
      left: 0px;
      font-size: 13px;
      text-transform: uppercase;
      white-space: nowrap;
      font-weight: bold;
      color: #ccc;
    }
  }

  .dot.active {
    background-color: ${COLORS.mainBlue};
    span {
      color: ${COLORS.mainBlue};
    }
  }
  .dot.passed {
    background-color: ${COLORS.mainBlue};
  }
  .line.passed {
    background-color: ${COLORS.mainBlue};
  }

  .line {
    display: inline-block;
    height: 7px;
    flex: 1;
    background-color: #ccc;
  }
`;

const StyledStepperMobile = styled.div`
  display: none;
  //only smartphone
  @media only screen and (max-width: 600px) {
    padding-top: 1rem;
    display: block;
    color: ${COLORS.mainBlue};
  }
`

export {
    StyledStepper,
    StyledStepperMobile
}