import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { Home } from './pages/home/Home';
import { PixelIllustrations } from './pages/pixel-illustrations/PixelIllustrations';
import { ImageToVector } from './pages/image-to-vector/ImageToVector';
import { Provider } from 'react-redux';
import { store } from './store/store';
import { Suspense, lazy } from 'react';
import { AuthManager } from './components/AuthManager';
import { Layout } from './components/layout/Layout';
import { Generate } from './pages/generate/Generate';
import { PrivateRoute } from './components/PrivateRoute';
import { Pricing } from './pages/pricing/Pricing';
import { CanvaLogin } from './pages/canva-login/CanvaLogin';
import NotFound from './pages/notfound/NotFound';
import { Loader } from './components/Loader';

function withSuspense<P>(WrappedComponent: any) {
  return (props: P) => (
    <Suspense fallback={<div style={{ padding: '4rem 2rem' }}><Loader /></div>}>
      <WrappedComponent {...props} />
    </Suspense>
  );
}

const Profile = lazy(() => import('./pages/profile/Profile'));
const Checkout = lazy(() => import('./pages/checkout/Checkout'));
const PaymentSuccess = lazy(() => import('./pages/success/PaymentSuccess'));
const Api = lazy(() => import('./pages/api/Api'));
const ApiPricing = lazy(() => import('./pages/api/components/ApiPricing/ApiPricing'));
const ApiDocs = lazy(() => import('./pages/api/components/ApiDocs/ApiDocs'));
const Affiliate = lazy(() => import('./pages/affiliate/Affiliate'));
const Collection = lazy(() => import('./pages/collection/Collection'));
const Privacy = lazy(() => import('./pages/privacy/Privacy'));
const Terms = lazy(() => import('./pages/terms/Terms'));
const Editor = lazy(() => import('./pages/editor/Editor'));
const Designs = lazy(() => import('./pages/designs/Designs'));
const Orders = lazy(() => import('./pages/orders/Orders'));

const BlogArticle1 = lazy(() => import('./pages/blog/article-1/Article-1'));

const SuspendedTerms = withSuspense(Terms);
const SuspendedCollection = withSuspense(Collection);
const SuspendedEditor = withSuspense(Editor);
const SuspendedApiPricing = withSuspense(ApiPricing);
const SuspendedApi = withSuspense(Api);
const SuspendedApiDocs = withSuspense(ApiDocs);
const SuspendedDesigns = withSuspense(Designs);
const SuspendedOrders = withSuspense(Orders);
const SuspendedProfile = withSuspense(Profile);
const SuspendedPrivacy = withSuspense(Privacy);
const SuspendedCheckout = withSuspense(Checkout);
const SuspendedPaymentSuccess = withSuspense(PaymentSuccess);
const SuspendedAffiliate = withSuspense(Affiliate);
const SuspendedBlogArticle1 = withSuspense(BlogArticle1);

const App = () => {
  return (
    <Provider store={store}>
      <AuthManager>
        <>
          <Router>
            <Layout>
              <Routes>
                <Route path='/' element={<Home />} />
                <Route path='/pixel-illustrations' element={<PixelIllustrations />} />
                <Route path='/convert' element={<ImageToVector />} />
                <Route path='/terms' element={<SuspendedTerms />} />
                <Route path='/privacy' element={<SuspendedPrivacy />} />
                <Route path='/generate' element={<Generate />} />
                <Route path='/affiliate' element={<SuspendedAffiliate />} />
                <Route path='/pricing' element={<Pricing />} />
                <Route path='/payment-success' element={<SuspendedPaymentSuccess />} />
                <Route path='/api' element={<SuspendedApi />} />
                <Route path='/api/docs' element={<SuspendedApiDocs />} />
                <Route path='/api/pricing' element={<SuspendedApiPricing />} />
                <Route path='/canva-login' element={<CanvaLogin />} />
                <Route path='/blog/designers-what-can-ai-do-for-you' element={<SuspendedBlogArticle1 />} />
                <Route
                  path="/collection"
                  element={
                    <PrivateRoute>
                      <SuspendedCollection />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/designs"
                  element={
                    <PrivateRoute>
                      <SuspendedDesigns />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/editor"
                  element={
                    <PrivateRoute>
                      <SuspendedEditor />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/profile"
                  element={
                    <PrivateRoute>
                      <SuspendedProfile />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/profile/orders"
                  element={
                    <PrivateRoute>
                      <SuspendedOrders />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/checkout"
                  element={
                    <PrivateRoute>
                      <SuspendedCheckout />
                    </PrivateRoute>
                  }
                />
                <Route path="*" element={<NotFound />} />
              </Routes>
            </Layout>
          </Router>
        </>
      </AuthManager>
    </Provider>
  );
}

export default App;