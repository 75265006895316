import styled from 'styled-components';
import { COLORS } from '../../../../../../colors';

const StyledColorLimitSettings = styled.div`
  display: flex;
  gap: 5px;
  > button {
    border: solid 1px black;
    background-color: ${COLORS.mainGray};
    width: 35px;
    height: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    border-radius: 5px;
  }
  > button:hover {
    transform: scale(1.1);
    img {
      display: block;
    }
  }
  button.selected {
    background-color: ${COLORS.mainBlue};
    color: white;
  }
`;

export {
  StyledColorLimitSettings
}