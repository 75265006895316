import { RGBColor } from "react-color";
import { ObjectMode } from "../types";

export function colorpaletteToHexList(colorpalette?: string): string[] {
  if (colorpalette) {
    return colorpalette.split(',').map(c => c.trim());
  }
  return [];
}

export const addColorToColorpalette = (colorpalette?: string, newHexColor?: string) => {
  if (colorpalette && newHexColor) {
    return `${colorpalette},${newHexColor}`
  } else if (!colorpalette && newHexColor) {
    return `${newHexColor}`
  }
  return colorpalette || ""
};

export const replaceColorFromColorpalette = (colorpalette?: string, newHexColor?: string, oldHexColor?: string) => {
  if (colorpalette && newHexColor && oldHexColor) {
    if (!colorpalette.includes(newHexColor)) {
      return colorpalette.replace(oldHexColor, newHexColor)
    }
  }
  return colorpalette || ""
};

export const removeColorFromColorpalette = (colorpalette?: string, hexColor?: string) => {
  if (colorpalette && hexColor) {
    return colorpalette.split(',').map(c => c.trim()).filter(c => c !== hexColor).join(',');
  }
  return colorpalette || ""
};

export const addWhiteToColorpalette = (colorpalette: string, objectMode: ObjectMode) => {
  if (objectMode === "centered") {
    if (!colorpalette.includes("#ffffff")) {
      return addColorToColorpalette(colorpalette, "#ffffff");
    }
  }
  return colorpalette
};


export const getRGBAString = (rbga: RGBColor) => {
  if (rbga.a) {
    return `rgba(${rbga.r}, ${rbga.g}, ${rbga.b}, ${rbga.a})`;
  }
  return `rgb(${rbga.r}, ${rbga.g}, ${rbga.b})`;
};
