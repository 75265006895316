import styled from 'styled-components'

const StyledTextInput = styled.input`
  padding: 0.5rem;
  font-size: 16px;
  border-radius: 25px;
  text-align: center;
`

export {
  StyledTextInput
}