import styled from "styled-components";
import { COLORS } from "../../colors";

export const GenerateLoaderStyled = styled.div`
  z-index: 999999;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${COLORS.mainBlueOpaque};
  display: flex;
  gap: 1rem;

  
  .inner {
    width: 600px;
    background-color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    padding: 2rem;
    border-radius: 25px;
    display: flex;
    flex-direction: row;
    gap: 2rem;

    //only smartphone
    @media only screen and (max-width: 600px) {
      flex-direction: column-reverse;
      margin: 1rem;
    }
    h3 {
      color: ${COLORS.mainBlue};
    }
    .tipTitle {
      font-size: 12px;
      margin-top: 5px
    }
    .tipText {
      font-size: 18px;
    }
  }
`;