import { useDispatch } from 'react-redux';
import { setSuccessAction } from '../../store/actions/ui';
import { Modal } from '../modal/Modal';

import { StyledSuccessModal } from './success-modal-style';

interface ISuccessModalProps {
  success: string
}

export const SuccessModal = ({ success }: ISuccessModalProps) => {

  const dispatch = useDispatch();

  const handleClose = () => {
    dispatch(setSuccessAction(""));
  };

  return (
    <Modal show={!!success} onClose={handleClose}>
      <StyledSuccessModal>
        <h1>Great</h1>
        <p><b>{success}</b></p>
      </StyledSuccessModal>
    </Modal>
  );
};