import { Button } from "../../../../components/button/Button";
import { StyledGenerateObjectMode } from "./generate-object-mode";
import objectModeCentered from '../../../../images/objectModeCentered.png';
import objectModeFull from '../../../../images/objectModeFull.png';
import { ObjectMode } from "../../../../types";
import { logEvent } from "firebase/analytics";
import { analytics } from "../../../../fire";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import { COLORS } from "../../../../colors";

interface IGenerateObjectModeProps {
  objectmode: ObjectMode,
  setObjectmode: (objectmode: ObjectMode) => void,
  setStep: (s: any) => void,
  isRepStyle: boolean
}

export const GenerateObjectMode = ({
  objectmode,
  setObjectmode,
  setStep,
  isRepStyle
}: IGenerateObjectModeProps) => {

  const handleClickContinue = () => {
    setStep("confirm");
    logEvent(analytics, 'select_objectmode', { objectmode });
  };
  return (
    <StyledGenerateObjectMode>
      <div className="objectmodeSelector">
        <div>
          <button className={(objectmode === "full") ? "selected" : ""} onClick={() => setObjectmode("full")}>
            <img src={objectModeFull} alt="objectmode full" />
            {(objectmode === "full") && <FontAwesomeIcon icon={faCheckCircle} color={COLORS.mainBlue} className="checkImg" />}
          </button>
        </div>
        {!isRepStyle && <div>
          <button className={(objectmode === "centered") ? "selected" : ""} onClick={() => setObjectmode("centered")}>
            <img src={objectModeCentered} alt="objectmode centered" />
            {(objectmode === "centered") && <FontAwesomeIcon icon={faCheckCircle} color={COLORS.mainBlue} className="checkImg" />}
          </button>
        </div>}
      </div>
      <div className="guides">
        {(objectmode === "full") && <p>Create a full illustration with a <b>background</b>. <br></br> The illustration <b>can be clipped on the edges</b>.</p>}
        {(objectmode === "centered") && <p>Create an illustration with an <b>isolated</b> objects in the center and a <b>white background</b>. <br></br> Illustration <b>cannot be clipped</b>.</p>}
      </div>
      <div className="buttons">
        <Button onClick={handleClickContinue}>Continue</Button>
        <Button variant="secondary" onClick={() => setStep('colormode')}>Go Back</Button>
      </div>
    </StyledGenerateObjectMode>
  )
};