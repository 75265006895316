import { StyledButton, StyledButtonSecondary } from './button-style';
import downloadIcon from '../../images/icons/download_white.svg';
import saveIcon from '../../images/icons/save_white.svg';
import editIcon from '../../images/icons/edit_white.svg';
import refreshIcon from '../../images/icons/refresh_white.svg';

export const ButtonIcons = {
  download: downloadIcon,
  save: saveIcon,
  edit: editIcon,
  refresh: refreshIcon
}

interface IButtonProps {
  onClick: () => any,
  children: any,
  variant?: "primary" | "secondary",
  id?: string,
  disabled?: boolean,
  size?: "medium" | "small",
  icon?: "save" | "edit" | "refresh" | "download";
}

export const Button = ({
  onClick,
  children,
  variant = "primary",
  id = "",
  disabled = false,
  size = "medium",
  icon
}: IButtonProps) => {
  if (variant === "primary") {
    return (
      <StyledButton onClick={onClick} id={id} disabled={disabled} size={size}>
        {icon && <img src={ButtonIcons[icon]} alt='illustroke icon button' />}
        {children}
      </StyledButton>
    );
  } else if (variant === "secondary") {
    return (
      <StyledButtonSecondary onClick={onClick} id={id} disabled={disabled} size={size}>
        {children}
      </StyledButtonSecondary>
    );
  }

  return <></>
};