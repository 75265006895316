import { Modal } from "../modal/Modal";
import { StyledZoomModal } from "./zoom-modal-style";
import SVG from 'react-inlinesvg';

interface IZoomModalProps {
  show: boolean,
  handleClose: () => void,
  uri?: string
}

export const ZoomModal = ({
  show,
  handleClose,
  uri
}: IZoomModalProps) => {
  if (!uri) return null
  return (
    <Modal show={show} onClose={handleClose}>
      <StyledZoomModal>
        <SVG
          viewBox="0 0 512 512"
          src={uri}
        />
      </StyledZoomModal>
    </Modal>
  );
};