export interface IUIState {
  loading: boolean, // show generic loading gif
  loadingMessage: string, // message that can appear when basic loading is true
  generateLoading: boolean, // show special loader, when creating illustrations
  error: string,
  success: string,
  info: string,
  showLoginModal: boolean,
}

const initialState: IUIState = {
  loading: false,
  generateLoading: false,
  loadingMessage: "",
  error: "",
  success: "",
  info: "",
  showLoginModal: false,
}

function ui(state = initialState, action: any) {
  switch (action.type) {
    case 'SET_LOADING':
      return {
        ...state,
        loading: action.payload
      }
    case 'SET_GENERATE_LOADING':
      return {
        ...state,
        generateLoading: action.payload
      }
    case 'SET_LOADING_MESSAGE':
      return {
        ...state,
        loadingMessage: action.payload
      }
    case 'SET_ERROR':
      return {
        ...state,
        error: action.payload
      }
    case 'SET_SUCCESS':
      return {
        ...state,
        success: action.payload
      }
    case 'SET_INFO':
      return {
        ...state,
        info: action.payload
      }
    case 'SET_SHOW_LOGIN_MODAL':
      return {
        ...state,
        showLoginModal: action.payload
      }
    default:
      return state
  }
}

export default ui