import styled, { keyframes } from "styled-components";
import { COLORS } from "../colors";

// Define the loader animation
const rotateAnimation = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

// Define the Loader component
export const Loader = styled.div`
  border: 10px solid #f3f3f3;
  border-top: 10px solid ${COLORS.mainBlue};
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: ${rotateAnimation} 1.5s linear infinite;
  margin: 10px;
`;