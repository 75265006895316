import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { redeemApi } from "../../api";
import { Button } from "../../components/button/Button";
import { TextInput } from "../../components/text-input/TextInput";
import { setErrorAction, setInfoAction, setLoadingAction, setSuccessAction } from "../../store/actions/ui";
import { setShowLoginModalAction } from "../../store/actions/ui";
import { changeUserTokensByXAction } from "../../store/actions/user";
import { RootState } from "../../store/store";
import { ItemType } from "../../types";
import { formatPrice, itemToDefaultPrice, itemToDefaultTokens } from "../../utils/pricing";
import { StyledPricing } from "./pricing-style";
import { PageHeader } from "../../components/page-header/PageHeader";
import { PageMain } from "../../components/page-main/PageMain";
import { SocialButton } from "../../components/social-button/SocialButton";
import { Helmet, HelmetProvider } from "react-helmet-async";

export const Pricing = () => {

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const token = useSelector((state: RootState) => state.user.token);
  const userFirebase = useSelector((state: RootState) => state.user.userFirebase);

  const [applyAffiliateDiscount, setApplyAffiliateDiscount] = useState<boolean>(false);

  const handleClickSubmitPromoCode = () => {
    if (userFirebase) {
      if (welcomeCodeTextInput) {
        if (import.meta.env.VITE_FLAGS_STOP_BUYING === "true") {
          dispatch(setInfoAction("Sorry for the inconvenience, but due to high traffic our website is currently undergoing maintenance. We will be back up and running as soon as possible. Thank you for your patience."))
        } else {
          if (token) {
            dispatch(setLoadingAction(true));
            redeemApi(token, { promoCode: welcomeCodeTextInput }).then(res => {
              dispatch(setLoadingAction(false));
              if (res?.success) {
                if (res.data?.tokens) dispatch(changeUserTokensByXAction(res.data?.tokens)); //decrement tokens by 1
                dispatch(setSuccessAction("Tokens redeemed! Check your profile."));
              } else {
                if(res.customErrorCode){
                  switch (res.customErrorCode) {
                    case "MISSING_PARAMETERS":
                      dispatch(setErrorAction("Missing parameters. Please contact the support."));
                      break;
                    case "PROMO_CODE_NOT_FOUND":
                      dispatch(setErrorAction("Promo Code not found."));
                      break;
                    case "PROMO_CODE_ALREADY_REDEEMED":
                      dispatch(setErrorAction("This Promo Code has been already redeemed."));
                      break;
                    default:
                      break;
                  }
                } else {
                  dispatch(setErrorAction("Unknow error"));
                }
              }
            });
          }
        }
      }
    } else {
      dispatch(setShowLoginModalAction(true));
    }
  };

  const [welcomeCodeTextInput, setWelcomeCodeTextInput] = useState<string>("");
  const handleClickBuy = (item: ItemType) => {
    if (userFirebase) {
      if (userFirebase.emailVerified) {
        if (import.meta.env.VITE_FLAGS_STOP_BUYING === "true") {
          dispatch(setInfoAction("Sorry for the inconvenience, but due to high traffic our website is currently undergoing maintenance. We will be back up and running as soon as possible. Thank you for your patience."))
        } else {
          navigate('/checkout', { state: { item } });
        }
      } else {
        dispatch(setInfoAction("Your email is not verified. Go to your profile and click on the button: 'send verification email'."))
      }
    } else {
      dispatch(setShowLoginModalAction(true));
    }
  };

  const handleClickTry = () => {
    if (userFirebase) {
      navigate('/');
    } else {
      dispatch(setShowLoginModalAction(true));
    }
  };

  useEffect(() => {
    if (window.sessionStorage.getItem('affiliateKey')) {
      setApplyAffiliateDiscount(true);
    }
  }, []);

  return (
    <div>
      <HelmetProvider>
        <Helmet>
          <title>Illustroke | Pricing</title>
          <link rel="canonical" href={`${window.location.origin}/pricing`} />
        </Helmet>
      </HelmetProvider>

      <StyledPricing>
        <PageHeader title="Get the tokens to create stunning vector illustrations." subtitle="Pick the bundle that’s perfect for your needs." />

        <PageMain>
          <div className="bundles">

            {!(import.meta.env.VITE_FLAGS_DISABLE_FREE_TRIAL === "true") && (
              <div className="bundle">
                <h1>Starter Bundle</h1>
                <p>Create an account and get your first <b>{import.meta.env.VITE_FLAGS_FREE_TOKENS} tokens</b> to try our service!</p>
                <Button disabled={import.meta.env.VITE_FLAGS_DISABLE_FREE_TRIAL === "true"} id="free" onClick={handleClickTry}>
                  Try for free
                </Button>
              </div>
            )}

            {(import.meta.env.VITE_FLAGS_DISABLE_FREE_TRIAL === "true") && (
              <div className="bundle">
                <h1>Free tokens</h1>
                <p>Follow us and send a message through our social channels to get a <b>welcome code</b>.</p>
                <p style={{ fontSize: '12px' }}>We normally reply within 24 hours.</p>
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: '20px' }}>
                  <SocialButton social="x" />
                </div>
              </div>
            )}

            <div className="bundle">
              <h1>Personal Bundle</h1>
              <p>Get <b>{itemToDefaultTokens("personal")} tokens</b> to generate beautiful illustrations for your personal projects.</p>
              {applyAffiliateDiscount ? (
                <Button onClick={() => handleClickBuy("personal")}>
                  Buy for <s>{formatPrice(itemToDefaultPrice("personal"))}</s> {formatPrice(itemToDefaultPrice("personal", true))}
                </Button>
              ) : (
                <Button onClick={() => handleClickBuy("personal")}>
                  Buy for {formatPrice(itemToDefaultPrice("personal"))}
                </Button>
              )}

              {applyAffiliateDiscount && <p className="affiliateDiscountActive">AFFILIATE DISCOUNT ACTIVE</p>}

            </div>

            <div className="bundle">
              <h1>Growth Bundle</h1>
              <p>Get <b>{itemToDefaultTokens("growth")} tokens</b> to generate beautiful illustrations to grow your business.</p>

              {applyAffiliateDiscount ? (
                <Button onClick={() => handleClickBuy("growth")}>
                  Buy for <s>{formatPrice(itemToDefaultPrice("growth"))}</s> {formatPrice(itemToDefaultPrice("growth", true))}
                </Button>
              ) : (
                <Button onClick={() => handleClickBuy("growth")}>
                  Buy for {formatPrice(itemToDefaultPrice("growth"))}
                </Button>
              )}

              {applyAffiliateDiscount && <p className="affiliateDiscountActive">AFFILIATE DISCOUNT ACTIVE</p>}

            </div>

          </div>

          <div className="howitworks">
            <h1>How it works?</h1>
            <p className="howitworks_1"><b>One token</b> = <b>One illustrations generation request</b>.</p>
            <p> In each request, <b>3 variants</b> of the same illustration are created, <br /> so as to be able to choose the best one.</p>
          </div>

          <div className="license">
            <h1>License</h1>
            <p>
              ✅ All illustrations are <b>free to download</b> and <b>use</b>
            </p>
            <p>
              ✅ <b>Commercial</b> and non-commercial purposes.
            </p>
            <p>
              ✅ <b>No permission</b> needed (although attribution is appreciated).
            </p>
            <p>
              ✅ Your illustrations are <b>unique</b> and cannot be accessed by other users.
            </p>
          </div>

          <div className="promo">
            <h2>Redeem your welcome code</h2>
            <p>Get one by following us and writing to us on social media.</p>
            <TextInput placeholder="YOUR-WELCOME-CODE" value={welcomeCodeTextInput} onChange={(e: any) => setWelcomeCodeTextInput(e.target.value)} />
            <Button onClick={handleClickSubmitPromoCode}>Redeem</Button>
          </div>
        </PageMain>

      </StyledPricing>
    </div>
  )
};