import { StyledImageToVector } from "./image-to-vector-style";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store/store";
import { convertToSvgOnlyApi } from "../../api";
import { useDropzone } from "react-dropzone";
import { Button } from "../../components/button/Button";
import { deleteObject, getDownloadURL, ref, uploadBytes } from "firebase/storage";
import { storage } from "../../fire";
import { setErrorAction, setLoadingAction, setShowLoginModalAction } from "../../store/actions/ui";
import { useCallback, useRef, useState } from "react";
import { getIllustrationUri } from "../../utils/urls";
import zoomIcon from '../../images/icons/zoom.svg';
import SVG from 'react-inlinesvg';
import { ZoomModal } from "../../components/zoom-modal/ZoomModal";
import { ImageToVectorColormode, isColorLimitValid } from "./component/ImageToVectorColormode/ImageToVectorColormode";
import { ColorMode } from "../../types";
import { Stepper } from "../../components/stepper/Stepper";
import { changeUserTokensByXAction } from "../../store/actions/user";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { GenerateErrorCodes, IConvertToSvgOnlyRequestParam } from "../../apiTypes";
import { ImageToVectorConfirm } from "./component/ImageToVectorConfirm/ImageToVectorConfirm";
import bee from "../../images/bee.svg";
import beeUnfocus from "../../images/bee-unfocus2.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";

export type ImageToVectorStep = "upload" | "colormode" | "confirm" | "result";
type IAdvancedSetting = "none" | "colorlimit" | "colorpalette";

const ALL_STEPS: ImageToVectorStep[] = ["upload", "colormode", "confirm"];
const ALL_STEPS_TITLES: string[] = ["Upload image", "Choose color mode", "Confirm"];
const INITIAL_COLOR_PALETTE = '#ffffff,#0500ff,#e6db63,#e878ce,#272727';

export const ImageToVector = () => {
  
  const token = useSelector((state: RootState) => state.user.token);
  const userFirebase = useSelector((state: RootState) => state.user.userFirebase);
  const imageResultRef = useRef<any>(null);
  
  const [step, setStep] = useState<ImageToVectorStep>("upload");
  const [colormode, setColormode] = useState<ColorMode>("color");
  const [colorlimit, setColorLimit] = useState<number>();
  const [colorpalette, setColorPalette] = useState<string>(INITIAL_COLOR_PALETTE);
  const [selectedAdvancedSettings, setSelectedAdvancedSettings] = useState<IAdvancedSetting>("none");
  const [showAdvancedSettings, setShowAdvancedSettings] = useState<boolean>(false);
  
  const [convertedSvgId, setConvertedSvgId] = useState<string>();
  const [showZoomModal, setShowZoomModal] = useState<boolean>(false);
  const [zoomModalImageUri, setZoomModalImageUri] = useState<string>();

  const dispatch = useDispatch();

  const [fileDropped, setFileDropped] = useState<File>();
  const onDrop = useCallback((acceptedFiles: File[]) => {
    if(acceptedFiles.length === 0) return;
    setFileDropped(acceptedFiles[0]);
    handleClickContinue();
  }, []);

  const {getRootProps, getInputProps} = useDropzone({
    onDrop,
    accept: {
      'image/png': ['.png'],
      'image/jpeg': ['.jpeg'],
      'image/jpg': ['.jpg'],
      'image/webp': ['.webp'],
      'image/tiff': ['.tiff'],
      'image/bmp': ['.bmp'],
    },
    maxFiles:1
  });

  const convert = () => {
    if(fileDropped) {
      dispatch(setLoadingAction(true));
      const n = fileDropped.name.replace(/\s/g, '');
      const imageToConvertRef = ref(storage, `${userFirebase?.uid}/ImageToVector/${n}`);
      uploadBytes(imageToConvertRef, fileDropped).then((snapshot) => {
        getDownloadURL(snapshot.ref).then((url) => {          
          const convertPayload: IConvertToSvgOnlyRequestParam = {
            imageUrl: url,
            colormode,
            ...((selectedAdvancedSettings === "colorlimit") && isColorLimitValid(colorlimit) && { colorlimit }),
            ...((selectedAdvancedSettings === "colorpalette") && !!colorpalette && { colorpalette }),
          };
          convertToSvgOnlyApi(token, convertPayload).then((res) => {
            if(res?.success && res.data) {
              setConvertedSvgId(res.data.id);
              setStep("result");
              dispatch(changeUserTokensByXAction(-1)); //decrement tokens by 1
            } else {
              if (res.customErrorCode){
                switch (res.customErrorCode) {
                  case GenerateErrorCodes.RUN_OUT_TOKENS:
                    dispatch(setErrorAction("You don't have enough tokens"));
                    break;
                  default:
                    dispatch(setErrorAction("This image cannot be converted. No tokens were used."));
                    handleClickReset();
                    break;
                }
              } else if (res.error){
                dispatch(setErrorAction(res.error.toString()));
              } else {
                dispatch(setErrorAction("Unknown error"));
              }
            }
            dispatch(setLoadingAction(false));
            deleteObject(imageToConvertRef);
          });
        });
      });
    }
  }

  const handleClickContinue = async () => {
    switch (step) {
      case "upload":
        setStep("colormode");
        break;
      case "colormode":
        setStep("confirm");
        break;
      case "confirm":
        if(userFirebase && token){
          convert();
        } else {
          dispatch(setShowLoginModalAction(true));
        }
        break;
      default:
        break;
    }
  }

  const handleClickDownload = () => {

    const svgDomObject = imageResultRef?.current?.reactWrapper ? imageResultRef.current.reactWrapper.querySelector('svg') : imageResultRef.current;
    if(!svgDomObject) {
      dispatch(setErrorAction("An error occurred while downloading the file"));
      return
    };
    const svgDomObjectCloned = svgDomObject.cloneNode(true);
    const svgData = new XMLSerializer().serializeToString(svgDomObjectCloned);
    const svgBlob = new Blob([svgData], { type: 'image/svg+xml;charset=utf-8' });
    const svgUrl = URL.createObjectURL(svgBlob);

    const a = document.createElement('a');
    a.style.display = 'none';
    a.href = svgUrl;
    a.download = 'illustroke.svg';
    document.body.appendChild(a);
    a.click();
    window.URL.revokeObjectURL(svgUrl);
  };

  const handleClickZoom = () => {
    setZoomModalImageUri(getIllustrationUri(userFirebase?.uid || "", convertedSvgId || ""));
    setShowZoomModal(true);
  };

  const handleClickReset = () => {
    setStep("upload");
    setConvertedSvgId(undefined);
    setFileDropped(undefined);
  }

  const handleClickStep = (nextStep: ImageToVectorStep) => {
    if(step === "result") return;
    let canGoToStep = false;
    switch (nextStep) {
      case "colormode":
        canGoToStep = !!fileDropped;
        break;
      case "confirm":
        canGoToStep = !!fileDropped && colormode !== undefined;
        break;
      case "upload":
        setConvertedSvgId(undefined);
        setFileDropped(undefined);
        canGoToStep = true
        break;
      default:
        break;
    }

    if (canGoToStep) {
      setStep(nextStep);
    }
  };

  return (
    <>
      <HelmetProvider>
        <Helmet>
          <title>Illustroke | Online Image to Vector (SVG) Converter – High-Quality SVG Conversion</title>
          <link rel="canonical" href={window.location.origin} />
        </Helmet>
      </HelmetProvider>
      <StyledImageToVector>

        <section className="main">

          {step === "upload" && (
            <div className="titles">
              <div className="bees">
                <img src={beeUnfocus} />
                <FontAwesomeIcon icon={faArrowRight} />
                <img src={bee} />
              </div>
              <h1>Image to Vector(SVG) <br /> AI Converter Tool</h1>
              <p>Convert your images to scalable vector graphics (SVG) quickly and easily with our AI powered online tool.</p>
            </div>
          )}

          {step !== "upload" && (
            <Stepper handleClickStep={handleClickStep} currentStep={step} steps={ALL_STEPS} titles={ALL_STEPS_TITLES} />
          )}

          {step === "upload" && (
            <div className="upload">
              <div {...getRootProps({className: 'dropzone'})}>
                <input {...getInputProps()} />
                <p>To start converting your image to vector, <br /> drag and drop your file here or click to upload.</p>
                <br />
                <p>Accepted: PNG, JPEG, JPG, WEBP, TIFF, BMP</p>
              </div>
            </div>
          )}

          {step === "colormode" && (
            <div className="colormode">
              <ImageToVectorColormode
                colormode={colormode}
                setColormode={setColormode}
                colorlimit={colorlimit}
                setColorLimit={setColorLimit}
                colorpalette={colorpalette}
                setColorPalette={setColorPalette}
                selectedAdvancedSettings={selectedAdvancedSettings}
                setSelectedAdvancedSettings={setSelectedAdvancedSettings}
                showAdvancedSettings={showAdvancedSettings}
                setShowAdvancedSettings={setShowAdvancedSettings}
              />
              <div className="buttons">
                <Button variant="secondary" onClick={() => setStep("upload")}>Go Back</Button>
                <Button onClick={handleClickContinue}>Continue</Button>
              </div>
            </div>
          )}

          {step === "confirm" && (
            <div className="confirm">

              <ImageToVectorConfirm 
                colormode={colormode} 
                setStep={setStep} 
                colorlimit={colorlimit} 
                colorpalette={colorpalette} 
                selectedAdvancedSettings={selectedAdvancedSettings} 
                fileDropped={fileDropped}
              />

              <div className="buttons">
                <Button variant="secondary" onClick={() => setStep("colormode")}>Go Back</Button>
                <Button onClick={handleClickContinue}>Yes, convert!</Button>
              </div>

            </div>
          )}

          {step === "result" && (
            <div className="result">
              
              <div className="titles">
                <h3>Your SVG is ready to be downloaded</h3>
              </div>

              <div className="svgContainer">
                <button className="zoomButton" onClick={handleClickZoom}>
                  <img src={zoomIcon} alt="Zoom" title="Zoom" />
                </button>
                <SVG
                  src={getIllustrationUri(userFirebase?.uid, convertedSvgId || "")}
                  innerRef={imageResultRef}
                />
              </div>

              <div className="buttons">
                <Button onClick={handleClickReset}>Convert another image</Button>
                <Button icon="download" onClick={handleClickDownload}>Download</Button>
              </div>

            </div>
          )}

        </section>

        <section className="info">
          <h2>Why Convert Images to Vector (SVG)?</h2>
          <p>Converting images to vector (SVG) format is essential for web and graphic designers. SVG files are scalable without losing quality, making them perfect for logos, icons, and illustrations. By converting your images to vectors, you ensure they remain crisp and clear on any device or screen size.</p>
        </section>

        <section className="info">
          <h2>How to Use Our Image to Vector (SVG) Converter</h2>
          <h3>Step 1: Upload Your Image</h3>
          <p>Simply drag and drop your image file or click the upload button to select an image from your device.</p>

          <h3>Step 2: Customize Your Conversion</h3>
          <p>Adjust the settings to fit your needs. You can tweak the colors, paths, and other parameters to ensure the vectorized image meets your requirements.</p>

          <h3>Step 3: Download Your Vector (SVG) File</h3>
          <p>Once the conversion is complete, download your new SVG file. It’s that easy!</p>
        </section>

        <section className="info">
          <h2>Features of Our SVG Converter Tool</h2>
          <p><strong>High-Quality AI Conversion:</strong> Our AI tool ensures your vector images maintain high quality.</p>
          <p><strong>Fast and Efficient:</strong> Convert your images to SVG in just a few clicks.</p>
          <p><strong>Customizable Settings:</strong> Fine-tune your vector images to your exact specifications.</p>
          <p><strong>User-Friendly Interface:</strong> Easy to navigate and use, even for beginners.</p>
        </section>

        <section className="info">
          <h2>Supported File Formats</h2>
          <p>Our image to vector converter supports various image formats including:</p>
          <p>JPG, PNG, GIF, BMP, TIFF</p>
        </section>

        <section className="info">
          <h2>FAQs About Image to Vector Conversion</h2>
          <h3>What is a vector image?</h3>
          <p>A vector image is a graphical representation composed of paths, as opposed to raster images which are composed of pixels. Vector images are resolution-independent and can be scaled infinitely without loss of quality.</p>

          <h3>Why use SVG format?</h3>
          <p>SVG (Scalable Vector Graphics) is a versatile and widely-used format for vector images. It is supported by all modern web browsers and can be easily edited with various graphic design software.</p>

          <h3>Is the conversion process secure?</h3>
          <p>Yes, we prioritize your privacy and security. All images uploaded to our converter are securely processed and are not stored on our servers.</p>
        </section>

      </StyledImageToVector>

      <ZoomModal show={showZoomModal} handleClose={() => setShowZoomModal(false)} uri={zoomModalImageUri} />

    </>
  )
};