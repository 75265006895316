import { StyledRadioButtonSettings, StyledSettingsContainer, StyledSettingsInner } from "../../generate-colormode-style";
// import { StyledNoAdvancedSettingsApplied } from "./color-limit-settings-style";

interface INoAdvancedSettingsAppliedProps {
  selected: boolean,
  onClickSelected: () => void
}

export const NoAdvancedSettingsApplied = ({
  selected,
  onClickSelected
}: INoAdvancedSettingsAppliedProps) => {
  return (
    <StyledSettingsContainer>
      <StyledRadioButtonSettings selected={selected} onClick={onClickSelected} />
      <StyledSettingsInner selected={selected}>
        <div>
          <p>No setting applied</p>
        </div>
        <div>
        </div>
      </StyledSettingsInner>
    </StyledSettingsContainer>
  )
};